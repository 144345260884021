import axios from "./axios";

export default {
    getCurrentlyInClinicByPatient(data) {
        const options = {
            method: 'POST',
            url: `patientlist/getCurrentlyInClinicByPatient`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    registerPatient(data) {
        const options = {
            method: 'POST',
            url: 'patientlist/registerPatient',
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                  resolve(response.data);
                })
                .catch(error => {
                  reject(error.message);
                })
        })
    },
    getDayPatients(data) {
        const options = {
            method: 'POST',
            url: 'patientlist/getDayPatients',
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                  resolve(response.data);
                })
                .catch(error => {
                  reject(error.message);
                })
        })
    },
    updateStatusClinic(data) {
        const options = {
            method: 'POST',
            url: 'patientlist/updateStatusClinic',
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                  resolve(response.data);
                })
                .catch(error => {
                  reject(error.message);
                })
        })
    },
    updatePatientClinic(data) {
        const options = {
            method: 'PUT',
            url: `patientlist/${data.ID}`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                  resolve(response.data);
                })
                .catch(error => {
                  reject(error.message);
                })
        })
    },
    deletePatientClinic(id) {
        const options = {
            method: 'DELETE',
            url: `patientlist/${id}`,
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                  resolve(response.data);
                })
                .catch(error => {
                  reject(error.message);
                })
        })
    }
}