import axios from "./axios";

export default {
    async createAccountingPeriod( data ) {
        try {
            
            const options = { 
                method: 'POST',
                url: '/accountingperiod',
                headers: {
                    'content-type': 'application/json'
                },
                data
            }
            const { data: responseData } = await axios( options );
            const { success, status } = responseData;

            if ( !success ) {
                if( status === 400 ) {
                    return responseData;
                } else {
                    throw new Error( responseData.message );
                }
            }
    
            return responseData;
        } catch (error) {
            throw new Error(`${error.message}`);
        }
    },
    async modifyAccountingPeriod( data ) {
        try {
            
            const options = { 
                method: 'PATCH',
                url: '/accountingperiod',
                headers: {
                    'content-type': 'application/json'
                },
                params: data
            }
            const { data: responseData } = await axios( options );
            const { success } = responseData;
    
            if ( !success ) {
                throw new Error( responseData.message );
            }
    
            return responseData;
        } catch (error) {
            throw new Error(`${error.message}`);
        }
    },
    async getAccountingPeriod( data ) {
        try {
            const options = { 
                method: 'GET',
                url: '/accountingperiod',
                headers: {
                    'content-type': 'application/json'
                },
                params: data
            }
            const { data: responseData } = await axios( options );
            const { success } = responseData;
    
            if ( !success ) {
                throw new Error( responseData.message );
            }
    
            return responseData;
        } catch (error) {
            throw new Error(`${error.message}`);
        }
    }
}