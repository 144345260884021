import { render, staticRenderFns } from "./ManageBusiness.vue?vue&type=template&id=c3d24dda&scoped=true"
import script from "./ManageBusiness.vue?vue&type=script&lang=js"
export * from "./ManageBusiness.vue?vue&type=script&lang=js"
import style0 from "./ManageBusiness.vue?vue&type=style&index=0&id=c3d24dda&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3d24dda",
  null
  
)

export default component.exports