<template>
  <v-container
    class="fill-height"
    :style="{
      backgroundImage: `url('/images/${imagelogin}')`,
    }"
    fluid
  >
    <v-row justify="center" no-gutters>
      <!-- login box -->
      <v-col class="pa-2" cols="12" sm="7" md="5" lg="3">
        <v-form @submit.prevent="submit" ref="formLogin">
          <v-card color="white" rounded="lg">
            <v-card-text>
              <v-row align="stretch" no-gutters>
                <v-col cols="3" class="d-flex align-center">
                  <v-img
                    v-if="!['d6d47d6edfc3812b5c68c16a07d51d8b', 'f33722a12f5e692b30374bbcf86011db', '9ede19c286c95b4aefd2462de691183a', '032b4fb2a80bacb6d7b912cd2fa6c005', 'ecbfba3a7cf5697352a16eecb423adbf','52e4d5b54ff5a95a878deca1491bdd36','2107b29ad7f6c567a5ea72523a108fd0'].includes(selectedBusiness.ID_KeyMaster ? selectedBusiness.ID_KeyMaster : selectedBusiness.sys_business_business_keys[0].ID_KeyMaster)"
                    src="/images/logo/muchiwa.png"
                    alt="logo"
                    max-width="48px"
                    contain
                  ></v-img>
                </v-col>
                <v-col cols="6" class="px-2">
                  <v-img
                    v-if="logomid"
                    :src="logomid"
                    alt="logo"
                    max-width="200px"
                    max-height="80px"
                    contain
                  ></v-img>
                </v-col>
                <v-col cols="3" class="d-flex align-center justify-end">
                  <v-img
                    v-if="!['d6d47d6edfc3812b5c68c16a07d51d8b', 'f33722a12f5e692b30374bbcf86011db', '9ede19c286c95b4aefd2462de691183a', '032b4fb2a80bacb6d7b912cd2fa6c005', 'ecbfba3a7cf5697352a16eecb423adbf','52e4d5b54ff5a95a878deca1491bdd36','2107b29ad7f6c567a5ea72523a108fd0'].includes(selectedBusiness.ID_KeyMaster ? selectedBusiness.ID_KeyMaster : selectedBusiness.sys_business_business_keys[0].ID_KeyMaster)"
                    src="/images/logo/InnovaLogo.png"
                    alt="logo"
                    max-width="80px"
                    contain
                  ></v-img>
                </v-col>
                <v-col
                  class="text-h4 text-center font-weight-bold mb-4"
                  cols="12"
                >
                  Inicio de Sesión
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    dense
                    autocomplete="new-password"
                    v-model="form.username"
                    label="Usuario"
                    filled
                    :rules="[rules.required]"
                  ></v-text-field>

                  <v-text-field
                    outlined
                    dense
                    autocomplete="new-password"
                    v-model="form.password"
                    type="password"
                    label="Contraseña"
                    ref="pass"
                    filled
                    :rules="[rules.required]"
                  ></v-text-field>

                  <v-autocomplete
                    outlined
                    dense
                    v-model="form.business"
                    :items="businessList"
                    item-text="BusinessName"
                    item-value="ID_Business"
                    @change="onItemSelected"
                    label="Seleccione su negocio"
                    filled
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="9">
                  <v-checkbox
                    label="Recordarme"
                    v-model="form.rememberme"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-img
                    :src="BusinessLogoPath"
                    alt="logo"
                    max-width="200px"
                    position="right center"
                    contain
                  ></v-img>
                </v-col>
                <v-col cols="12">
                  <v-btn color="primary" type="submit">Iniciar Sesión</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-col>
      <!-- rebembered users box -->
      <v-col
        v-if="loginData.length > 0"
        class="pa-2"
        cols="12"
        sm="4"
        md="3"
        lg="2"
      >
        <v-card class="py-3" color="white" rounded="lg">
          <v-list
            id="box-scroll"
            class="overflow-y-auto"
            style="max-height: 390px"
            dense
          >
            <v-list-item
              v-for="(user, index) in loginData"
              @click="loginRemember(user)"
              :key="index"
            >
              <v-list-item-avatar>
                <v-img
                  :src="
                    user.profileImage2
                      ? user.profileImage2
                      : user.businesstype === 'bloodtestlaboratory'
                      ? '/images/profile/innovaLab_Icon_WhiteFrame.png'
                      : user.businesstype === 'veterinary'
                      ? '/images/profile/innovaVet_Icon_WhiteFrame.png'
                      : user.businesstype === 'accounting_and_bill'
                      ? '/images/profile/innovaBill_Icon_WhiteFrame.png'
                      : '/images/profile/InnovaMed_WhiteFrime.png'
                  "
                  contain
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >{{ user.firstName }} {{ user.lastName }}</v-list-item-title
                >
                <v-list-item-subtitle class="text-center">
                  <v-btn @click="removeCookie(user)" color="error" text small
                    >Dejar de recordar</v-btn
                  >
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- loading overlay -->
    <block v-if="loading"></block>

    <!-- alert component -->
    <alert
      v-model="alert.show"
      :type="alert.type"
      :title="alert.title"
      :message="alert.message"
      btn-cancel-text="Aceptar"
    ></alert>
  </v-container>
</template>

<script>
import {
  setTokenMasterCookie,
  setBackendURLCookie,
  setIDKeyMasterCookie,
} from "@/helpers/token";
import {
  VUE_APP_ENV,
  VUE_APP_MASTER_FRONTEND_URL,
  VUE_APP_CARSEM_ID_KEYMASTER1,
  VUE_APP_CARSEM_ID_KEYMASTER2,
  VUE_APP_CARSEM_ID_KEYMASTER3,
} from "../config";

export default {
  name: "Login",
  data: () => ({
    valid: false,
    url: null,
    loading: false,
    imagelogin: "",
    businessShow: null,
    logomid: "",
    form: {
      username: "",
      password: "",
      business: "",
      rememberme: false,
    },
    alert: {
      show: false,
      type: "info",
      title: "",
      message: "",
    },
    rules: {
      required: (value) => !!value || "Este campo es obligatorio",
    },
    businessList: [],
    loginData: [],
    savedCredentials: null,
    BusinessLogoPath: null,
    isCarsem: false,
  }),
  computed: {
    selectedBusiness() {
      const selected = this.businessList.filter((item) => item.ID_Business === this.form.business)
      return selected.length ? selected[0] : {}
    },
    checktype() {
      return this.form.business;
    },
  },
  async mounted() {
    if (VUE_APP_ENV == 2) {
      if (!this.getCookie("tokenMaster")) {
        if (this.$route.query.token != undefined) {
          const token = decodeURIComponent(this.$route.query.token);
          const { data } = await this.$API.token.useToken(token);

          if (data.success) {
            setTokenMasterCookie(token);
            this.$router.push("/login");
            window.location.reload();
          } else {
            window.location.href = `${VUE_APP_MASTER_FRONTEND_URL}?redirect=1`;
          }
        } else {
          window.location.href = `${VUE_APP_MASTER_FRONTEND_URL}?redirect=1`;
        }
      }
      const { data } = await this.$API.token.verifyToken(
        this.getCookie("tokenMaster")
      );
      setIDKeyMasterCookie(data);

      setBackendURLCookie(data);
    }

    if (VUE_APP_ENV == 2) {
      try {
        for (const element of JSON.parse(this.getCookie("ID_KeyMaster"))) {
          const {
            data: { content: backendurlfromdb },
          } = await this.$API.token.verifyBackendURL({ idkeymaster: element });

          if (
            this.getCookie("BackendUrl") &&
            this.getCookie("BackendUrl").length > 0 &&
            backendurlfromdb != this.getCookie("BackendUrl")
          ) {
            document.cookie = `BackendUrl =;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${window.location.hostname};path=/`;
            document.cookie = `tokenMaster =;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${window.location.hostname};path=/`;
            window.location.href = `${VUE_APP_MASTER_FRONTEND_URL}?redirect=1`;
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    await this.getBusinessList();
    if (
      this.getCookie("details") != undefined &&
      this.getCookie("details").trim().length > 0
    ) {
      this.cookieLogin(JSON.parse(this.getCookie("details")));
    }
  },
  watch: {},
  methods: {
    onItemSelected() {
      try {
        let data;

        this.businessList.forEach((element) => {
          if (element.ID_Business == this.form.business) {
            data = element;
          }
        });

        console.log(data);
        

        const keyMastersArray = JSON.parse(this.getCookie("ID_KeyMaster"));
        const specialKeyMasters =
          keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER1) ||
          keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER2) ||
          keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER3);

        if (specialKeyMasters) {
          if (this.form.business === 33) {
            this.imagelogin = "login/loginbackcarsem.jpg";
            this.logomid = "/images/InnovaBanners/InnovaCarsem.jpg";
            this.isCarsem = true;
          }
          if (this.form.business === 44) {
            this.imagelogin = "login/loginbackresauto.jpg";
            this.logomid = "/images/InnovaBanners/InnovaResauto.png";
            this.isCarsem = true;
          }
        } else if (data.BusinessType == "bloodtestlaboratory") {
          this.BusinessLogoPath = data.BusinessLogoPath;
          this.imagelogin = "login/loginbacklab.jpg";
          this.logomid = "/images/InnovaBanners/InnovaLab.png";
          this.isCarsem = false;
        } else if (data.BusinessType == "veterinary") {
          this.BusinessLogoPath = data.BusinessLogoPath;
          this.imagelogin = "login/loginbackvet.jpg";
          this.logomid = "/images/InnovaBanners/InnovaVet.png";
          this.isCarsem = false;
        } else if (data.BusinessType == "accounting_and_bill") {
          this.BusinessLogoPath = data.BusinessLogoPath;
          this.imagelogin = "login/bill.png";
          this.logomid = "/images/InnovaBanners/InnovaBill.png";
          this.isCarsem = false;
          if(['9ede19c286c95b4aefd2462de691183a', '032b4fb2a80bacb6d7b912cd2fa6c005', 'ecbfba3a7cf5697352a16eecb423adbf','52e4d5b54ff5a95a878deca1491bdd36','2107b29ad7f6c567a5ea72523a108fd0'].includes(data.ID_KeyMaster ? data.ID_KeyMaster : data.sys_business_business_keys[0].ID_KeyMaster)) {
            this.logomid = "/images/InnovaBanners/InnovaSercondi.jpeg";
          }
        } else if (data.BusinessType == "medicalclinic") {
          this.BusinessLogoPath = data.BusinessLogoPath;
          this.imagelogin = "login/loginbackmed.png";
          this.logomid = "/images/InnovaBanners/InnovaMed.png";
          this.isCarsem = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    showAlert(type, title, message) {
      this.alert.type = type;
      this.alert.title = title;
      this.alert.message = message;
      this.alert.show = true;
    },
    submit() {
      this.loading = true;
      if (!this.$refs.formLogin.validate()) {
        this.showAlert(
          "error",
          "Error",
          "Tienes que completar todos los campos para iniciar sesión"
        );
        this.loading = false;
        return;
      }

      let bussnesdataSelected = this.businessList.filter(
        (item) => item.ID_Business == this.form.business
      );
      this.$API.auth
        .login(this.form, bussnesdataSelected)
        .then((res) => {
          this.alert = false;
          this.cookieManagement(this.form.rememberme, res.data);

          this.$router.push({ path: "/" });
          this.$API.general.systemParam();
        })
        .catch((error) => {
          if (!error.success) {
            this.showAlert(
              "warning",
              "Credenciales incorrectas",
              "El usuario o contraseña es incorrecto"
            );
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getBusinessList() {
      this.loading = true;
      try {
        const response = await this.$API.business.businessList({
          ID_KeyMaster: this.getCookie("ID_KeyMaster"),
          ID_Desktop: this.getCookie("ID_Desktop"),
        });

        if (this.businessShow) {
          for (var i = 0; i <= response.length; i++) {
            if (response[i]["BusinessName"] == this.businessShow) {
              this.businessList.push(response[i]);
              this.form.business = this.businessList[0].ID_Business;
            }
          }
        } else {
          this.businessList = response;
        }

        let data = this.businessList[0].BusinessType;
        this.BusinessLogoPath = this.businessList[0].BusinessLogoPath;
        this.form.business = this.businessList[0].ID_Business;

        if (data == "bloodtestlaboratory") {
          this.imagelogin = "login/loginbacklab.jpg";
          this.logomid = "/images/InnovaBanners/InnovaLab.png";
        } else if (data == "veterinary") {
          this.imagelogin = "login/loginbackvet.jpg";
          this.logomid = "/images/InnovaBanners/InnovaVet.png";
        } else if (data == "accounting_and_bill") {
          this.imagelogin = "login/bill.png";
          this.logomid = "/images/InnovaBanners/InnovaBill.png";
        } else if (data == "medicalclinic") {
          this.imagelogin = "login/loginbackmed.png";
          this.logomid = "/images/InnovaBanners/InnovaMed.png";
        }
        this.onItemSelected();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    cookieManagement(rememberme, data) {
      if (rememberme == true) {
        this.pushManager(data);
        var myDate = new Date();
        myDate.setMonth(myDate.getMonth() + 12);
        document.cookie =
          "details =" +
          JSON.stringify(this.loginData) +
          ";expires=" +
          myDate +
          ";domain=" +
          window.location.hostname +
          ";path=/";
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    cookieLogin(login) {
      if (login) this.loginData = login;
    },
    pushManager(data) {
      if (this.loginData.length > 0) {
        let valite = false;
        this.loginData.forEach((item) => {
          if (item.id == data.id) {
            valite = true;
          }
        });
        if (!valite) {
          this.loginData.push(data);
        }
      } else {
        this.loginData.push(data);
      }
    },
    loginRemember(user) {
      this.form.username = user.username;
      this.businessList.forEach((element) => {
        if (element.ID_Business == user.businessid) {
          this.form.business = element.ID_Business;
          this.onItemSelected();
        }
      });
      this.$refs.pass.focus();
    },
    removeCookie(user) {
      let array = this.loginData.filter((item) => item.id != user.id);
      this.loginData = array;
      var myDate = new Date();
      myDate.setMonth(myDate.getMonth() + 12);
      document.cookie =
        "details =" +
        JSON.stringify(this.loginData) +
        ";expires=" +
        myDate +
        ";domain=" +
        window.location.hostname +
        ";path=/";
    },
  },
};
</script>
