<template>
    <window-portal :open="data.show" @close="data.show = false">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center><div><p style="font-size: 30px;">Vista previa de impresión de Constancia Médica</p></div></center>
            <br/>
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
            </center>
            <center><div v-if="loading"><p>Cargando...</p></div></center>
        </div>
        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">

                <!--Configuración de la pagina-->
                <div class="page" ref="dataprint" style="position: relative; width: 1000px; min-height: 1294px; background: white; margin: 0 auto; margin-bottom: 20px;">
                    
                    <!-- Encabezado-->
                    <div v-html="header" style="width: 100% !important; padding-top: 50px"></div>
                        
                    <!-- Configuración del contenido de la pagina -->
                    <div class="subpage" style="height: 950px; padding-top: 20px; overflow: hidden;">
                        <center><h3>CONSTANCIA MÉDICA</h3></center>
                        <div style="margin-left: 80px; margin-right: 50px;">
                            <center></center>
                            <table style="font-size: 12pt; border-collapse: collapse; width: 100%;">
                                <tr>
                                    <td colspan="4" style="padding-right:20px; padding-bottom: 20px;">A QUIEN INTERESE</td>
                                </tr>
                                <tr>
                                    <td colspan="4" style="padding-right:20px; padding-bottom: 20px;">EL ( LA ) INGRASCRITO (A) MEDICO (A) VETERINARIO (A) HA REALIZADO EL EXAMEN CLINICO AL EJEMPLAR:</td>
                                </tr>
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Paciente</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.patientinfo.namepatient}}</td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Ficha Paciente</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.patientinfo.id_patient}}</td>
                                </tr>
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Especie</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.patientinfo.species}}</td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Sexo</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.patientinfo.gender}}</td>
                                </tr>
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Raza</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.patientinfo.breed}}</td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Fecha de naciemiento</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.patientinfo.dateofbirth}}</td>
                                </tr>
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Color</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.patientinfo.color}}</td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Registro</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.patientinfo.registrynumber}}</td>
                                </tr>
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Tatuaje</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.patientinfo.tattoo}}</td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>MicroChip</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">{{data.data.patientinfo.microchip}}</td>
                                </tr>
                                <tr>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>Propietario</u></td>
                                    <td style="padding-right:20px; padding-bottom: 10px; width: 25%;">                                    {{ data.data.patientinfo.customer.Is_a_Business ? data.data.patientinfo.customer.BusinessName :  data.data.patientinfo.customer.FirstName + " " +  data.data.patientinfo.customer.LastName}}</td>
                                </tr>
                            </table>

                            <div style="padding-right:20px; padding-bottom: 10px; width: 100% !important;">
                                <p style="padding-right:20px; padding-bottom: 10px; width: 25%;"><u>HACE CONSTAR QUE: </u></p>
                                <p style="padding-right:20px; padding-bottom: 10px; width: 100% !important;" v-html="data.data.healthCertificate.findingHim"></p>
                            </div>
                                
                            <table style="font-size: 12pt; border-collapse: collapse; width: 100%;">
                                <tr>
                                    <td colspan="4" style="padding-right:20px; padding-bottom: 20px; padding-top: 20px;">POR TODO ELLO Y PARA LOS USOS QUE AL INTERESADO CONVENGAN EXTIENDO LA PRESENTE CERTIFICACIÓN</td>
                                </tr>

                                <tr>
                                    <td colspan="4" style="padding-right:20px; padding-bottom: 0px; padding-top: 20px;">{{data.data.branchinfo.BranchAddress + " , " + data.data.healthCertificate.date}}</td>
                                </tr>

                                <tr>
                                    <td colspan="4" style="padding-right:20px; padding-top: 10px;">
                                        <center><img width="150px" height="150px" :src="'data:image/png;base64,' + data.data.useData.seal" crossorigin="anonymous"  /></center>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4" style="padding-right:20px; padding-bottom: 20px;">
                                        <center>F. ___________________________________</center>
                                        <br/>
                                        <center><p>{{data.data.healthCertificate.sign}}</p></center>
                                    </td>
                                </tr>
                                
                            </table>
                        </div>
                    </div>

                    <div v-html="footer" style="width: 100% !important;"></div>
                </div>

        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
//import html2canvas from "html2canvas";
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
export default {
    name: "HealthCertificate",
    props: ['data'],
    components: {WindowPortal},
    data() {
        return {
            loading: false,
            pages: 1,
            header: null,
            footer: null,
            branch: JSON.parse(localStorage.getItem("user")).branch,
            business: JSON.parse(localStorage.getItem("user")).businessid,
        }
    },
    mounted() {
        this.getHeaderFooter()
        this.loadContent()
    },
    computed: {
    },
    methods: {
        getHeaderFooter() {
            let search = {
                ID_Branch: this.branch,
                ID_Business: this.business
            };
            this.$API.HF.getHF(search).then((data) => {
                let header = [], footer = [];
                header = data.filter((x) => x.State == '1' && x.Type == 'Header');
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
                this.header = this.header.replace(/(\n)/gm, "");
                footer = data.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
            })
        },

        async Print(){
            this.loading = true
            
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                    callback: function(doc){
                        doc.output()
                    },
                    x: 0,
                    y: 0,
                    margin: [0, 0, 0, 0],
                    html2canvas:{ 
                        scale: 0.612,
                        //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                        useCORS: false,
                        logging: false,
                        allowTaint: true
                    }
            })
            
            doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        }
    }
}
</script>

<style scoped>

    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
        background-color: #F29D35 !important;
        color: white !important;
    }
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }
    

</style>
