import axios from "./axios";
export default {
    /**
     * Get Order Id
     * @api {Get} /manageorder/orderid
     */
    getOrderId() {
        const options = {
            method: 'GET',
            url: '/manageorder/orderid',
            headers: {'content-type': 'text/html'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getOneOrder(params) {
        const options = {
            method: 'GET',
            url: 'manageorder/getOnlyOne',
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * Add Purchase order for provider
     * @api {POST} manageorder
     */
    addOrder(data) {
        const options = {

            method: 'POST',
            url: 'manageorder',
            headers: {'content-type': 'multipart/form-data'},
            data: data
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * Get All Products for Providers
     * @api {Get} manageproduct/provider
     * @param {object} params
     */
    getProvidersProducts(params) {
        const options = {
            method: 'GET',
            url: 'manageorder/products/filtered',
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getProvidersProductsHistory(params) {
        const options = {
            method: 'GET',
            url: 'manageorder/products/order/history',
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getProvidersProductsFiltered(params) {
        const options = {
            method: 'GET',
            url: 'manageorder/products/order/filtered',
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getProvidersProductsFilteredByDate(params) {
        const options = {
            method: 'GET',
            url: 'manageorder/products/order/filteredByDate',
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getProvidersProductsOrdersFiltered(params) {
        const options = {
            method: 'GET',
            url: 'manageorder/products/providers/order/filtered',
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * Get All product for order
     * @api {Get} manageorder/products/:id
     */
    getProductItems(purchaseorder_id) {
        const options = {
            method: 'GET',
            url: `manageorder/products/${purchaseorder_id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    /**
     * Delete Order
     * @api {DELETE} manageorder/:id
     */
    deleteOrder(id) {
        const options = {
            method: 'DELETE',
            url: `manageorder/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    logicalDeleteOrder(id) {
        const options = {
            method: "PUT",
            url: `manageorder/logicaldelete/${id}`,
            headers: { "content-type": "application/json" },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.message)
                });
        });
    },

    updateStateOrder(data){
        const options = {
            method: 'POST',
            url: `manageorder/updatestatus`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },

    generateWithholdingReceipt(data){
        const options = {
            method: 'POST',
            url: `manageorder/generatewithholdingreceipt`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },

    generateWithholdingReceipt2(data){
        const options = {
            method: 'POST',
            url: `manageorder/generatewithholdingreceipt2`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },

    getDataCr(data){
        const options = {
            method: 'POST',
            url: `manageorder/getdatacr`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },

    getDataCrHistory(data){
        const options = {
            method: 'POST',
            url: `manageorder/getdatacr/list/history`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },
    

    getDataCrFiltered(data){
        const options = {
            method: 'POST',
            url: `manageorder/getdatacr/list/filtered`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },

    getDataCrDetail(data){
        const options = {
            method: 'POST',
            url: `manageorder/getdatacrdetail`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },
    getAllDataCr(data){
        const options = {
            method: 'POST',
            url: `manageorder/getAllDataCrDetail`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },
    getAllDataCrWithDate(data){
        const options = {
            method: 'GET',
            url: `manageorder/getAllDataCrDetailwithDate`,
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.messageage)
                })
        })
    },

    invalidCr(data){
        const options = {
            method: 'POST',
            url: 'manageorder/invalidCr',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

}
