<template>
  <div ref="container" style="z-index: 1">
    <block v-if="loading"></block>
    <!-- <v-card class="white box">
      <v-row class="d-flex justify-center">
        <v-col v-if="workingIn == ''" cols="12" md="4" style="text-align: center;">
          <v-alert  class="btndisable" style="padding: 4px !important;"> nueva factura</v-alert>
        </v-col>
        <v-col v-else cols="12" md="4" style="text-align: center;">
          <v-alert  class="btndisable" style="padding: 4px !important;">{{workingIn}}</v-alert>
        </v-col>
        <v-col cols="12" md="4" style="padding-bottom: 13px !important;" class="fast-ease-in-transitioned-element" >
          <date-picker 
            label="Fecha"
            @dateSelected="(param) => (form.invoicedate = param)"
            dense
            class="fast-ease-in-transitioned-element"
          >
          </date-picker>
        </v-col>
      </v-row>
      
    </v-card> -->
    <div>
      <v-row id="toshowdiv" v-show="togglehide">
        <v-col cols="12">
          <v-card class="white box">
            <v-card-text style="padding: 5px 10px 0px 10px !important">
              <div>
                <v-row
                  class="d-flex justify-center"
                  style="padding: 1px !important"
                >
                  <v-col
                    v-if="workingIn == ''"
                    cols="12"
                    md="11"
                    style="
                      text-align: center;
                      padding: 0px 0px 0px 0px !important;
                    "
                  >
                    <v-alert
                      class="btndisable"
                      style="
                        padding: 4px !important;
                        margin-bottom: 0px !important;
                      "
                      >AGREGANDO NUEVO DTE: {{ retornarDTEname }}</v-alert
                    >
                  </v-col>
                  <v-col
                    v-else
                    cols="12"
                    md="11"
                    style="
                      text-align: center;
                      padding: 0px 0px 0px 0px !important;
                    "
                  >
                    <v-alert
                      class="btndisable"
                      style="
                        padding: 4px !important;
                        margin-bottom: 0px !important;
                      "
                    >
                      <pre style="font-family: inherit">{{ workingIn }}</pre>
                    </v-alert>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                    style="
                      text-align: right;
                      padding: 0px 0px 0px 0px !important;
                    "
                  >
                    <v-alert
                      class="btndisable2"
                      style="
                        padding: 4px !important;
                        margin-bottom: 0px !important;
                      "
                      ><button
                        @click="togglehide = !togglehide"
                        v-show="togglehide"
                        style="padding: 0px 4px 0px 4px !important"
                      >
                        Ampliar
                      </button></v-alert
                    >
                  </v-col>
                </v-row>
                <v-row
                  class="d-flex justify-center mt-5 mb-5"
                  style="
                    padding: 1px !important;
                    font-weight: bold !important;
                    color: black !important;
                    font-size: 25px !important;
                  "
                >
                  <v-col
                    cols="12"
                    md="1"
                    class="d-flex justify-center"
                    style="
                      padding-bottom: 0px !important;
                      justify-content: left !important;
                    "
                  >
                    <v-btn class="btnsave" @click="showCustomer = !showCustomer"
                      ><v-icon>mdi-file-account</v-icon></v-btn
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    class="d-flex justify-center"
                    style="
                      padding-bottom: 0px !important;
                      justify-content: left !important;
                    "
                  >
                    {{ form.idcustomer }}
                  </v-col>
                  <v-col
                    cols="12"
                    md="7"
                    class="d-flex justify-center"
                    style="
                      padding-bottom: 0px !important;
                      justify-content: left !important;
                    "
                  >
                    {{ form.legalname }}
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    class="d-flex justify-center"
                    style="
                      padding-bottom: 0px !important;
                      justify-content: right !important;
                    "
                  >
                    {{ form.invoicedate }}
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        id="tohidediv"
        v-show="!togglehide"
        style="margin-top: -10px !important"
      >
        <!--v-col cols="12">
          <v-card outlined elevation="2" class="box white">
            <br />
            <v-card-text>
              <v-row align="center">


              </v-row>
            </v-card-text>
          </v-card>
          <br />
        </v-col-->
        <v-col cols="12">
          <v-card class="white box">
            <v-card-text style="padding: 8px 10px 0px 10px !important">
              <div>
                <v-row class="d-flex justify-center">
                  <v-col
                    v-if="workingIn == ''"
                    cols="12"
                    md="11"
                    style="
                      text-align: center;
                      padding: 0px 0px 0px 0px !important;
                    "
                  >
                    <v-alert
                      class="btndisable"
                      style="
                        padding: 4px !important;
                        margin-bottom: 0px !important;
                      "
                      >AGREGANDO NUEVO DTE: {{ retornarDTEname }}</v-alert
                    >
                  </v-col>
                  <v-col
                    v-else
                    cols="12"
                    md="11"
                    style="
                      text-align: center;
                      padding: 0px 0px 0px 0px !important;
                    "
                  >
                    <v-alert
                      class="btndisable"
                      style="
                        padding: 4px !important;
                        margin-bottom: 0px !important;
                      "
                    >
                      <pre style="font-family: inherit">{{ workingIn }}</pre>
                    </v-alert>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                    style="
                      text-align: right;
                      padding: 0px 0px 0px 0px !important;
                    "
                  >
                    <v-alert
                      class="btndisable2"
                      style="
                        padding: 4px !important;
                        margin-bottom: 0px !important;
                      "
                      ><button
                        @click="togglehide = !togglehide"
                        v-show="!togglehide"
                        style="padding: 0px 4px 0px 4px !important"
                      >
                        Minimizar
                      </button></v-alert
                    >
                  </v-col>
                </v-row>

                <v-row
                  class="d-flex mt-5 mb-5"
                  style="
                    padding: 4px 4px 0px 4px !important;
                    margin-bottom: 0px !important;
                  "
                >
                  <v-col
                    cols="12"
                    md="1"
                    class="d-flex justify-center"
                    style="padding-bottom: 13px !important"
                  >
                    <!-- <v-col :width="legalidInvoiceCondition() ? 4 : 5" class="d-flex justify-center"> -->
                    <v-btn class="btnsave" @click="showCustomer = !showCustomer"
                      ><v-icon>mdi-file-account</v-icon></v-btn
                    >
                  </v-col>
                  <!-- <v-col cols="12" md="2">
                    <v-autocomplete outlined dense label="ID" v-model="form.idcustomer" :items="customersList"
                      item-text="ID_Customer" item-value="ID_Customer" v-on:change="customerSelectOnchange"
                      attach></v-autocomplete>
                  </v-col> -->
                  <v-col cols="12" md="2">
                    <CustomAutoComplete
                      key="idCustomer"
                      v-if="customersList.length > 0"
                      :label="'ID'"
                      v-model="form.idcustomer"
                      :search="{ ...search, filteredFrom: 2 }"
                      :items="customersList"
                      :item-text="'ID_Customer'"
                      :filter-endpoint="$API.customers.filterCustomers"
                      :item-value="'ID_Customer'"
                      @select="
                        (e) => {
                          form.idcustomer = e;
                          customerSelectOnchange(e);
                        }
                      "
                      @updateItems="updateCustomersLIst"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <CustomAutoComplete
                      key="Customer"
                      v-if="customersList.length > 0"
                      :label="'Cliente'"
                      v-model="form.idcustomer"
                      :search="{ ...search, filteredFrom: 2 }"
                      :items="customersList"
                      :item-text="'itemText'"
                      :filter-endpoint="$API.customers.filterCustomers"
                      :item-value="'ID_Customer'"
                      @select="
                        (e) => {
                          form.idcustomer = e;
                          customerSelectOnchange(e);
                        }
                      "
                      @updateItems="updateCustomersLIst"
                    />
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <v-btn
                    class="text-uppercase btnsave mb-2"
                    @click.prevent="searchCustomerDialog = true"
                    >{{ $t("vwinvoices_csearchcustomer") }}</v-btn>

                    <v-btn
                    class="text-uppercase btnsave mb-2"
                    @click.prevent="searchSeveralCustomer"
                    >CLIENTES VARIOS</v-btn>
                  </v-col>
                  <!-- <v-col cols="12" md="7">
                    <div :style="`height: 400px; overflow-y: scroll; box-shadow: 0px 5px 10px 0px; margin-top: -27px; z-index: 8000; width: 100%`">
                      <v-list lines="one" style="">
                        <v-list-item v-for="item in customersList" :key="item['ID_Customer']" @click="selectOption(item['ID_Customer'])" :id="item['ID_Customer']">
                            <v-list-item-content>
                            <v-list-item-title>{{ item['itemText'] }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-col> -->
                  <v-col
                    cols="12"
                    md="3"
                    lg="2"
                    style="padding-bottom: 13px !important"
                    class="fast-ease-in-transitioned-element"
                  >
                    <date-picker
                      label="Fecha"
                      v-model="form.invoicedate"
                      @dateSelected="(param) => (form.invoicedate = param)"
                      dense
                      class="fast-ease-in-transitioned-element"
                      :disbaled="isCarsem ? true : false"
                    >
                    </date-picker>
                  </v-col>
                  <!-- <v-col cols="12" md="3" v-if="legalidInvoiceCondition()"> -->
                  <v-col cols="12" md="3" v-if="false">
                    <v-text-field
                      outlined
                      dense
                      class="text-input-red"
                      style="font-weight: bold"
                      v-model="form.legalidinvoice"
                      label="Correlativo fiscal"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="6" class="d-flex justify-space-between">
                    <v-row>
                    </v-row>
                  </v-col>
                  <v-col cols="2" />
                  <v-col cols="3" >
                    <v-text-field class="text-input-red" style="font-weight: bold"
                      v-model="correlative"
                      label="Correlativo factura"
                      disabled
                      dense
                    ></v-text-field
                    
                  </v-col>
                  <v-col cols="11" class="d-flex justify-space-between">
                    <div>
                      <div v-if="isCustomerSelected">
                        <h4>
                          <b>{{ $t("vwinvoices_cidclient") }}</b>
                          {{ form.idcustomer ? form.idcustomer : form.customer }}
                          <b>{{ form.isa_business ? "Negocio: " : "Cliente: " }}</b>
                          {{
                            this.form.isa_business
                            ? form.businessname
                            : form.firstname ? (form.firstname + " " + form.lastname) : 'N/A'
                          }}
                      </h4>
                      </div>
                      <div v-else>
                        <h4>
                          <b>No se ha seleccionado cliente/negocio</b>
                        </h4>
                      </div>
                      
                      <div v-if="isCustomerSelected && validateform" >
                        <h3>Faltan datos por rellenar para el cliente </h3>
                      </div>
                    </div>
                  </v-col> -->
                </v-row>
                <v-row
                  v-if="isCustomerSelected && validateform"
                  class="mb-5"
                  style="
                    padding: 4px 4px 10px 4px !important;
                    margin-bottom: 0px !important;
                    margin-top: -25px !important;
                  "
                >
                  <v-col cols="12" md="12">
                    <!-- <div  > -->
                    <h3>Faltan datos por rellenar para el cliente</h3>
                    <!-- </div> -->
                  </v-col>
                </v-row>
                <v-form ref="posForm">
                  <v-row
                    class=""
                    style="
                      padding: 4px 4px 0px 4px !important;
                      margin-bottom: 0px !important;
                    "
                  >
                    <v-col
                      cols="12"
                      md="3"
                      class="fast-ease-in-transitioned-element"
                    >
                      <v-select
                        outlined
                        dense
                        attach
                        v-model="form.invoicetype"
                        @change="getInvoiceId().subscribe()"
                        :items="filterType"
                        item-text="name"
                        item-value="key"
                        :label="$t('vwinvoices_cinvoicetype')"
                        class="fast-ease-in-transitioned-element"
                      >
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      class="fast-ease-in-transitioned-element"
                    >
                      <!-- <v-select outlined dense 
                        v-model="form.invoicepaymenttype" attach
                        :items="['Tarjeta de crédito', 'Efectivo', 'Cheque', 'Transferencia bancaria', 'Otro']"
                        label="Método de pago"
                      >
                      </v-select> -->
                      <v-select
                        v-if="form.isquote == 0 && pospayments == 0"
                        outlined
                        dense
                        v-model="form.invoicepaymenttype"
                        attach
                        :items="paymentTypes"
                        item-text="Valores"
                        item-value="Valores"
                        label="Método de pago"
                        class="fast-ease-in-transitioned-element"
                        :rules="required"
                      >
                      </v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                      v-if="
                        form.invoicepaymenttype != 'Billetes y monedas' &&
                        form.invoicepaymenttype != undefined &&
                        form.invoicepaymenttype != '' &&
                        form.invoicetype != 'DND' &&
                        form.invoicetype != 'DNC'
                      "
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="form.invoicereference"
                        label="N. Referencia"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <div id="parent">
                        <v-select
                          v-if="form.isquote == 0"
                          outlined
                          dense
                          attach
                          v-model="form.invoicestatusdescription"
                          :items="checkCountryInvoiceStatuses"
                          label="Estado de la factura"
                          :rules="required"
                        >
                        </v-select>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                      v-if="form.invoicestatusdescription == 'Crédito'"
                    >
                      <v-select
                        v-if="form.invoicestatusdescription == 'Crédito'"
                        outlined
                        dense
                        v-model="form.invoicecreditday"
                        attach
                        :items="creditDays"
                        label="Días de crédito"
                        :readonly="
                          !(
                            form.creditperiod == 0 &&
                            form.creditstatus == 'DISPONIBLE'
                          )
                        "
                        :rules="required"
                      >
                      </v-select>
                      <v-select
                        v-else
                        outlined
                        dense
                        v-model="form.invoicecreditday"
                        attach
                        :items="creditDays"
                        label="Días de crédito"
                        :readonly="
                          !(
                            form.creditperiod == 0 &&
                            form.creditstatus == 'DISPONIBLE'
                          )
                        "
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row
                    class=""
                    style="
                      padding: 0px 0px 0px 0px !important;
                      margin-bottom: -25px !important;
                    "
                  >
                    <v-col
                      cols="12"
                      :md="4"
                      v-if="legalidInvoiceCondition()"
                      class="fast-ease-in-transitioned-element"
                    >
                      <v-text-field
                        v-if="form.invoicetype == 'CCF'"
                        outlined
                        dense
                        class="text-input-red fast-ease-in-transitioned-element"
                        style="font-weight: bold"
                        v-model="form.legalidinvoice"
                        label="Correlativo fiscal"
                        disabled
                        :rules="required"
                      ></v-text-field>
                      <v-text-field
                        v-else
                        outlined
                        dense
                        class="text-input-red fast-ease-in-transitioned-element"
                        style="font-weight: bold"
                        v-model="form.legalidinvoice"
                        label="Correlativo fiscal"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col cols="12" md="2">
                      <v-autocomplete outlined dense label="ID del Vendedor" v-model="form.ID_Seller" :items="sellersList"
                        item-text="itemText" item-value="ID_Seller" 
                        attach></v-autocomplete>
                    </v-col>
                  </v-row> -->

                  <v-row
                    class="mt-5 mb-5"
                    style="
                      padding: 4px 4px 0px 4px !important;
                      margin-bottom: 0px !important;
                    "
                  >
                    <v-col
                      cols="12"
                      md="1"
                      v-show="mostrarOcultarVendedor()"
                      class="d-flex justify-center pb-3"
                    >
                      <!-- <v-col :width="legalidInvoiceCondition() ? 4 : 5" class="d-flex justify-center"> -->

                      <v-btn
                        v-if="sellersList.length > 0"
                        class="btnsave"
                        @click="searchSellerDialog = !searchSellerDialog"
                        ><v-icon v-if="sellersList.length > 0"
                          >mdi-magnify</v-icon
                        ></v-btn
                      >
                    </v-col>
                    <v-col cols="12" md="2" v-show="mostrarOcultarVendedor()">
                      <CustomAutoComplete
                        v-if="sellersList.length > 0"
                        :label="`ID Vendedor${
                          POSRequiredIdSeller === 1 ? '*' : ''
                        } `"
                        v-model="form.ID_Seller"
                        :search="{ ...search }"
                        :items="sellersList"
                        :item-text="'ID_Seller'"
                        :filter-endpoint="$API.sellers.getAllSellersFiltered"
                        :item-value="'ID_Seller'"
                        @select="
                          (e) => {
                            form.ID_Seller = e;
                          }
                        "
                        @updateItems="updateSellersList"
                        :required="POSRequiredIdSeller === 1"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      :md="
                        form.invoicetype == 'CDD' || form.invoicetype == 'FEX'
                          ? 2
                          : 7
                      "
                      v-show="mostrarOcultarVendedor()"
                    >
                      <CustomAutoComplete
                        v-if="sellersList.length > 0"
                        :label="`Vendedor${
                          POSRequiredIdSeller === 1 ? '*' : ''
                        } `"
                        v-model="form.ID_Seller"
                        :search="{ ...search }"
                        :items="sellersList"
                        :item-text="'FullName'"
                        :filter-endpoint="$API.sellers.getAllSellersFiltered"
                        :item-value="'ID_Seller'"
                        @select="
                          (e) => {
                            form.ID_Seller = e;
                          }
                        "
                        @updateItems="updateSellersList"
                        :required="POSRequiredIdSeller === 1"
                      />
                    </v-col>
                    <v-col cols="12" md="2" v-if="EnableZoneConfiguration">
                      <v-select
                        :items="ZoneConfiguration"
                        v-model="form.zoneclient"
                        label="Zona del cliente: "
                        outlined
                        attach
                        dense
                      ></v-select>
                    </v-col>
                    <v-col
                      v-if="
                        form.invoicetype == 'CDD' || form.invoicetype == 'FEX'
                      "
                      cols="12"
                      md="2"
                    >
                      <v-select
                        v-if="form.invoicetype == 'CDD'"
                        outlined
                        dense
                        v-model="form.codasociado"
                        attach
                        :items="codigosAsociados"
                        item-value="id"
                        item-text="valor"
                        label="Código de documento asociado"
                        class="fast-ease-in-transitioned-element"
                        :rules="required"
                      >
                      </v-select>
                      <v-select
                        v-if="form.invoicetype == 'FEX'"
                        outlined
                        dense
                        v-model="form.codasociado"
                        attach
                        :items="codigosAsociadosEx"
                        item-value="id"
                        item-text="valor"
                        label="Código de documento asociado"
                        class="fast-ease-in-transitioned-element"
                      >
                      </v-select>
                    </v-col>
                    <v-col v-if="form.invoicetype == 'CDD'" cols="12" md="2">
                      <v-text-field
                        v-model="form.docasociado"
                        dense
                        outlined
                        label="Documento asociado"
                        :rules="required"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="form.invoicetype == 'CDD'" cols="12" md="3">
                      <v-text-field
                        v-model="form.detalleasociado"
                        dense
                        outlined
                        label="Detalle de documento asociado"
                        :rules="required"
                      ></v-text-field>
                    </v-col>

                    <v-col v-if="form.invoicetype == 'FEX'" cols="12" md="2">
                      <v-text-field
                        v-model="form.docasociado"
                        dense
                        outlined
                        label="Documento asociado"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="form.invoicetype == 'FEX'" cols="12" md="3">
                      <v-text-field
                        v-model="form.detalleasociado"
                        dense
                        outlined
                        label="Detalle de documento asociado"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="form.invoicetype == 'FEX' && form.codasociado == 4"
                      cols="12"
                      md="2"
                    >
                      <v-select
                        outlined
                        dense
                        v-model="form.modotransp"
                        attach
                        :items="modoTransporte"
                        item-value="id"
                        item-text="valor"
                        label="Modo de transporte"
                        class="fast-ease-in-transitioned-element"
                      >
                      </v-select>
                    </v-col>
                    <v-col
                      v-if="form.invoicetype == 'FEX' && form.codasociado == 4"
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="form.numconductor"
                        dense
                        outlined
                        label="N. identificación de conductor"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="form.invoicetype == 'FEX' && form.codasociado == 4"
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="form.nombreconductor"
                        dense
                        outlined
                        label="Nombre del conductor"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="form.invoicetype == 'FEX' && form.codasociado == 4"
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="form.placatrans"
                        dense
                        outlined
                        label="Placa del transporte"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="form.invoicetype == 'FEX'" cols="12" md="2">
                      <v-text-field
                        v-model="form.seguro"
                        type="number"
                        dense
                        outlined
                        label="Seguro"
                        :rules="required"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="form.invoicetype == 'FEX'" cols="12" md="3">
                      <v-text-field
                        v-model="form.flete"
                        type="number"
                        dense
                        outlined
                        label="Flete"
                        :rules="required"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="form.invoicetype == 'DNR'" cols="12" md="2">
                      <v-select
                        outlined
                        dense
                        v-model="form.bientitulo"
                        attach
                        :items="bienTitulo"
                        item-value="id"
                        item-text="value"
                        label="Bienes Remitidos a Títulos de"
                        class="fast-ease-in-transitioned-element"
                        :rules="required"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-form>

                <div
                  :class="`${showBgcolorNotes} mt-3`"
                  @click="showComment = !showComment"
                >
                  <center>Comentario de factura</center>
                </div>
                <v-dialog v-model="showComment" max-width="1000">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Comentario de factura</v-card-title
                    >
                    <v-card-text>
                      <quill-editor
                        :options="quillOptions"
                        class="mt-5"
                        @change="onEditorChange($event)"
                        v-model="form.invoiceinternalnote"
                      ></quill-editor>
                    </v-card-text>
                    <v-card-actions>
                      <v-col class="text-right">
                        <v-btn
                          @click="showComment = !showComment"
                          class="btndisable"
                        >
                          Continuar
                        </v-btn>
                      </v-col>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <!--div style="width: 100%; margin: 0;" class="tabs" @click="showCustomer = !showCustomer">
                  <v-icon v-if="showCustomer" color="white" left>
                    mdi-minus
                  </v-icon>
                  <v-icon v-else color="white" left> mdi-plus </v-icon>
                  Información del cliente
              </div-->

              <v-dialog v-model="showCustomer" scrollable max-width="1000">
                <v-card height="100%" style="overflow: auto">
                  <v-card-title
                    style="display: flex; justify-content: space-between"
                  >
                    <div class="text-h5">Información del cliente</div>
                    <v-checkbox
                      v-model="newCostumer"
                      :disabled="isCustomerSelected"
                      label="Nuevo cliente"
                    ></v-checkbox>
                  </v-card-title>
                  <v-card-text>
                    <form v-show="showCustomer">
                      <br /><br />
                      <!--div
                        class="mt-2 mb-5"
                        style="width: 100%"
                        v-if="isCustomerSelected"
                      >
                        <h4>
                          <b>{{ $t("vwinvoices_cidclient") }}</b>
                          {{ form.idcustomer }}
                          <b>{{ form.isa_business ? "Negocio: " : "Cliente: " }}</b>
                          {{
                            this.form.isa_business
                              ? form.businessname
                              : form.firstname + " " + form.lastname
                          }}
                        </h4>
                      </div-->
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-if="
                              form.invoicetype == 'FCF' ||
                              form.invoicetype == 'CCF'
                            "
                            outlined
                            dense
                            v-model="form.legalname"
                            :label="$t('vwinvoices_clegalname')"
                            :rules="required"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            outlined
                            dense
                            v-model="form.legalname"
                            :label="$t('vwinvoices_clegalname')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            outlined
                            dense
                            v-model="form.legaladdress"
                            :label="$t('vwinvoices_clegaladdress')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" v-if="!form.isa_business">
                          <v-text-field
                            v-if="
                              form.invoicetype == 'CCF' ||
                              (form.invoicetype == 'FCF' &&
                                sumObject.total > 25000.0)
                            "
                            outlined
                            dense
                            v-model="form.dui"
                            label="Documento identidad"
                            @input="getDuiMask"
                            :rules="required"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            outlined
                            dense
                            v-model="form.dui"
                            label="Documento identidad"
                            @input="getDuiMask"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" v-if="form.isa_business">
                          <v-text-field
                            v-if="
                              form.invoicetype == 'FCF' &&
                              sumObject.total > 25000.0
                            "
                            outlined
                            dense
                            v-model="form.dui"
                            label="Documento identidad"
                            @input="getDuiMask"
                            :rules="required"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            outlined
                            dense
                            v-model="form.dui"
                            label="Documento identidad"
                            @input="getDuiMask"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" v-if="!form.isa_business">
                          <v-text-field
                            v-if="form.invoicetype == 'CCF'"
                            outlined
                            dense
                            v-model="form.phonenumber"
                            label="Número de télefono:"
                            :rules="required"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            outlined
                            dense
                            v-model="form.phonenumber"
                            label="Número de télefono:"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" v-if="form.isa_business">
                          <v-text-field
                            v-if="form.invoicetype == 'CCF'"
                            outlined
                            dense
                            v-model="form.businesscontactphone"
                            label="Número de télefono:"
                            :rules="required"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            outlined
                            dense
                            v-model="form.businesscontactphone"
                            label="Número de télefono:"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" v-if="form.isa_business">
                          <v-text-field
                            v-if="form.invoicetype == 'CCF'"
                            outlined
                            dense
                            v-model="form.businesscontactphone"
                            label="Número de télefono:"
                            :rules="required"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            outlined
                            dense
                            v-model="form.businesscontactphone"
                            label="Número de télefono:"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" v-if="!form.isa_business">
                          <v-text-field
                            v-if="form.invoicetype == 'CCF'"
                            outlined
                            dense
                            v-model="form.email"
                            label="Correo eléctronico: "
                            :rules="required"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            outlined
                            dense
                            v-model="form.email"
                            label="Correo eléctronico: "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" v-if="form.isa_business">
                          <v-text-field
                            v-if="form.invoicetype == 'CCF'"
                            outlined
                            dense
                            v-model="form.businesscontactemail"
                            label="Correo eléctronico: "
                            :rules="required"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            outlined
                            dense
                            v-model="form.businesscontactemail"
                            label="Correo eléctronico: "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" v-if="form.isa_business">
                          <v-text-field
                            v-if="form.invoicetype == 'CCF'"
                            outlined
                            dense
                            v-model="form.businesscontactemail"
                            label="Correo eléctronico: "
                            :rules="required"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            outlined
                            dense
                            v-model="form.businesscontactemail"
                            label="Correo eléctronico: "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field
                            v-if="form.invoicetype == 'CCF'"
                            outlined
                            dense
                            v-model="form.legalnit"
                            :label="$t('vwinvoices_clegalnit')"
                            @keyup="getNITMask"
                            :rules="required"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            outlined
                            dense
                            v-model="form.legalnit"
                            :label="$t('vwinvoices_clegalnit')"
                            @keyup="getNITMask"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field
                            v-if="form.invoicetype == 'CCF'"
                            outlined
                            dense
                            v-model="form.legalnrc"
                            :label="$t('vwinvoices_clegalnrc')"
                            @input="getNRCMask"
                            :rules="required"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            outlined
                            dense
                            v-model="form.legalnrc"
                            :label="$t('vwinvoices_clegalnrc')"
                            @input="getNRCMask"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            v-if="form.invoicetype == 'CCF'"
                            outlined
                            dense
                            label="Actividad economica:*"
                            :items="activities"
                            @change="changueValueactivity"
                            attach
                            v-model="form.codactividad"
                            item-text="Valores"
                            item-value="Codigo"
                            :rules="required"
                          ></v-autocomplete>
                          <v-autocomplete
                            v-else
                            outlined
                            dense
                            label="Actividad economica:*"
                            :items="activities"
                            @change="changueValueactivity"
                            attach
                            v-model="form.codactividad"
                            item-text="Valores"
                            item-value="Codigo"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-autocomplete
                            outlined
                            dense
                            v-model="form.legalentity"
                            attach
                            :items="businessEntity"
                            :label="$t('vwinvoices_clegalentity')"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-select
                            v-if="form.invoicetype == 'CCF'"
                            outlined
                            dense
                            :items="taxpayersize"
                            item-value="key"
                            attach
                            item-text="value"
                            v-model="form.legaltaxpayersize"
                            :label="$t('vwinvoices_clegaltaxpayersize')"
                            :rules="required"
                          >
                          </v-select>
                          <v-select
                            v-else
                            outlined
                            dense
                            :items="taxpayersize"
                            item-value="key"
                            attach
                            item-text="value"
                            v-model="form.legaltaxpayersize"
                            :label="$t('vwinvoices_clegaltaxpayersize')"
                          >
                          </v-select>
                        </v-col>
                        <!--  <v-col cols="12" md="5">
                          <v-text-field outlined dense 
                            v-model="form.legalsector"
                            :label="$t('vwinvoices_clegalsector')"
                          ></v-text-field>
                        </v-col> -->
                        <v-col style="display: none" cols="12" md="4">
                          <v-text-field
                            outlined
                            dense
                            v-model="form.information"
                            :label="$t('vwinvoices_clegalinformation')"
                          ></v-text-field>
                        </v-col>

                        <v-col class="align-self-center" cols="12" md="3">
                          <v-checkbox
                            :value="form.legalisnontaxable"
                            v-model="form.legalisnontaxable"
                            :label="$t('vwinvoices_cclientisnontaxable')"
                          ></v-checkbox>
                        </v-col>
                        <v-col class="align-self-center" cols="12" md="3">
                          <v-checkbox
                            :value="form.legalisexempt"
                            v-model="form.legalisexempt"
                            label="Cliente exento"
                          ></v-checkbox>
                        </v-col>
                        <!--<v-col cols="12" lg="4">
                                            <v-select outlined dense  :items="['Foo', 'Bar', 'Fizz', 'Buzz']" label="Payment Type" attach> 
                                                <template v-slot:item="{ item, attrs, on }">
                                                    <v-list-item v-bind="attrs" v-on="on">
                                                        <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"
                                                        ></v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </v-select>
                                        </v-col>-->
                        <v-col cols="12"></v-col>
                        <br />
                        <v-col cols="12" md="10"></v-col>
                        <v-col cols="12" sm="2" class="align-self-end"
                          ><v-btn
                            class="btndisable align-self-end"
                            @click="
                              newCostumer ? addNewCustomer() : '';
                              showCustomer = !showCustomer;
                            "
                            >Continuar</v-btn
                          ></v-col
                        >
                        <br /><br />
                        <!--v-col cols="12" lg="4" class="align-self-center mb-4" v-if="!isCustomerSelected">
                                            <v-btn color="primary" class="text-uppercase" @click.prevent="addNewClient">
                                                {{$t('vwinvoices_csaveasnewclient')}}
                                            </v-btn>
                                        </v-col-->
                      </v-row>
                    </form>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-col>
        <!--<v-col cols="12">
          <div class="white pa-4 border-5 box">
            <div style="width: 100%; margin: 0;" class="tabs" @click="showProductsAndServices = !showProductsAndServices">
                  <v-icon v-if="showProductsAndServices" color="white" left>
                    mdi-minus
                  </v-icon>
                  <v-icon v-else color="white" left> mdi-plus </v-icon>
                  Ver productos y servicios disponibles
            </div-->

        <div>
          <v-dialog
            v-model="showProductsAndServices"
            scrollable
            max-width="1300"
            @keydown.enter="handleEnterKey"
            @keydown.down="handleKeyDown"
            tabindex="0"
          >
            <v-card>
              <v-card-title class="text-h5">
                Productos y servicios disponibles
              </v-card-title>
              <v-card-text>
                <div v-if="showProductsAndServices">
                  <br />
                  <div style="display: flex; margin-left: 5px">
                    <input
                      type="radio"
                      id="InvoiceProductBtn"
                      value="products"
                      v-model="displayInvoice"
                    />
                    <h5 style="margin: 5px" for="InvoiceProductBtn">
                      Productos
                    </h5>
                    <input
                      style="margin-top: 1px; margin-left: 10px"
                      type="radio"
                      id="InvoiceServiceBtn"
                      value="services"
                      v-model="displayInvoice"
                    />
                    <h5 style="margin: 5px" for="InvoiceServiceBtn">
                      Servicios
                    </h5>
                  </div>
                  <template v-if="displayInvoice === 'products'">
                    <div>
                      <v-btn
                        class="btnclean"
                        style="margin-bottom: 10px; display: none"
                        dark
                        @click.prevent="showFilter = true"
                        >{{ $t("vwinvoices_cfilter") }}</v-btn
                      >
                      <v-bottom-sheet v-model="showFilter" inset>
                        <v-sheet class="text-center">
                          <v-btn
                            class="mt-6"
                            text
                            color="error"
                            @click="showFilter = !showFilter"
                          >
                            close
                          </v-btn>
                          <div class="my-3 pa-4">
                            <v-select
                              outlined
                              dense
                              hide-details
                              :items="priceList"
                              attach
                              class="mb-2"
                              v-model="filterProducts.pricelist"
                              item-text="id"
                              item-value="id"
                              :label="$t('vwinvoices_cpricelist')"
                            ></v-select>
                            <v-select
                              outlined
                              dense
                              hide-details
                              attach
                              :items="types"
                              class="mb-2"
                              v-model="filterProducts.type"
                              :label="$t('vwinvoices_citemstype')"
                            >
                            </v-select>
                            <v-select
                              outlined
                              dense
                              hide-details
                              attach
                              :items="warehouse"
                              item-text="Warehouse"
                              item-value="ID_Warehouse"
                              class="mb-2"
                              v-model="filterProducts.warehouse"
                              :label="$t('vwinvoices_cwarehouse')"
                            >
                            </v-select>
                            <v-select
                              outlined
                              dense
                              hide-details
                              attach
                              :items="provider"
                              item-text="ProviderName"
                              item-value="ID_Provider"
                              class="mb-2"
                              v-model="filterProducts.provider"
                              label="Proveedor"
                            >
                            </v-select>
                          </div>
                        </v-sheet>
                      </v-bottom-sheet>
                    </div>
                    <GeneralFilter
                      ref="generalFilter"
                      :filterEndpoint="
                        $API.products.getProductsToBillForInvoiceFiltered
                      "
                      :search="filterProducts"
                      @emptyFilter="getProductsOnFilter"
                      @filtered="productsFilterHandler"
                    >
                      <DxDataGrid
                        ref="dxDataGridInstance"
                        style="max-height: 250px"
                        class="isScrolledByGeneralFilter"
                        :data-source="productsToBill"
                        key-expr="ID_Item"
                        :keyboard-navigation="{
                          enabled: true,
                          enterKeyAction: 'moveFocus',
                          enterKeyDirection: 'column',
                        }"
                        :allow-column-resizing="true"
                        column-resizing-mode="widget"
                        :column-auto-width="true"
                        :paging="{ enabled: false }"
                      >
                        <DxScrolling row-rendering-mode="virtual" />
                        <!--DxPaging :page-size="5" /-->
                        <DxColumn
                          :width="45"
                          cell-template="show-template"
                          caption=""
                        ></DxColumn>
                        <DxColumn
                          :width="150"
                          data-field="ID_Item"
                          caption="ID"
                        ></DxColumn>
                        <DxColumn
                          :width="150"
                          data-field="BarCode"
                          caption="Código alterno"
                        ></DxColumn>
                        <DxColumn
                          data-field="ItemName"
                          caption="Producto"
                        ></DxColumn>
                        <DxColumn
                          data-field="ItemComercialName"
                          caption="Nombre comercial"
                        ></DxColumn>

                        <DxColumn
                          :width="130"
                          data-field="PriceListName"
                          caption="PriceListName"
                        ></DxColumn>
                        <!-- <DxColumn :width="120" data-field="ItemNewPrice" caption="Precio"></DxColumn> -->
                        <DxColumn
                          :width="120"
                          cell-template="itemPriceFormatted"
                          caption="Precio"
                        ></DxColumn>
                        <DxColumn
                          :width="60"
                          data-field="ItemCurrentQuantity"
                          caption="Existencias"
                        ></DxColumn>
                        <DxColumn
                          :width="60"
                          data-field="ID_ItemBatch"
                          caption="Lote"
                        ></DxColumn>
                        <template #show-template="{ data }">
                          <a
                            ref="plusIcon"
                            href="#"
                            class="mx-1"
                            tabindex="0"
                            @click.prevent.stop="
                              triggerAddItemEvent(data.data, 1)
                            "
                            @keyup.space.prevent="handleSpace(data.data)"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </a>
                        </template>
                        <template #itemPriceFormatted="{ data }">
                          {{ Currency
                          }}{{ numberWithCommas(data.data.ItemNewPrice) }}
                        </template>
                      </DxDataGrid>
                    </GeneralFilter>
                  </template>
                  <template v-else-if="displayInvoice === 'services'">
                    <GeneralFilter
                      ref="dxDataGridInstance"
                      :filterEndpoint="
                        $API.services.getAllServicesWithPriceListFiltered
                      "
                      :search="filterServices"
                      @emptyFilter="getServicesOnFilter"
                      @filtered="servicesFilterHandler"
                    >
                      <DxDataGrid
                        class="isScrolledByGeneralFilter"
                        style="max-height: 250px"
                        :data-source="servicesToBill"
                        key-expr="CodeService"
                        :allow-column-resizing="true"
                        column-resizing-mode="widget"
                        :column-auto-width="true"
                        :paging="{ enabled: false }"
                      >
                        <DxScrolling row-rendering-mode="virtual" />
                        <!--DxPaging :page-size="5" /-->
                        <DxColumn
                          :width="45"
                          cell-template="show-template"
                          caption=""
                        ></DxColumn>
                        <template #show-template="{ data }">
                          <a
                            ref="plusIconService"
                            href="#"
                            class="mx-1"
                            @click.prevent.stop="
                              triggerAddItemEvent(data.data, 2)
                            "
                            @keyup.space.prevent="handleSpaceService(data.data)"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </a>
                        </template>
                        <DxColumn
                          :width="150"
                          data-field="CodeService"
                          caption="ID"
                        ></DxColumn>
                        <DxColumn
                          data-field="NameService"
                          header-cell-template="headerNameService"
                        ></DxColumn>
                        <template #headerNameService="{}">
                          <div>Nombre de<br />servicio</div>
                        </template>
                        <DxColumn
                          data-field="Name"
                          header-cell-template="headerNameLab"
                        ></DxColumn>
                        <template #headerNameLab="{}">
                          <div>Nombre a<br />facturar</div>
                        </template>
                        <DxColumn
                          :width="130"
                          data-field="TypeService"
                          header-cell-template="headerTypeservice"
                        >
                          <DxLookup
                            :data-source="typeService"
                            display-expr="Name"
                            value-expr="ID"
                          />
                        </DxColumn>
                        <template #headerTypeservice="{}">
                          <div>Tipo de<br />servicio</div>
                        </template>
                        <DxColumn
                          :width="130"
                          data-field="PriceListName"
                          caption="PriceListName"
                        ></DxColumn>
                        <!-- <DxColumn :width="120" data-field="ItemNewPrice" caption="Precio"></DxColumn> -->
                        <DxColumn
                          :width="120"
                          cell-template="itemPriceFormatted"
                          caption="Precio"
                        ></DxColumn>
                        <template #itemPriceFormatted="{ data }">
                          {{ Currency
                          }}{{ numberWithCommas(data.data.ItemNewPrice) }}
                        </template>
                      </DxDataGrid>
                    </GeneralFilter>
                  </template>
                </div>
              </v-card-text>
              <v-col class="text-right">
                <v-btn
                  @click="showProductsAndServices = !showProductsAndServices"
                  class="btndisable"
                >
                  Continuar
                </v-btn>
              </v-col>
            </v-card>
          </v-dialog>
        </div>

        <QuantityDialog
          v-if="currentItemToAdd"
          :show="showQuantityDialog"
          :itemToReturn="currentItemToAdd"
          @cancel="clearQuantityInputValues"
          @submit="submitQuantityInput"
        />
        <!--</div>
        </v-col-->
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="white pa-4 border-5 box">
            <v-row class="d-flex justify-center">
              <p>
                <b>{{ $t("vwinvoices_ccurrentinvoiceitems") }}</b>
              </p>
            </v-row>
            <v-row class="mt-2">
              <v-col
                cols="12"
                :md="posrestaurant ? 1 : 2"
                class="d-flex justify-center"
                style="padding-bottom: 13px !important"
              >
                <v-btn class="btnsave ml-3" @click="loadpriceProducts()"
                  ><v-icon>mdi-magnify</v-icon></v-btn
                >
              </v-col>
              <v-col
                v-if="posrestaurant"
                cols="12"
                md="1"
                class="d-flex justify-center"
              >
                <!-- <v-btn class="btnsave ml-3" @click="loadpriceProducts()"><v-icon>mdi-notebook</v-icon></v-btn> -->
                <!-- <v-btn class="btnsave ml-3" @click="loadpriceProducts()"><v-icon>mdi-table-chair</v-icon></v-btn> -->
                <v-btn class="btnsave ml-3" @click="openClasificationSelector"
                  ><v-icon>mdi-food</v-icon></v-btn
                >
                <!-- <v-btn class="btnsave ml-3" @click="loadpriceProducts()"><v-icon>mdi-notebook-check-outline</v-icon></v-btn> -->
                <!-- <v-btn class="btnsave ml-3" @click="loadpriceProducts()"><v-icon>mdi-coffee-to-go</v-icon></v-btn> -->
              </v-col>
              <v-col cols="12" md="3">
                <!-- <v-text-field outlined dense v-model="selectedBarCode" item-text="BarCode"
                  @keyup.enter="addBarCode()" item-value="ID_Item" label="Ingrese código de barras / alternos"
                  :readonly="checkWarehandPl" attach></v-text-field> -->
                <v-text-field
                  outlined
                  dense
                  v-model="selectedBarCode"
                  item-text="BarCode"
                  @keyup.enter="addBarCode()"
                  item-value="ID_Item"
                  label="Ingrese código de barras / alternos"
                  attach
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <!--   <v-text-field outlined dense 
                  id="input_productcode"
                  v-model="productcode"
                  @keyup.enter="addProductAndService()"
                  label="Ingrese código de producto"
                  dense
                ></v-text-field> -->
                <!-- <v-autocomplete outlined dense v-model="productcode" :items="allproducts" item-text="ID"
                  @change="addselector()" item-value="prod" label="Código de producto o servicio"
                  :readonly="checkWarehandPl" attach></v-autocomplete> -->
                <CustomAutoComplete
                  :filter-endpoint="$API.products.getAllWithServicesFiltered"
                  v-if="assignedList.prod || assignedList.serv"
                  v-model="productcode"
                  :search="{ ...search }"
                  :items="ProductServList ? ProductServList : allproductbyName"
                  :item-text="'val'"
                  :isProd="true"
                  @select="
                    (e) => {
                      productcode = e;
                      addselector(e);
                    }
                  "
                  :item-value="'val'"
                  label="Código de producto o servicio"
                  :emitComplete="true"
                  @updateItems="updateServProList"
                >
                </CustomAutoComplete>
              </v-col>

              <v-col cols="12" md="4">
                <!--   <v-text-field outlined dense 
                  id="input_productcode"
                  v-model="productcode"
                  @keyup.enter="addProductAndService()"
                  label="Ingrese código de producto"
                  dense
                ></v-text-field> -->
                <!-- <v-autocomplete outlined dense v-model="productcode" :items="allproductbyName" item-text="ID"
                  @change="addselector()" item-value="prod" label="Nombre de producto o servicio" attach
                  :readonly="checkWarehandPl"></v-autocomplete> -->
                <CustomAutoComplete
                  :filter-endpoint="$API.products.getAllWithServicesFiltered"
                  v-if="assignedList.prod || assignedList.serv"
                  v-model="productcode"
                  :search="{ ...search }"
                  :items="[
                    ...(ProductServList ? ProductServList : allproductbyName),
                  ]"
                  :item-text="'ID'"
                  :isProd="true"
                  @select="
                    (e) => {
                      productcode = e;
                      addselector(e);
                    }
                  "
                  :item-value="'val'"
                  label="Nombre de producto o servicio"
                  :emitComplete="true"
                  @updateItems="updateServProList"
                >
                </CustomAutoComplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  outlined
                  dense
                  attach
                  v-model="warehouseSelected"
                  :items="warehouses"
                  item-text="Warehouse"
                  item-value="ID_Warehouse"
                  label="Almacen"
                  @change="reactiveMainProductsFilter"
                  :readonly="!$store.getters['rolesUser/getCustomRole'](240)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  outlined
                  dense
                  attach
                  v-model="PriceListSelected"
                  @change="getPriceListSelected()"
                  :items="priceListData"
                  item-text="id"
                  item-value="id"
                  label="Lista de precios"
                  :readonly="!$store.getters['rolesUser/getCustomRole'](239)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-checkbox
                  outlined
                  dense
                  v-model="allowRepetitionOfArticles"
                  label="Permitir repitición de articulos"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="d-flex justify-center"
                v-if="isCustomerSelected"
              >
                <v-btn
                  color="btnsave"
                  class="mb-2"
                  @click="showDiscountGlobal = true"
                >
                  Aplicar descuento a factura
                </v-btn>
              </v-col>
            </v-row>
            <br />
            <v-row style="overflow-x: scroll" class="mb-3">
              <v-col cols="12">
                <v-btn 
                  class="primary mb-3"
                  @click="extendedViewTable = !extendedViewTable"
                >
                  Vista {{ !extendedViewTable ? 'extendida' : 'mínima' }}
                </v-btn>
                <DxDataGrid
                  class="isScrolledByGeneralFilter"
                  :data-source="
                    productsFiltered === false
                      ? productsToShow
                      : productsToShowFiltered
                  "
                  :allow-column-resizing="false"
                  column-resizing-mode="widget"
                  :column-auto-width="true"
                  :word-wrap-enabled="true"
                >
                  <DxEditing
                    :allow-updating="true"
                    :select-text-on-edit-start="true"
                    start-edit-action="click"
                    mode="cell"
                  />
                  <DxColumn
                    data-field="ID_Item"
                    :allow-editing="false"
                    caption="ID"
                  ></DxColumn>
                  <DxColumn
                    v-if="posshowprincipalbarcode == 1"
                    data-field="BarCode"
                    :allow-editing="false"
                    caption="Código alterno principal"
                  ></DxColumn>
                  <DxColumn
                    data-field="ItemName"
                    :allow-editing="allowEditName"
                    :allow-updating="allowEditName"
                    data-type="text"
                    caption="Nombre de Producto/Servicio"
                    :width="200"
                  >
                  </DxColumn>
                  <DxColumn
                    data-field="qty"
                    caption="Cantidad"
                    data-type="number"
                  >
                    <DxFormat typed="fixedPoint" :precision="0" />
                    <DxRequiredRule
                      message="La cantidad del producto a facturar es requerida"
                    />
                    <DxPatternRule
                      :pattern="itemQtyPattern"
                      message="La cantidad del producto solo puede contener numeros enteros mayores a 0"
                    />
                    <!-- <DxAsyncRule 
                      :validation-callback="itemsQtyOnlyDigits"
                      message="La cantidad del producto solo puede contener numeros enteros mayores a 0"
                    /> -->
                    <DxAsyncRule
                      :validation-callback="handleStockAndQty"
                      message="La cantidad del producto no puede exceder el stock actual"
                    />
                  </DxColumn>
                  <DxColumn cell-template="itemPrice" caption="Pre./U" :width="100"></DxColumn>
                  <!--DxColumn data-field="ItemNewPrice" caption="Pre./U" width="10%" :allow-editing="false"></DxColumn-->
                  <DxColumn cell-template="Discount" caption="Descuento">
                  </DxColumn>
                  <DxColumn v-if="extendedViewTable" cell-template="NonTaxable" caption="Exento">
                  </DxColumn>
                  <DxColumn v-if="extendedViewTable" cell-template="NonSuj" caption="No Sujeto">
                  </DxColumn>
                  <DxColumn
                    v-if="extendedViewTable"
                    cell-template="IsHidrocarburo"
                    caption="Hidrocarburo"
                  ></DxColumn>
                  <DxColumn
                    cell-template="totalItemPrice"
                    caption="Total"
                  ></DxColumn>
                  <!--DxColumn :calculate-cell-value="calculateCellValue" caption="Total" width="10%"></DxColumn-->
                  <DxColumn cell-template="show-template" caption=""></DxColumn>

                  <template #itemPrice="{ data }">
                    <div v-if="$store.getters['rolesUser/getCustomRole'](8)">
                      <div v-if="data.data.nontaxable || data.data.nonsuj">
                        <input
                          :readonly="
                            !$store.getters['rolesUser/getCustomRole'](8)
                          "
                          type="number"
                          style="width: 100% !important; text-align: center"
                          v-model="data.data.ItemNewPrice"
                          @input="calculateItemNewPrice(data.data)"
                        />
                        <!-- {{ (data.data.ItemNewPrice / 1.13).toFixed(8) }} -->
                      </div>
                      <div v-else>
                        <input
                          :readonly="
                            !$store.getters['rolesUser/getCustomRole'](8)
                          "
                          type="number"
                          style="width: 100% !important; text-align: center"
                          v-if="
                            form.invoicetype == 'CCF' ||
                            form.invoicetype == 'DNC' ||
                            form.invoicetype == 'DND'
                          "
                          v-model="data.data.TmpItemNewPrice"
                          @input="calculateItemNewPrice2(data.data)"
                          @blur="calculateItemNewPrice3(data.data)"
                          @keyup.enter="calculateItemNewPrice3(data.data)"
                        />
                        <input
                          :readonly="
                            !$store.getters['rolesUser/getCustomRole'](8)
                          "
                          type="number"
                          style="width: 100% !important; text-align: center"
                          v-if="form.invoicetype != 'CCF' && form.invoicetype != 'DNC' && form.invoicetype != 'DND'"
                          v-model="data.data.ItemNewPrice"
                          @input="calculateItemNewPrice(data.data)"
                        />
                        <!--{{ form.invoicetype == 'CCF' ? (data.data.ItemNewPrice / 1.13).toFixed(8) : "" }}
                        {{ form.invoicetype != 'CCF' ? data.data.ItemNewPrice.toFixed(8) : "" }} -->
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="data.data.nontaxable || data.data.nonsuj">
                        {{ (data.data.ItemNewPrice).toFixed(8) }}
                      </div>
                      <div v-else>
                        {{
                          form.invoicetype == "CCF" ||
                          form.invoicetype == "DNC" ||
                          form.invoicetype == "DND"
                            ? (data.data.ItemNewPrice / 1.13).toFixed(8)
                            : ""
                        }}
                        {{
                          form.invoicetype != "CCF" &&
                          form.invoicetype != "DNC" &&
                          form.invoicetype != "DND"
                            ? data.data.ItemNewPrice.toFixed(8)
                            : ""
                        }}
                      </div>
                    </div>
                  </template>

                  <template #totalItemPrice="{ data }">
                    <div v-if="data.data.nontaxable || data.data.nonsuj">
                      {{
                        data.data.TmpCounter > 0
                          ? calculateCellValue(data.data)
                          : calculateCellValue(data.data)
                      }}
                    </div>
                    <div v-else>
                      {{
                        (form.invoicetype == "CCF" ||
                          form.invoicetype == "DNC" ||
                          form.invoicetype == "DND") &&
                        data.data.TmpCounter == 0
                          ? (calculateCellValue(data.data) / 1.13).toFixed(8)
                          : ""
                      }}
                      {{
                        (form.invoicetype == "CCF" ||
                          form.invoicetype == "DNC" ||
                          form.invoicetype == "DND") &&
                        data.data.TmpCounter > 0
                          ? (calculateCellValue(data.data) / 1.13).toFixed(8)
                          : ""
                      }}
                      {{
                        form.invoicetype != "CCF" &&
                        form.invoicetype != "DNC" &&
                        form.invoicetype != "DND"
                          ? calculateCellValue(data.data)
                          : ""
                      }}
                    </div>
                  </template>

                  <template #show-template="{ data }">
                    <div>
                      <a
                        href="#"
                        class="mx-1"
                        @click.prevent.stop="
                          confirmDelete = data;
                          showAlert(
                            'question',
                            'Eliminar',
                            '¿Está seguro que desea eliminar este producto?',
                            'deleteoneproduct'
                          );
                        "
                      >
                        <v-icon color="error">mdi-delete</v-icon>
                      </a>
                      <a v-if="data.data?.ErrorRow == true"
                        href="#"
                        class="mx-1"
                        title="La cantidad del producto contiene un valor negativo o excede al stock actual"
                        >
                        <v-icon color="error">mdi-alert-circle</v-icon>
                      </a>
                    </div>
                  </template>
                  <template #NonTaxable="{ data }">
                    <div>
                      <input
                        v-if="
                          (data.data.IsExempt == 1 ||
                            form.legalisexempt ||
                            data.data.nontaxable2) &&
                          form.invoicetype != 'FEX'
                        "
                        type="checkbox"
                        v-model="data.data.nontaxable"
                        checked
                        @change="
                          data.data.nontaxable2 = false;
                          data.data.nonsuj = false;
                        "
                        :disabled="form.invoicetype == 'FEX'"
                      />

                      <input
                        v-else
                        type="checkbox"
                        v-model="data.data.nontaxable"
                        @click="data.data.nonsuj = false"
                        :disabled="form.invoicetype == 'FEX'"
                      />
                    </div>
                  </template>
                  <template #NonSuj="{ data }">
                    <div>
                      <input
                        v-if="
                          (form.legalisnontaxable == 1 || data.data.nonsuj2) &&
                          form.invoicetype != 'FEX'
                        "
                        type="checkbox"
                        v-model="data.data.nonsuj"
                        checked
                        @change="
                          data.data.nonsuj2 = false;
                          data.data.nontaxable = false;
                        "
                        :disabled="form.invoicetype == 'FEX'"
                      />
                      <input
                        v-else
                        type="checkbox"
                        v-model="data.data.nonsuj"
                        @click="data.data.nontaxable = false"
                        :disabled="form.invoicetype == 'FEX'"
                      />
                    </div>
                  </template>
                  <template #IsHidrocarburo="{ data }">
                    <div>
                      <input
                        type="checkbox"
                        v-model="data.data.IsHidrocarburo"
                        disabled
                      />
                    </div>
                  </template>
                  <template #Discount="{ data }">
                    <button
                      type="checkbox"
                      @click="
                        showDiscount = true;
                        discountObject = data.data;
                        objectToApplyDiscount = { ...data.data };
                        loadPriceListData(data.data.ID_Item);
                        showPrices = false;
                      "
                    >
                      <v-icon
                        :color="
                          data.data.ItemPriceDiscountAmount > 0
                            ? 'warning'
                            : 'primary'
                        "
                        >mdi-eye</v-icon
                      >
                    </button>
                  </template>
                </DxDataGrid>
              </v-col>
              <!-- <GeneralFilter :search="{}" :filterEndpoint="filterPromise" @emptyFilter="clearFilter" @filtered="filterHandler"> -->
              <!-- </GeneralFilter> -->
            </v-row>
            <!-- <v-divider></v-divider> -->
            <v-row class="mt-5 box invoiceResponsiveSummary">
              <v-row class="mt-3 mb-1 d-flex justify-center">
                <span style="display: flex; justify-content: space-between"
                  ><v-col
                    :class="
                      form.invoicetype != 'TCKS'
                        ? `responsiveSmallColFCF`
                        : `responsiveSmallCol`
                    "
                  >
                    <v-row class="d-flex justify-center">
                      <span v-if="form.invoicetype != 'FEX'" id="subtotal"
                        >Gravadas</span
                      >
                      <span v-else id="subtotal">Afectas</span>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <input
                        type="text"
                        class="form-control"
                        :value="sumObject.subTotal"
                        disabled
                        aria-describedby="subtotal"
                      />
                    </v-row>
                  </v-col>
                  <v-col
                    :class="
                      form.invoicetype != 'TCKS'
                        ? `responsiveSmallColFCF`
                        : `responsiveSmallCol`
                    "
                    v-if="
                      form.invoicetype != 'FCF' &&
                      form.invoicetype != 'TCKS' &&
                      form.invoicetype != 'DNR'
                    "
                  >
                    <v-row class="d-flex justify-center mx-2">
                      <span style="white-space: nowrap" id="IVA">IVA 13%</span>
                    </v-row>
                    <v-row class="d-flex justify-center mx-2">
                      <input
                        type="text"
                        class="form-control"
                        :value="sumObject.iva"
                        disabled
                        aria-describedby="IVA"
                      />
                    </v-row>
                  </v-col>
                  <!-- <v-col cols="1" class="mx-2 "> -->

                  <v-col
                    v-if="form.invoicetype != 'FEX'"
                    :class="
                      form.invoicetype != 'TCKS'
                        ? `responsiveSmallColFCF`
                        : `responsiveSmallCol`
                    "
                  >
                    <v-row class="d-flex justify-center">
                      <span id="NON-TAXABLE">Exentas</span>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <input
                        type="text"
                        class="form-control"
                        :value="sumObject.nontaxable"
                        disabled
                        aria-describedby="NON-TAXABLE"
                      />
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="form.invoicetype != 'FEX'"
                    :class="
                      form.invoicetype != 'TCKS'
                        ? `responsiveSmallColFCF`
                        : `responsiveSmallCol`
                    "
                  >
                    <v-row class="d-flex justify-center">
                      <span id="NON-TAXABLE" class="no-wrap">No Sujetas</span>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <input
                        type="text"
                        class="form-control"
                        :value="sumObject.nonsuj"
                        disabled
                        aria-describedby="NON-TAXABLE"
                      />
                    </v-row>
                  </v-col>
                  <v-col
                    class="responsiveSmallColFCF"
                    v-if="
                      (form.invoicetype == 'CCF' ||
                        form.invoicetype == 'FCF' ||
                        form.invoicetype == 'DNR' ||
                        form.invoicetype == 'DNC' ||
                        form.invoicetype == 'DND') &&
                      (sumObject.ivaperce > 0 || sumObject.ivaret > 0)
                    "
                  >
                    <v-row class="d-flex justify-center">
                      <span id="NON-TAXABLE">
                        {{ sumObject.ivaperce > 0 ? "IVA Perce" : "" }}
                        {{ sumObject.ivaret > 0 ? "IVA Rete" : "" }}
                      </span>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <input
                        v-if="sumObject.ivaret > 0"
                        type="text"
                        class="form-control"
                        :value="sumObject.ivaret"
                        disabled
                      />
                      <input
                        v-if="sumObject.ivaperce > 0"
                        type="text"
                        class="form-control"
                        :value="sumObject.ivaperce"
                        disabled
                      />
                    </v-row>
                  </v-col>
                  <v-col
                    class="responsiveSmallCol"
                    v-if="sumObject.reterenta > 0"
                  >
                    <v-row class="d-flex justify-center">
                      <span id="NON-TAXABLE"> Ret Renta </span>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <input
                        type="text"
                        class="form-control"
                        :value="sumObject.reterenta"
                        disabled
                      />
                    </v-row>
                  </v-col>
                  <v-col
                    class="responsiveSmallColFCF"
                    v-if="
                      sumObject.fovial > 0 &&
                      (form.invoicetype == 'FCF' ||
                        form.invoicetype == 'CCF' ||
                        form.invoicetype == 'DNR')
                    "
                  >
                    <v-row class="d-flex justify-center">
                      <span id="NON-TAXABLE"> Fovial </span>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <input
                        type="text"
                        class="form-control"
                        :value="sumObject.fovial"
                        disabled
                      />
                    </v-row>
                  </v-col>
                  <v-col
                    class="responsiveSmallColFCF"
                    v-if="
                      sumObject.cotrans > 0 &&
                      (form.invoicetype == 'FCF' ||
                        form.invoicetype == 'CCF' ||
                        form.invoicetype == 'DNR')
                    "
                  >
                    <v-row class="d-flex justify-center">
                      <span id="NON-TAXABLE"> Cotrans </span>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <input
                        type="text"
                        class="form-control"
                        :value="sumObject.cotrans"
                        disabled
                      />
                    </v-row>
                  </v-col>
                </span>
                <span :style="colGroupStyle">
                  <v-col
                    :class="colClasses"
                    :style="!isMobile ? 'margin-top: 10px' : 'margin:0px 15px'"
                  >
                    <v-btn
                      class="text-uppercase btnclean"
                      @click.prevent="clearForm"
                    >
                      <v-icon>mdi-broom</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    :class="{
                      'd-flex': true,
                      'justify-center': true,
                      responsiveTinyCol: true,
                      'mt-8': !isMobile,
                    }"
                    :style="!isMobile ? 'margin-top: 10px' : 'margin:0px 15px'"
                    v-if="
                      invalid == null && ndornc == null && form.isquote == 0
                    "
                  >
                    <v-btn
                      title="Guardar plantilla"
                      class="text-uppercase btnsave"
                      @click.prevent="
                        pospayments
                          ? ((showReturnedAndPaymentModal = true),
                            (form.istemplate = 1),
                            (isReadyToBill = false))
                          : (showDialogTemplate = true)
                      "
                    >
                      <v-icon>mdi-book-plus-multiple</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    :class="{
                      'd-flex': true,
                      'justify-center': true,
                      responsiveTinyCol: true,
                      'mt-8': !isMobile,
                    }"
                    :style="!isMobile ? 'margin-top: 10px' : 'margin:0px 15px'"
                    v-if="
                      invalid == null && ndornc == null && form.isquote == 0
                    "
                  >
                    <v-btn
                      title="Guardar factura"
                      class="text-uppercase btnsave"
                      @click.prevent="
                        pospayments
                          ? ((showReturnedAndPaymentModal = true),
                            (isReadyToBill = true),
                            (form.istemplate = 0))
                          : sendBill(0)
                      "
                    >
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </v-col></span
                >
                <span
                  :style="
                    isMobile
                      ? 'width:100%; display:flex; justify-content:center;'
                      : ''
                  "
                >
                  <v-col
                    v-if="form.isquote == 0"
                    class="d-flex justify-center responsiveAverageCol flex-sx-12"
                  >
                    <v-btn
                      :disabled="creditBillingBlock"
                      class="text-uppercase btn-add"
                      @click.prevent="
                        pospayments &&
                        form.invoicetype != 'DND' &&
                        form.invoicetype != 'DNC'
                          ? ((showReturnedAndPaymentModal = true),
                            (isReadyToBill = false),
                            (form.istemplate = 0))
                          : sendBill(1)
                      "
                      v-if="$store.getters['rolesUser/getCustomRole'](5)"
                    >
                      <v-icon>mdi-printer-pos</v-icon>
                      Facturar
                    </v-btn>
                  </v-col>
                </span>
                <v-col
                  :cols="$vuetify.breakpoint.width < 600 ? '12' : ''"
                  v-if="form.isquote == 1"
                  :class="`d-flex justify-center  ${
                    form.isquote == 1
                      ? 'mr-3 responsiveAverageColQuote'
                      : 'responsiveAverageCol '
                  }`"
                  :style="`margin-top: 10px;${
                    $vuetify.breakpoint.width < 600
                      ? 'width:100% !important;'
                      : ''
                  }`"
                >
                  <v-btn
                    class="text-uppercase btn-add alig"
                    @click.prevent="sendBill(0)"
                  >
                    <v-icon>mdi-printer-pos</v-icon>
                    &nbsp;CREAR COTIZACIÓN
                  </v-btn>
                </v-col>
                <v-col
                  :cols="$vuetify.breakpoint.width < 600 ? '12' : ''"
                  :class="'responsiveAverageCol mt-8'"
                  :style="isMobile ? 'margin-bottom:15px' : ''"
                >
                  <v-row class="d-flex justify-center">
                    <span id="VENTAS-TOTAL">Total</span>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <input
                      type="text"
                      class="totalDesign form-control"
                      :value="sumObject.total"
                      disabled
                      aria-describedby="VENTAS-TOTAL"
                    />
                  </v-row>
                </v-col>
                <v-col v-if="showTimesExecute == true">
                  <v-row class="d-flex justify-left">
                    <span>Cantidad a procesar</span>
                  </v-row>
                  <v-row class="d-flex justify-left">
                    <input
                      type="number"
                      v-model="form.timesexecute"
                      class="form-control"
                    />
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <v-dialog v-model="searchCustomerDialog" scrollable max-width="1000">
        <search @customerSelected="customerSelected"></search>
      </v-dialog>
      <v-dialog v-model="searchSellerDialog" scrollable>
        <SearchDialog
          :entityLabel="'Vendedor'"
          :filterEndpoint="$API.sellers.getAllSellersFiltered"
          :defaultEndpoint="$API.sellers.getAllSellers"
          :gridKeyExp="'ID_Seller'"
          :recordsetBody="[
            {
              caption: 'ID',
              dataField: 'ID_Seller',
            },
            {
              caption: 'Nombre',
              dataField: 'FirstName',
            },
            {
              caption: 'Apellido',
              dataField: 'LastName',
            },
            {
              caption: 'Correo Electrónico',
              dataField: 'Email',
            },
            {
              caption: 'Teléfono Celular',
              dataField: 'Mobile',
            },
          ]"
          @select="sellerSelectedFromDialog"
          max-width="1000"
        />
      </v-dialog>
      <!-- Null Invoice -->
      <v-dialog v-model="nullInvoice" max-width="400">
        <null-invoice
          :invoiceId="createdInvoiceId"
          @hideNullInvoiceDialog="nullInvoice = false"
        ></null-invoice>
      </v-dialog>
      <!-- Discount global modal -->
      <v-dialog v-model="showDiscountGlobal" max-width="290">
        <v-card>
          <v-card-title class="text-h5"> Aplicar Descuento </v-card-title>
          <v-card-text>
            <form>
              <v-text-field
                outlined
                dense
                disabled
                v-model="sumObject.total"
                label="Precio total"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                v-model="globalDiscount.TotalPriceDiscountPercent"
                label="Porcentaje de descuento"
                :disabled="
                  !$store.getters['rolesUser/getArrayCustomRole']([253])
                "
              ></v-text-field>
              <v-text-field
                outlined
                dense
                v-model="globalDiscount.TotalNewPrice"
                label="Descuento a aplicar"
                :disabled="
                  !$store.getters['rolesUser/getArrayCustomRole']([253])
                "
              ></v-text-field>
              <v-autocomplete
                outlined
                dense
                attach
                v-model="PriceListSelected"
                @change="getPriceListSelected()"
                :items="priceListData"
                item-text="id"
                item-value="id"
                label="Lista de precios"
                :readonly="!$store.getters['rolesUser/getCustomRole'](239)"
              ></v-autocomplete>
            </form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="btnsave"
              text
              @click="applyGlobalDiscount()"
              :disabled="!$store.getters['rolesUser/getArrayCustomRole']([253])"
            >
              Aplicar
            </v-btn>
            <v-btn class="btndelete" text @click="showDiscountGlobal = false">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Discount Modal -->
      <v-dialog
        v-model="showDiscount"
        :max-width="showPrices ? 800 : (ferretlabenddata ? 800 : 300)"
        scrollable
      >
        <v-card>
          <v-card-title class="text-h5">
            {{ $t("vwinvoices_capplydiscount") }}
          </v-card-title>
          <v-card-text>
            <v-row class="mt-1">
              <v-col
                cols="12"
                :lg="showPrices ? 3 : (ferretlabenddata ? 3 : 12 )"
                class="mt-3 text-center"
              >
                <form v-if="discountObject">
                  <v-text-field
                    outlined
                    dense
                    disabled
                    v-model="discountObject.ItemOriginalPrice"
                    label="Precio original"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    disabled
                    v-model="discountObject.ItemNewPrice"
                    label="Precio con descuento"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="discountObject.ItemPriceDiscountPercent"
                    :label="$t('vwinvoices_cdiscountporcentage')"
                    @input="onInputDiscountObjs('ItemPriceDiscountPercent')"
                    @focus="onItemPriceDiscountPercentFocusBlur"
                    @blur="onItemPriceDiscountPercentFocusBlur"
                    :disabled="
                      !$store.getters['rolesUser/getArrayCustomRole']([253])
                    "
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="discountObject.ItemPriceDiscountAmount"
                    :label="$t('vwinvoices_cdiscountfixed')"
                    @input="onInputDiscountObjs('ItemPriceAmountPercent')"
                    @focus="onItemPriceDiscountAmountFocusBlur"
                    @blur="onItemPriceDiscountAmountFocusBlur"
                    :disabled="
                      !$store.getters['rolesUser/getArrayCustomRole']([253])
                    "
                  ></v-text-field>
                </form>
                <v-btn v-if="ferretlabenddata == 0" @click="showPrices = !showPrices" color="comment" dark>{{
                  showPrices ? "Ocultar precios" : "Mostrar precios"
                }}</v-btn>
              </v-col>
              <v-col cols="12" lg="9" v-if="showPrices || ferretlabenddata == 1">
                <div class="text-h6 mb-3 black--text">Precios</div>
                <v-data-table
                  :headers="headers"
                  :items="prices"
                  :height="200"
                  :items-per-page="-1"
                  @click:row="selectedPrice"
                  fixed-header
                  disable-pagination
                  hide-default-footer
                  dense
                >
                  <template #item.ItemPriceDiscountAmount="{ item }">
                    {{ item.ItemPriceDiscountAmount.toFixed(6) }}
                  </template>
                  <template #item.ItemNewPrice="{ item }">
                    {{ item.ItemNewPrice.toFixed(6) }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click.prevent="
                showDiscount = false;
                discountObject = null;
              "
              :disabled="!$store.getters['rolesUser/getArrayCustomRole']([253])"
            >
              Aplicar
            </v-btn>
            <v-btn color="error darken-1" text @click.prevent="cancelDiscount">
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- Datos de factura que se muestran con el menú minimizado -->
    <footer
      v-if="$store.getters.getDrawerMini == true"
      class="box smallScreenScrollYContainer"
      style="
        align-self: flex-end;
        display: flex;
        min-width: calc(100% - 100px);
        background: #41464c;
        color: white;
        bottom: 0;
        left: 75px;
        align-self: flex-start;
        z-index: 99999999;
        justify-content: right;
        position: fixed;
      "
    >
      <!-- <div style="overflow-x:scroll">
      <div style="min-width: 1000px;" class="d-flex"> -->
      <v-row class="mt-2 mb-1 ml-5 smallScreenScrollYContent justify-center">
        <v-col :class="'responsiveSmallCol'">
          <v-row class="d-flex justify-center">
            <span v-if="form.invoicetype != 'FEX'" id="subtotal">Gravadas</span>
            <span v-else id="subtotal">Afectas</span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="form-control"
              :value="sumObject.subTotal"
              disabled
              aria-describedby="subtotal"
            />
          </v-row>
        </v-col>
        <v-col
          class="responsiveSmallCol"
          v-if="
            form.invoicetype != 'FCF' &&
            form.invoicetype != 'TCKS' &&
            form.invoicetype != 'DNR'
          "
        >
          <v-row class="d-flex justify-center">
            <span id="IVA">IVA 13%</span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="form-control"
              :value="sumObject.iva"
              disabled
              aria-describedby="IVA"
            />
          </v-row>
        </v-col>
        <!-- <v-col cols="1" class="mx-2 "> -->

        <v-col v-if="form.invoicetype != 'FEX'" :class="'responsiveSmallCol'">
          <v-row class="d-flex justify-center">
            <span id="NON-TAXABLE">Exentas</span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="form-control"
              :value="sumObject.nontaxable"
              disabled
              aria-describedby="NON-TAXABLE"
            />
          </v-row>
        </v-col>

        <v-col v-if="form.invoicetype != 'FEX'" :class="'responsiveSmallCol'">
          <v-row class="d-flex justify-center">
            <span id="NON-TAXABLE">No Sujetas</span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="form-control"
              :value="sumObject.nonsuj"
              disabled
              aria-describedby="NON-TAXABLE"
            />
          </v-row>
        </v-col>
        <v-col
          class="responsiveSmallCol"
          v-if="
            (form.invoicetype == 'CCF' ||
              form.invoicetype == 'FCF' ||
              form.invoicetype == 'DNR' ||
              form.invoicetype == 'DNC' ||
              form.invoicetype == 'DND') &&
            (sumObject.ivaperce > 0 || sumObject.ivaret > 0)
          "
        >
          <v-row class="d-flex justify-center">
            <span id="NON-TAXABLE">
              {{ sumObject.ivaperce > 0 ? "IVA Perce" : "" }}
              {{ sumObject.ivaret > 0 ? "IVA Rete" : "" }}
            </span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              v-if="sumObject.ivaret > 0"
              type="text"
              class="form-control"
              :value="sumObject.ivaret"
              disabled
            />
            <input
              v-if="sumObject.ivaperce > 0"
              type="text"
              class="form-control"
              :value="sumObject.ivaperce"
              disabled
            />
          </v-row>
        </v-col>
        <v-col class="responsiveSmallCol" v-if="sumObject.reterenta > 0">
          <v-row class="d-flex justify-center">
            <span id="NON-TAXABLE"> Ret Renta </span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="form-control"
              :value="sumObject.reterenta"
              disabled
            />
          </v-row>
        </v-col>
        <v-col
          class="responsiveSmallColFCF"
          v-if="
            sumObject.fovial > 0 &&
            (form.invoicetype == 'FCF' ||
              form.invoicetype == 'CCF' ||
              form.invoicetype == 'DNR')
          "
        >
          <v-row class="d-flex justify-center">
            <span id="NON-TAXABLE"> Fovial </span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="form-control"
              :value="sumObject.fovial"
              disabled
            />
          </v-row>
        </v-col>
        <v-col
          class="responsiveSmallColFCF"
          v-if="
            sumObject.cotrans > 0 &&
            (form.invoicetype == 'FCF' ||
              form.invoicetype == 'CCF' ||
              form.invoicetype == 'DNR')
          "
        >
          <v-row class="d-flex justify-center">
            <span id="NON-TAXABLE"> Cotrans </span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="form-control"
              :value="sumObject.cotrans"
              disabled
            />
          </v-row>
        </v-col>
        <v-col
          :class="
            form.invoicetype != 'TCKS'
              ? 'responsiveAverageColFCF'
              : 'responsiveAverageColFCF'
          "
        >
          <v-row class="d-flex justify-center">
            <span id="VENTAS-TOTAL">Total</span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="totalDesign form-control"
              :value="sumObject.total"
              disabled
              aria-describedby="VENTAS-TOTAL"
            />
          </v-row>
        </v-col>
        <v-col
          :class="`d-flex justify-center responsiveSmallCol`"
          style="margin-top: 10px"
        >
          <v-btn class="text-uppercase btnclean" @click.prevent="clearForm">
            <v-icon>mdi-broom</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="3"
          md="1"
          class="mb-2 d-flex justify-center responsiveSmallCol"
          style="margin-top: 10px"
          v-if="invalid == null && ndornc == null && form.isquote == 0"
        >
          <v-btn
            title="Guardar plantilla"
            class="text-uppercase btnsave"
            @click.prevent="
              pospayments
                ? ((showReturnedAndPaymentModal = true),
                  (form.istemplate = 1),
                  (isReadyToBill = false))
                : (showDialogTemplate = true)
            "
          >
            <v-icon>mdi-book-plus-multiple</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="3"
          md="2"
          class="mb-2 d-flex justify-center responsiveSmallCol"
          style="margin-top: 10px"
          v-if="invalid == null && ndornc == null && form.isquote == 0"
        >
          <v-btn
            title="Guardar factura"
            class="text-uppercase btnsave"
            @click.prevent="
              pospayments
                ? ((showReturnedAndPaymentModal = true),
                  (isReadyToBill = true),
                  (form.istemplate = 0))
                : sendBill(0)
            "
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-col>
        <v-col
          v-if="form.isquote == 0"
          cols="6"
          sm="3"
          md="2"
          :class="`mb-2 d-flex justify-center ${
            form.invoicetype != 'TCKS'
              ? 'responsiveAverageColFCF'
              : 'responsiveAverageCol'
          }`"
          style="margin-top: 10px"
        >
          <v-btn
            :disabled="creditBillingBlock"
            class="text-uppercase btn-add"
            @click.prevent="
              pospayments &&
              form.invoicetype != 'DND' &&
              form.invoicetype != 'DNC'
                ? ((showReturnedAndPaymentModal = true),
                  (isReadyToBill = false),
                  (form.istemplate = 0))
                : sendBill(1)
            "
            v-if="$store.getters['rolesUser/getCustomRole'](5)"
          >
            <v-icon>mdi-printer-pos</v-icon>
            &nbsp;Facturar
          </v-btn>
        </v-col>
        <v-col
          v-if="form.isquote == 1"
          cols="6"
          sm="3"
          md="2"
          :class="`mb-2 d-flex justify-center ${
            form.invoicetype != 'TCKS'
              ? 'responsiveAverageColFCF'
              : 'responsiveAverageCol'
          }`"
          style="margin-top: 10px"
        >
          <v-btn class="text-uppercase btn-add" @click.prevent="sendBill(0)">
            <v-icon>mdi-printer-pos</v-icon>
            &nbsp;CREAR COTIZACIÓN
          </v-btn>
        </v-col>
        <v-col v-if="showTimesExecute == true">
          <v-row class="d-flex justify-left">
            <span>Cantidad a procesar</span>
          </v-row>
          <v-row class="d-flex justify-left">
            <input
              type="number"
              v-model="form.timesexecute"
              class="form-control"
            />
          </v-row>
        </v-col>
      </v-row>
      <!-- </div>
    </div> -->
    </footer>

    <!-- Datos de factura que se muestran con el menú no minimizado -->
    <footer
      v-if="$store.getters.getDrawerMini == false"
      class="box smallScreenScrollYContainer"
      style="
        align-self: flex-end;
        display: flex;
        width: calc(100% - 300px);
        background: #41464c;
        color: white;
        bottom: 0;
        left: 280px;
        align-self: flex-start;
        z-index: 99999999;
        justify-content: right;
        position: fixed;
      "
    >
      <!-- <footer v-if="$store.getters.getDrawerMini == false"
          class="box" 
          style="background: #41464c;" 
    > -->
      <!-- <div style="overflow-x: scroll"> -->
      <!-- <div style="min-width: calc(100% - 300px);" class="d-flex"> -->
      <v-row class="mt-2 mb-1 ml-2 smallScreenScrollYContent justify-center">
        <v-col :class="'responsiveSmallCol'">
          <v-row class="d-flex justify-center">
            <span v-if="form.invoicetype != 'FEX'" id="subtotal">Gravadas</span>
            <span v-else id="subtotal">Afectas</span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="form-control"
              :value="sumObject.subTotal"
              disabled
              aria-describedby="subtotal"
            />
          </v-row>
        </v-col>
        <v-col
          class="responsiveSmallCol"
          v-if="
            form.invoicetype != 'FCF' &&
            form.invoicetype != 'TCKS' &&
            form.invoicetype != 'DNR'
          "
        >
          <v-row class="d-flex justify-center">
            <span id="IVA">IVA 13%</span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="form-control"
              :value="sumObject.iva"
              disabled
              aria-describedby="IVA"
            />
          </v-row>
        </v-col>
        <!-- <v-col cols="1" class="mx-2 "> -->

        <v-col v-if="form.invoicetype != 'FEX'" :class="'responsiveSmallCol'">
          <v-row class="d-flex justify-center">
            <span id="NON-TAXABLE">Exentas</span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="form-control"
              :value="sumObject.nontaxable"
              disabled
              aria-describedby="NON-TAXABLE"
            />
          </v-row>
        </v-col>

        <v-col v-if="form.invoicetype != 'FEX'" :class="'responsiveSmallCol'">
          <v-row class="d-flex justify-center">
            <span id="NON-TAXABLE" style="white-space: nowrap !important"
              >No Sujetas</span
            >
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="form-control"
              :value="sumObject.nonsuj"
              disabled
              aria-describedby="NON-TAXABLE"
            />
          </v-row>
        </v-col>
        <v-col
          class="responsiveSmallColFCF"
          v-if="
            (form.invoicetype == 'CCF' ||
              form.invoicetype == 'FCF' ||
              form.invoicetype == 'DNR' ||
              form.invoicetype == 'DNC' ||
              form.invoicetype == 'DND') &&
            (sumObject.ivaperce > 0 || sumObject.ivaret > 0)
          "
        >
          <v-row class="d-flex justify-center">
            <span id="NON-TAXABLE">
              {{ sumObject.ivaperce > 0 ? "IVA Perce" : "" }}
              {{ sumObject.ivaret > 0 ? "IVA Rete" : "" }}
            </span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              v-if="sumObject.ivaret > 0"
              type="text"
              class="form-control"
              :value="sumObject.ivaret"
              disabled
            />
            <input
              v-if="sumObject.ivaperce > 0"
              type="text"
              class="form-control"
              :value="sumObject.ivaperce"
              disabled
            />
          </v-row>
        </v-col>
        <v-col class="responsiveSmallCol" v-if="sumObject.reterenta > 0">
          <v-row class="d-flex justify-center">
            <span id="NON-TAXABLE"> Ret Renta </span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="form-control"
              :value="sumObject.reterenta"
              disabled
            />
          </v-row>
        </v-col>
        <v-col
          class="responsiveSmallColFCF"
          v-if="
            sumObject.fovial > 0 &&
            (form.invoicetype == 'FCF' ||
              form.invoicetype == 'CCF' ||
              form.invoicetype == 'DNR')
          "
        >
          <v-row class="d-flex justify-center">
            <span id="NON-TAXABLE"> Fovial </span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="form-control"
              :value="sumObject.fovial"
              disabled
            />
          </v-row>
        </v-col>
        <v-col
          class="responsiveSmallColFCF"
          v-if="
            sumObject.cotrans > 0 &&
            (form.invoicetype == 'FCF' ||
              form.invoicetype == 'CCF' ||
              form.invoicetype == 'DNR')
          "
        >
          <v-row class="d-flex justify-center">
            <span id="NON-TAXABLE"> Cotrans </span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="form-control"
              :value="sumObject.cotrans"
              disabled
            />
          </v-row>
        </v-col>
        <v-col
          :class="
            form.invoicetype != 'TCKS'
              ? 'responsiveAverageColFCF'
              : 'responsiveAverageCol'
          "
        >
          <v-row class="d-flex justify-center">
            <span id="VENTAS-TOTAL">Total</span>
          </v-row>
          <v-row class="d-flex justify-center">
            <input
              type="text"
              class="totalDesign form-control"
              :value="sumObject.total"
              disabled
              aria-describedby="VENTAS-TOTAL"
            />
          </v-row>
        </v-col>
        <!-- <v-col cols="3" :md="form.invoicetype != 'FCF' ? 1 : 2" class="d-flex justify-right responsiveTinyCol" -->
        <v-col
          :class="`d-flex justify-center responsiveSmallCol`"
          style="margin-top: 10px"
        >
          <v-btn class="text-uppercase btnclean" @click.prevent="clearForm">
            <v-icon>mdi-broom</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="3"
          md="1"
          :class="`mb-2 d-flex justify-center responsiveSmallCol`"
          style="margin-top: 10px"
          v-if="invalid == null && ndornc == null && form.isquote == 0"
        >
          <v-btn
            title="Guardar plantilla"
            class="text-uppercase btnsave"
            v-if="$store.getters['rolesUser/getCustomRole'](7)"
            @click.prevent="
              pospayments
                ? ((showReturnedAndPaymentModal = true),
                  (form.istemplate = 1),
                  (isReadyToBill = false))
                : (showDialogTemplate = true)
            "
          >
            <v-icon>mdi-book-plus-multiple</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="3"
          md="2"
          :class="`mb-2 d-flex justify-center responsiveSmallCol`"
          style="margin-top: 10px"
          v-if="invalid == null && ndornc == null && form.isquote == 0"
        >
          <v-btn
            title="Guardar factura"
            class="text-uppercase btnsave"
            @click.prevent="
              pospayments
                ? ((showReturnedAndPaymentModal = true),
                  (isReadyToBill = true),
                  (form.istemplate = 0))
                : sendBill(0)
            "
            v-if="$store.getters['rolesUser/getCustomRole'](6)"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-col>
        <v-col
          v-if="form.isquote == 0"
          cols="3"
          md="2"
          :class="`mb-2 d-flex justify-center ${
            form.invoicetype != 'TCKS'
              ? 'responsiveAverageColFCF'
              : 'responsiveAverageCol'
          }`"
          style="margin-top: 10px"
        >
          <v-btn
            :disabled="creditBillingBlock"
            class="text-uppercase btn-add"
            @click.prevent="
              pospayments &&
              form.invoicetype != 'DND' &&
              form.invoicetype != 'DNC'
                ? ((showReturnedAndPaymentModal = true),
                  (isReadyToBill = false),
                  (form.istemplate = 0))
                : sendBill(1)
            "
            v-if="$store.getters['rolesUser/getCustomRole'](5)"
          >
            <v-icon>mdi-printer-pos</v-icon>
            &nbsp;Facturar
          </v-btn>
        </v-col>
        <v-col
          v-if="form.isquote == 1"
          cols="3"
          md="2"
          :class="`mb-2 d-flex justify-center ${
            form.invoicetype != 'TCKS'
              ? 'responsiveAverageColFCF'
              : 'responsiveAverageCol'
          }`"
          style="margin-top: 10px"
        >
          <v-btn
            class="text-uppercase btn-add"
            @click.prevent="sendBill(0)"
            v-if="$store.getters['rolesUser/getCustomRole'](72)"
          >
            <v-icon>mdi-printer-pos</v-icon>
            &nbsp;CREAR COTIZACIÓN
          </v-btn>
        </v-col>
        <v-col v-if="showTimesExecute == true">
          <v-row class="d-flex justify-left">
            <span>Cantidad a procesar</span>
          </v-row>
          <v-row class="d-flex justify-left">
            <input
              type="number"
              v-model="form.timesexecute"
              class="form-control"
            />
          </v-row>
        </v-col>
      </v-row>
      <!-- </div>
    </div> -->
    </footer>

    <alerts
      style="z-index: 20000 !important"
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    >
    </alerts>
    <v-dialog v-model="showDetailsMessages" width="60%">
      <v-card>
        <v-card-title
          class="text-h5"
          style="
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            display: flex;
            justify-content: space-between;
          "
        >
          <div>Detalles de factura</div>
          <div>
            <v-btn
              small
              class="btndelete"
              @click.prevent="showDetailsMessages = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <div>
            <v-btn @click="copyText()" class="btnsave"> copiar texto </v-btn>
          </div>
          <p
            style="color: black !important; font-size: 16px"
            v-html="currenterrordata"
          ></p>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <print-pos-tk :dataProps="print2"></print-pos-tk>

    <!--print-pos-tk-fcf :dataProps="print3"></print-pos-tk-fcf-->
    
    <template v-if="isFCF">
      <print-pos-factura :dataRegular="print" :dataTicket="print3"></print-pos-factura>
    </template>
    <template v-else>
      <print-pos-fa :dataProps="print"></print-pos-fa>
    </template>

    <v-dialog v-model="showDialogTemplate" max-width="1000">
      <v-card>
        <v-card-title class="text-h5"
          >Creación de plantilla de facturación</v-card-title
        >
        <v-card-text>
          <v-text-field
            v-model="form.templatename"
            label="Nombre de plantilla"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-left">
            <v-btn @click="showDialogTemplate = false" class="btndelete">
              Cancelar
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
              @click="
                form.istemplate = 1;
                sendBill(0);
              "
              class="btn-add"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <InvoiceProductsClasificationSelector v-if="productsToBillForSelector.length > 0 && servicesToBillForSelector.length > 0" v-model="showClasificationSelector" :products="productsToBillForSelector" :services="servicesToBillForSelector" @addElement="addElementFromSelector"></InvoiceProductsClasificationSelector> -->
    <InvoiceProductsClasificationSelector
      v-model="showClasificationSelector"
      :products="productsToBillForSelector"
      :services="servicesToBillForSelector"
      @addElement="addElementFromSelector"
    ></InvoiceProductsClasificationSelector>
    <v-dialog v-model="showReturnedAndPaymentModal" scrollable max-width="1000">
      <v-card height="inherit" style="overflow: auto">
        <v-card-title class="text-h5"> Método de pago </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn @click="addPaymentType" class="btnsave">
                <v-icon>mdi-plus</v-icon>&nbsp;Agregar tipo de pago
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <DxDataGrid :data-source="paymentsData" :show-borders="true">
              <DxEditing
                :allow-updating="true"
                :select-text-on-edit-start="true"
                start-edit-action="click"
                mode="cell"
              />
              <DxColumn cell-template="type-payments" caption="Tipo de pago" />
              <template #type-payments="{ data }">
                <div>
                  <div style="width: 100%">
                    <select
                      style="width: calc(100% - 30px); padding: 5px"
                      v-model="data.data.typePayment"
                      @change="handleTypePaymentChange(data)"
                    >
                      <option
                        v-for="(paymentType, index) in paymentTypes"
                        :key="index"
                        :value="paymentType.Codigo"
                      >
                        {{ paymentType.Valores }}
                      </option>
                    </select>
                    <v-icon>mdi-arrow-down</v-icon>
                  </div>
                </div>
              </template>
              <DxColumn cell-template="total-payment" caption="Total" />
              <template #total-payment="{ data }">
                <div>
                  <input
                    type="number"
                    style="width: 100% !important; text-align: center"
                    v-model="data.data.totalPayment"
                    @blur="showPaymentWithDecimal(data.data)"
                    @input="handleRowChangedPayments()"
                  />
                </div>
              </template>
              <DxColumn
                cell-template="reference-editing"
                caption="N. Referencia"
              />
              <template #reference-editing="{ data }">
                <template>
                  <input
                    v-model="data.data.reference"
                    style="width: 100% !important; text-align: center"
                    :disabled="data.data.typePayment == '01'"
                  />
                </template>
              </template>

              <DxColumn :width="50" cell-template="delete-payment" caption="" />
              <template #delete-payment="{ data }">
                <a href="#" class="mx-1" @click="deletePayment(data.data)">
                  <v-icon color="error"> mdi-delete </v-icon>
                </a>
              </template>
            </DxDataGrid>
          </v-row>
          <v-row>
            <v-col class="mt-5">
              <v-row class="d-flex justify-center">
                <span style="color: black">Total a pagar</span>
              </v-row>
              <v-row class="d-flex justify-center">
                <input type="text" class="form-control" :value="sumPayments" />
              </v-row>
            </v-col>
            <v-col class="mt-5">
              <v-row class="d-flex justify-center">
                <span style="color: black">Total a cancelar</span>
              </v-row>
              <v-row class="d-flex justify-center">
                <input
                  type="text"
                  class="totalDesign form-control"
                  :value="sumObject.total"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-col class="text-left">
            <v-btn
              @click="showReturnedAndPaymentModal = false"
              class="btndelete"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col v-if="form.isquote" class="text-right">
            <v-btn
              @click="
                showReturnedAndPaymentModal = false;
                sendBill(0);
              "
              class="btn-add"
            >
              <v-icon>mdi-content-save</v-icon>&nbsp;Guardar Cotización
            </v-btn>
          </v-col>
          <v-col v-if="form.istemplate" class="text-right">
            <v-btn
              @click="
                showReturnedAndPaymentModal = false;
                showDialogTemplate = true;
              "
              class="btnsave"
            >
              <v-icon>mdi-content-save</v-icon>&nbsp;Guardar Plantilla
            </v-btn>
          </v-col>
          <v-col v-if="isReadyToBill" class="text-right">
            <v-btn
              @click="
                showReturnedAndPaymentModal = false;
                sendBill(0);
              "
              class="btnsave"
            >
              <v-icon>mdi-content-save</v-icon>&nbsp;Guardar Factura
            </v-btn>
          </v-col>
          <v-col
            v-if="!isReadyToBill && form.istemplate == 0 && form.isquote == 0"
            class="text-right"
          >
            <v-btn
              @click="
                showReturnedAndPaymentModal = false;
                sendBill(1);
              "
              class="btn-add"
              v-if="$store.getters['rolesUser/getCustomRole'](5)"
            >
              <v-icon>mdi-printer-pos</v-icon>&nbsp;Facturar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <alert
      v-model="alertFactura.show"
      :title="alertFactura.title"
      :color="alertFactura.color"
      :altColor="alertFactura.colorBtn"
      :titleColor="alertFactura.colorTitle"
    >
      <div :class="`mb-3 ${['RECHAZADO', 'CONTINGENCIA'].includes(alertFactura.estado) ? 'black--text' : 'white--text'}`">{{alertFactura.message}}</div>
      <template v-if="!!alertFactura.total">
        <div class="mb-1"><span :class="`'mb-1 text-h5' ${['RECHAZADO', 'CONTINGENCIA'].includes(alertFactura.estado) ? 'black--text' : 'primary--text'}`">Total facturado:</span> ${{alertFactura.total}}</div>
        <div class="text-h5"><span :class="`'text-h5' ${['RECHAZADO', 'CONTINGENCIA'].includes(alertFactura.estado) ? 'black--text' : 'primary--text'}`">Total pagado:</span> ${{alertFactura.pagoCon}}</div>
        <div class="mt-1 text-h5"><span :class="`'text-h5' ${['RECHAZADO', 'CONTINGENCIA'].includes(alertFactura.estado) ? 'black--text' : 'primary--text'}`">Cambio:</span> ${{alertFactura.cambio}}</div>
      </template>
      <div class="mt-3"><span :class="`${['RECHAZADO', 'CONTINGENCIA'].includes(alertFactura.estado) ? 'black--text' : 'primary--text'}`">Estado: </span>{{alertFactura.estado}}</div>
      <div :class="`${['RECHAZADO', 'CONTINGENCIA'].includes(alertFactura.estado) ? 'black--text' : 'primary--text'}`">Mensaje del Ministerio de Hacienda:</div><span>{{alertFactura.description}}</span>
      <div>
        <span :class="`${['RECHAZADO', 'CONTINGENCIA'].includes(alertFactura.estado) ? 'black--text' : 'primary--text'}`">Observaciones DTE:</span>
        <template v-if="!!alertFactura.observaciones.length">
          <div :key="index" v-for="(observacion, index) in alertFactura.observaciones">- {{observacion}}</div>
        </template>
        <div v-else>- Ninguna observación</div>   
      </div>
    </alert>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxAsyncRule,
  DxRequiredRule,
  DxFormat,
  DxPatternRule,
  DxScrolling,
  DxLookup,
} from "devextreme-vue/data-grid";
import InvoiceProductsClasificationSelector from "../InvoiceProductsClasificationSelector.vue";
import Vue from "vue";
import PrintPosFa from "@/components/ReportsAndPrintings/PrintPosFa";
import PrintPosTk from "@/components/ReportsAndPrintings/PrintPosTk";
import { multipleCriteriaFilter } from "@/helpers/object";
import Search from "./Search";
import DatePicker from "../../../components/DatePicker";
import NullInvoice from "./NullInvoice";
import Alerts from "@/components/Alerts";
import { Observable } from "rxjs";
import { mapState } from "vuex";
import { quillConfig } from "../../../helpers/quillconfig";
import { mapGetters } from "vuex";
import { numberToText } from "../../../helpers/conveterNumberText";
import Block from "@/components/Block";
import GeneralFilter from "../../../components/GeneralFilter.vue";
import CustomAutoComplete from "../../../components/CustomAutoComplete.vue";
import SearchDialog from "@/components/SearchDialog.vue";
import { numberWithCommas } from "../../../helpers/money";
import QuantityDialog from "../../../components/POS/QuantityDialog.vue";
import { generateUUID } from "../../../helpers/misc";
import PrintPosFactura from "../../../components/ReportsAndPrintings/PrintPosFactura.vue";

import {
  dui as duiMask,
  nrc as nrcMask,
  applyDUIMaskOutOfInput,
  // applyNITMaskOutOfInput,
  nitMaskHandlingHomologation,
  applyNitMaskHandlingHomologationOutOfInput,
} from "@/helpers/masks";

import { validateNITHomologation } from "@/helpers/object";
import { onlyDigitsAndAPoint } from "../../../helpers/masks";
//import PrintPosTkFcf from "@/components/ReportsAndPrintings/PrintPosTkFcf.vue";
import {
  VUE_APP_ENV,
  VUE_APP_CARSEM_ID_KEYMASTER1,
  VUE_APP_CARSEM_ID_KEYMASTER2,
  VUE_APP_CARSEM_ID_KEYMASTER3,
} from "../../../config";

import { getCookie } from "../../../helpers/misc";

const printPosFaConstructor = Vue.extend(PrintPosFa);

export default {
  name: "Create",
  components: {
    InvoiceProductsClasificationSelector,
    SearchDialog,
    NullInvoice,
    DatePicker,
    Search,
    DxDataGrid,
    DxEditing,
    DxAsyncRule,
    DxRequiredRule,
    DxFormat,
    DxPatternRule,
    DxColumn,
    DxScrolling,
    DxLookup,
    Alerts,
    Block,
    PrintPosFa,
    PrintPosTk,
    //PrintPosTkFcf,
    GeneralFilter,
    CustomAutoComplete,
    QuantityDialog,
    PrintPosFactura
  },
  props: ["win"],
  data() {
    return {
      extendedViewTable: false,
      alertFactura:{
        show:false,
        title:"",
        message:"",
        color:"comment",
        total:"",
        pagoCon:"",
        cambio:"",
        estado:"",
        description:"",
        observaciones:[],
        colorBtn:"primary",
        colorTitle: "",
      },
      showPrices: false,
      headers: [
        {
          text: "Lista de precios",
          align: "start",
          sortable: false,
          value: "PriceListName",
        },
        {
          text: "Precio original",
          align: "end",
          sortable: false,
          value: "ItemOriginalPrice",
        },
        {
          text: "Monto descuento",
          align: "end",
          sortable: false,
          value: "ItemPriceDiscountAmount",
        },
        {
          text: "Porcentaje descuento",
          align: "end",
          sortable: false,
          value: "ItemPriceDiscountPercent",
        },
        {
          text: "Precio con descuento",
          align: "end",
          sortable: false,
          value: "ItemNewPrice",
        },
        {
          text: "Rango inicial",
          align: "end",
          sortable: false,
          value: "InitialRange",
        },
        {
          text: "Rango final",
          align: "end",
          sortable: false,
          value: "FinalRange",
        },
      ],
      prices: [],
      togglehide: false,
      togglehideSeller: "display: none !important",
      showClasificationSelector: false,
      sellersList: [],
      datatoprint: {
        branch: JSON.parse(localStorage.getItem("branch")),
        user: JSON.parse(localStorage.getItem("user")),
        header: null,
        body: null,
        dteJson: null,
      },
      print: {
        show: false,
        data: {},
      },
      print2: {
        show: false,
        data: {},
      },
      print3: {
        show: false,
        data: {},
      },
      allowEditName: false,
      customersList: [],
      itemQtyPattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/i,
      loading: false,
      workingIn: "",
      displayInvoice: "products",
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      currenterrordata: null,
      role: null,
      branch: null,
      business: null,
      search: {},
      form: {
        invoicedate: null,
        invoicetype: null,
        idinvoice: "",
        legalidinvoice: "",
        legalisnontaxable: 0,
        legalisnonsuj: 0,
        istemplate: 0,
        isquote: 0,
        timesexecute: 0,
        flete: 0,
        seguro: 0,
      },
      showBgcolorNotes: "tabs",
      confirmDelete: null,
      quillOptions: quillConfig,
      showNotes: false,
      showFilter: false,
      filterProducts: {},
      filterServices: {},
      newCostumer: false,
      priceList: [
        {
          PriceListName: "TODOS",
        },
      ],
      types: ["TODOS", "PRODUCTO"],
      warehouse: [
        {
          ID_Warehouse: "TODOS",
          Warehouse: "TODOS",
        },
      ],
      provider: [
        {
          ID_Provider: "TODOS",
          ProviderName: "TODOS",
        },
      ],
      productsToBillDefault: [],
      productsToBill: {},
      productsToBillForSelector: [],
      productToBillPrev: {},
      servicesToBill: [],
      servicesToBillForSelector: [],

      //??????? no se
      nullInvoice: false,

      productsToShow: [],
      taxpayersize: [
        { key: "MEDIANO", value: "MEDIANO" },
        { key: "PEQUEÑO", value: "PEQUEÑO" },
        { key: "GRANDE", value: "GRANDE" },
      ],
      businessSize: ["PEQUEÑO", "MEDIANO", "GRANDE"],
      businessEntity: [
        "PERSONA NATURAL",
        "PERSONA JURÍDICA",
        "SOCIEDAD ANÓNIMA",
        "CORPORACIÓN",
      ],
      searchCustomerDialog: false,
      searchSellerDialog: false,
      warehouses: [],
      headerFirstRowDefaultElements: 3,
      headerFirstRowElementWidth: 4,
      headerFirstRowMdsManagement: {
        3: 2,
        4: 3,
        5: null,
        6: 2,
      },
      headerSecondRowDefaultElements: 1,
      headerSecondRowElementWidth: 12,
      headerSecondRowColsManagement: {
        1: 12,
        2: 6,
        3: 4,
      },
      productcode: null,
      filterWarehouses: {},
      PriceListSelected: null,
      warehouseSelected: null,
      priceListData: [],
      isCustomerSelected: false,
      createdInvoiceId: null,
      discountObject: null,
      objectToApplyDiscount: {},
      showDiscount: false,
      showDiscountGlobal: false,
      barcode: "",
      isCarsem: false,
      typeService: [
        {
          ID: "PLT",
          Name: "Examen de Laboratorio",
        },
        {
          ID: "OTR",
          Name: "Otro",
        },
      ],
      showComment: false,
      showCustomer: false,
      showProductsAndServices: false,
      globalDiscount: { TotalNewPrice: 0, TotalPriceDiscountPercent: 0 },
      invoiceStatus: 0,
      pricelistDetail: [],
      showDetailsMessages: false,
      showDialogTemplate: false,
      taxPayerSize: null,
      BusinessLegalAgentRetain: false,
      required: [(v) => !!v || "Este campo es requerido"],
      defaultPriceList: null,
      productsFiltered: false,
      productsToShowFiltered: [],
      showTimesExecute: false,
      cancelTimesExecute: false,
      barCodes: [],
      selectedBarCode: null,
      barCodeToFind: null,
      customerCreditInformation: {},
      creditBillingBlock: false,
      productsToBillSearchTab: [],
      servicesToBillSearchTab: [],
      ProductServList: [],
      assignedList: {
        prod: false,
        serv: false,
      },
      posrestaurant: JSON.parse(localStorage.getItem("user")).posrestaurant,
      Currency: JSON.parse(localStorage.getItem("branch")).Currency,
      showReturnedAndPaymentModal: false,
      isReadyToBill: false,
      paymentsData: [
        {
          typePayment: "01",
          descriptionPayment: "Billetes y monedas",
          totalPayment: 0.0,
          reference: null,
        },
        {
          typePayment: "02",
          descriptionPayment: "Tarjeta Débito",
          totalPayment: 0.0,
          reference: null,
        },
        {
          typePayment: "03",
          descriptionPayment: "Tarjeta Crédito",
          totalPayment: 0.0,
          reference: null,
        },
        {
          typePayment: "04",
          descriptionPayment: "Cheque",
          totalPayment: 0.0,
          reference: null,
        },
        {
          typePayment: "05",
          descriptionPayment: "Transferencia_ Depósito Bancario",
          totalPayment: 0.0,
          reference: null,
        },
      ],
      sumPayments: 0,
      pospayments: JSON.parse(localStorage.getItem("user")).pospayments,
      codigosAsociados: [
        { id: 1, valor: "Emisor" },
        { id: 2, valor: "Receptor" },
      ],
      codigosAsociadosEx: [
        {
          id: 4,
          valor: "Transporte",
        },
        {
          id: 1,
          valor: "Emisor",
        },
        {
          id: 2,
          valor: "Receptor",
        },
      ],
      modoTransporte: [
        { id: 1, valor: "Terrestre" },
        { id: 2, valor: "Marítimo" },
        { id: 3, valor: "Aéreo" },
        { id: 4, valor: "Multimodal, Terrestre-marítimo" },
        { id: 5, valor: "Multimodal, Terrestre-aéreo" },
        { id: 6, valor: "Multimodal, Marítimo- aéreo" },
        { id: 7, valor: "Multimodal, Terrestre-Marítimo- aéreo" },
      ],
      itemPriceDiscountPercentFocus: false,
      itemPriceDiscountAmountFocus: false,
      creditDays: [
        "8 días",
        "15 días",
        "30 días",
        "45 días",
        "60 días",
        "90 días",
        "120 días",
        "180 días",
      ],
      stopIn: false,
      DynamicPriceLists: JSON.parse(localStorage.getItem("user"))
        .dynamicpricelists,
      POSRequiredIdSeller: JSON.parse(localStorage.getItem("user"))
        .posrequiredidseller,
      showQuantityDialog: false,
      currentItemToAdd: null,
      currentItemType: 0,
      POSProductsAlternativeBehavior: JSON.parse(localStorage.getItem("user"))
        .posproductsalternativebehavior,
      keyboardConfig: {
        F10: () => {
          if (this.form.idcustomer) {
            this.togglehide = true;
          }
          this.loadpriceProducts();
        },
        F12: () => {
          if (
            this.pospayments &&
            this.form.invoicetype != "DND" &&
            this.form.invoicetype != "DNC"
          ) {
            this.showReturnedAndPaymentModal = true;
            this.isReadyToBill = false;
            this.form.istemplate = 0;
          }
        },
        F1: () => {
          this.sendBill(1);
        },
        F2: () => {
          this.sendBill(0);
        },
        F11: () => {
          let index =
            this.priceListData.findIndex(
              (current) => current.id === this.PriceListSelected
            ) + 1;
          if (index >= this.priceListData.length) {
            index = 0;
          }
          const { id } = this.priceListData[index];
          this.PriceListSelected = id;
          this.getPriceListSelected();
        },
      },
      posshowprincipalbarcode: JSON.parse(localStorage.getItem("user"))
        .posshowprincipalbarcode,
      bienTitulo: [
        { id: "01", value: "Depósito" },
        { id: "02", value: "Propiedad" },
        { id: "03", value: "Consignación" },
        { id: "04", value: "Traslado" },
        { id: "05", value: "Otros" },
      ],
      ZoneConfiguration: [],
      EnableZoneConfiguration: JSON.parse(localStorage.getItem("user"))
        .enablezoneconfiguration,
      allowRepetitionOfArticles: false,
      EnableNegativeItem: JSON.parse(localStorage.getItem("user")).enablenegativeitem,
      ferretlabenddata: JSON.parse(localStorage.getItem("user")).ferretlabenddata,
    };
  },
  computed: {
    ...mapState(["changueWarehouse", "changuePriceList"]),
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    isFCF() {
      return (
        JSON.parse(localStorage.getItem("user")).ticketformatfcf &&
        JSON.parse(localStorage.getItem("user")).TipoPlan != "Emprendedor" &&
        JSON.parse(localStorage.getItem("user")).TipoPlan != "Ecoanual" &&
        (this.form.invoicetype == "FCF" || this.form.invoicetype == "CCF")
      );
    },
    isTablet() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    colClasses() {
      let classes = {
        "d-flex": true,
        "justify-center": true,
        responsiveTinyCol: true,
        "mt-8": !this.isMobile,
      };

      if (this.form.isquote === 1) {
        classes["mr-4"] = true;
      }
      return classes;
    },
    colGroupStyle() {
      if (this.isTablet) {
        return {
          width: "100%",
          display: "flex",
          "justify-content": "center",
          margin: "25px",
        };
      } else {
        return "";
      }
    },
    retornarDTEname() {
      if (this.form.invoicetype == "FCF") {
        return "FACTURA CONSUMIDOR FINAL";
      } else {
        if (this.form.invoicetype == "CCF") {
          return "COMPROBANTE CRÉDITO FISCAL";
        } else {
          if (this.form.invoicetype == "FNS") {
            return "FACTURA SUJETO EXCLUIDO";
          } else {
            if (this.form.invoicetype == "FEX") {
              return "FACTURA EXPORTACIÓN";
            } else {
              if (this.form.invoicetype == "CDD") {
                return "COMPROBANTE DE DONACION";
              } else {
                if (this.form.invoicetype == "DNC") {
                  return "NOTA DE CRÉDITO";
                } else {
                  if (this.form.invoicetype == "DND") {
                    return "NOTA DE DÉBITO";
                  } else {
                    if (this.form.invoicetype == "DNR") {
                      return "NOTA DE REMISIÓN";
                    } else {
                      return "Otro";
                    }
                  }
                }
              }
            }
          }
        }
      }
    },

    filterType() {
      if (
        (JSON.parse(localStorage.getItem("branch")).ID_Business == "17" ||
          JSON.parse(localStorage.getItem("branch")).ID_Business == "41") &&
        this.form.invoicetype != "DNC" &&
        this.form.invoicetype != "DND" &&
        this.form.invoicetype != "COT"
      ) {
        return [
          {
            name: "CONSUMIDOR FINAL",
            key: "FCF",
          },
          {
            name: "CRÉDITO FISCAL",
            key: "CCF",
          },
          {
            name: "SUJETO EXCLUIDO",
            key: "FNS",
          },
          {
            name: "EXPORTACIÓN",
            key: "FEX",
          },
          {
            name: "COMPROBANTE DE DONACIÓN",
            key: "CDD",
          },
          {
            name: "TICKETS",
            key: "TCKS",
          },
          {
            name: "NOTA DE REMISIÓN",
            key: "DNR",
          },
        ];
      } else if (this.form.invoicetype == "DNC") {
        return [
          {
            name: "NOTA DE CRÉDITO",
            key: "DNC",
          },
        ];
      } else if (this.form.invoicetype == "DND") {
        return [
          {
            name: "NOTA DE DÉBITO",
            key: "DND",
          },
        ];
      } else if (this.form.invoicetype == "COT") {
        return [
          {
            name: "COTIZACIÓN",
            key: "COT",
          },
        ];
      } else {
        return [
          {
            name: "CONSUMIDOR FINAL",
            key: "FCF",
          },
          {
            name: "CRÉDITO FISCAL",
            key: "CCF",
          },
          {
            name: "SUJETO EXCLUIDO",
            key: "FNS",
          },
          {
            name: "EXPORTACIÓN",
            key: "FEX",
          },
          {
            name: "COMPROBANTE DE DONACIÓN",
            key: "CDD",
          },
          {
            name: "NOTA DE REMISIÓN",
            key: "DNR",
          },
        ];
      }
    },
    checkCountryInvoiceStatuses() {
      const country = JSON.parse(localStorage.getItem("branch")).Country;
      if (
        this.isCustomerSelected &&
        country === "El Salvador" &&
        (this.form.creditstatus == "BLOQUEADO" ||
          this.form.creditstatus == "INACTIVO" ||
          this.form.creditstatus == "SUSPENDIDO")
      ) {
        return ["Cancelada"];
      } else if (
        this.form.invoicetype == "DNC" &&
        this.form.invoicetype == "DND"
      ) {
        return ["Cancelada"];
      } else if (country === "El Salvador") {
        return ["Cancelada", "Crédito"];
      } else {
        return ["Cancelada", "Pendiente de pago", "Crédito"];
      }
    },
    checkWarehandPl() {
      if (!this.PriceListSelected || !this.warehouseSelected) return true;
      else return false;
    },
    correlative() {
      return this.form.idinvoice;
    },
    allproducts() {
      let values = [];
      if (this.productsToBillSearchTab.length > 0) {
        this.productsToBillSearchTab.forEach((element) => {
          values.push({
            ID: element.ID_Item,
            val: element.ID_Item,
            prod: { ...element, type: "product" },
          });
        });
      }
      if (this.servicesToBillSearchTab.length > 0) {
        this.servicesToBillSearchTab.forEach((element) => {
          values.push({
            ID: element.CodeService,
            val: element.CodeService,
            prod: { ...element, type: "service" },
          });
        });
      }
      return values;
    },
    allproductbyName() {
      let values = [];
      // const products = [];
      if (this.productsToBillSearchTab.length > 0) {
        if (this.warehouseSelected) {
          const filteredProducts = this.productsToBillSearchTab.filter(
            (element) => element.Warehouse === this.warehouseSelected
          );

          filteredProducts.forEach((element) => {
            values.push({
              ID:
                element.ItemName +
                (element.ItemComercialName
                  ? " | " + element.ItemComercialName
                  : ""),
              val: element.ID_Item,
              prod: { ...element, type: "product" },
            });
          });
        } else {
          this.productsToBillSearchTab.forEach((element) => {
            values.push({
              ID:
                element.ItemName +
                (element.ItemComercialName
                  ? " | " + element.ItemComercialName
                  : ""),
              val: element.ID_Item,
              prod: { ...element, type: "product" },
            });
          });
        }
      }
      if (this.servicesToBillSearchTab.length > 0) {
        this.servicesToBillSearchTab.forEach((element) => {
          values.push({
            ID:
              element.NameService + (element.Name ? " | " + element.Name : ""),
            val: element.CodeService,
            prod: { ...element, type: "service" },
          });
        });
      }

      // console.log(products.length, this.productsToBill.filter( element => element.Warehouse === this.warehouseSelected).length );
      return values;
    },
    sumObject() {
      let sum = this.productsToShow.reduce(
        (prev_value, next_value) => {
          let price = parseFloat(this.calculateCellValue(next_value));
          let sumInSubTotal = 0.0;
          //let sumInIva = 0.00

          if (
            next_value.nontaxable != true &&
            next_value.nontaxable2 != true &&
            next_value.nonsuj != true &&
            next_value.nonsuj2 != true
          ) {
            sumInSubTotal = price;
            //sumInIva = parseFloat(parseFloat(next_value.ItemOriginalPrice / 1.13).toFixed(2))
            //sumInIva = next_value.ItemOriginalPrice - sumInIva
            //sumInIva = parseFloat(parseFloat(sumInIva * next_value.qty).toFixed(2))
          } else {
            sumInSubTotal = 0.0;
          }

          return {
            subTotal: +prev_value.subTotal + sumInSubTotal,
            nontaxable:
              prev_value.nontaxable +
              (next_value.nontaxable
                ? price
                : next_value.nontaxable2
                ? price
                : 0),
            nonsuj:
              prev_value.nonsuj +
              (next_value.nonsuj ? price : next_value.nonsuj2 ? price : 0),
            //iva:
            //  prev_value.iva + sumInIva
          };
        },
        { subTotal: 0, nontaxable: 0, nonsuj: 0 } //iva: 0 }
      );

      if (this.form.invoicetype == "FCF") {
        sum.subTotal = parseFloat(sum.subTotal).toFixed(2);
        sum.nontaxable = parseFloat(sum.nontaxable).toFixed(2);
        sum.nonsuj = parseFloat(sum.nonsuj).toFixed(2);
        sum.iva = 0.0;
        sum.fovial = 0.0;
        sum.cotrans = 0.0;
        sum.reterenta = 0.0;

        let sumtmp =
          parseFloat(sum.subTotal) +
          parseFloat(sum.nontaxable) +
          parseFloat(sum.nonsuj);

        if (
          this.form.isa_publicentity ||
          (!this.BusinessLegalAgentRetain &&
            this.form.legalagentretain)
        ) {
          if (sumtmp >= 113) {
            let subTotalLessIva = parseFloat(sum.subTotal / 1.13).toFixed(2);
            sum.ivaret = parseFloat(parseFloat(subTotalLessIva) * 0.01).toFixed(
              2
            );
          } else if (this.form.removerestrictionpublicentity) {
            let subTotalLessIva = parseFloat(sum.subTotal / 1.13).toFixed(2);
            sum.ivaret = parseFloat(parseFloat(subTotalLessIva) * 0.01).toFixed(
              2
            );
          } else {
            sum.ivaret = 0.0;
          }
        } else {
          sum.ivaret = 0.0;
        }

        let fovial = 0;
        let cotrans = 0;
        for (let m = 0; m < this.productsToShow.length; m++) {
          if (
            this.productsToShow[m].type != "service" &&
            this.productsToShow[m].IsHidrocarburo
          ) {
            fovial += parseFloat(parseFloat(this.productsToShow[m].qty) * 0.2);
            cotrans += parseFloat(parseFloat(this.productsToShow[m].qty) * 0.1);
          }
        }

        sum.fovial = parseFloat(fovial).toFixed(2);
        sum.cotrans = parseFloat(cotrans).toFixed(2);

        if (this.form.legalreterenta) {
          let reterenta = 0;
          for (let m = 0; m < this.productsToShow.length; m++) {
            if (this.productsToShow[m].type == "service") {
              reterenta += parseFloat(
                parseFloat(this.productsToShow[m].ItemNewNewPrice / 1.13) * 0.1
              )
            }
          }

          sum.reterenta = parseFloat(reterenta).toFixed(2);
        }

        sum.total = parseFloat(
          parseFloat(sum.subTotal) +
            parseFloat(sum.nontaxable) +
            parseFloat(sum.nonsuj) +
            parseFloat(sum.fovial) +
            parseFloat(sum.cotrans) -
            parseFloat(sum.ivaret) -
            parseFloat(sum.reterenta) 
        ).toFixed(2);
      } else if (
        this.form.invoicetype == "CCF" ||
        this.form.invoicetype == "DND" ||
        this.form.invoicetype == "DNC"
      ) {
        sum.iva = parseFloat(sum.subTotal - sum.subTotal / 1.13).toFixed(2);
        sum.subTotal = parseFloat(
          parseFloat(sum.subTotal) - parseFloat(sum.iva)
        ).toFixed(2);
        sum.nontaxable = parseFloat(sum.nontaxable).toFixed(2);
        sum.nonsuj = parseFloat(sum.nonsuj).toFixed(2);
        sum.fovial = 0.0;
        sum.cotrans = 0.0;
        sum.reterenta = 0.0;

        let sumtmp =
          parseFloat(sum.subTotal) +
          parseFloat(sum.nontaxable) +
          parseFloat(sum.nonsuj);
        //IvaPerce1
        if (
          this.BusinessLegalAgentRetain &&
          !this.form.legalagentretain
        ) {
          if (sumtmp >= 100) {
            sum.ivaperce = parseFloat(sum.subTotal * 0.01).toFixed(2);
          } else {
            sum.ivaperce = 0;
          }

          sum.ivaret = 0;
        } else {
          sum.ivaperce = 0;
        }

        //IvaRete1
        if (
          !this.BusinessLegalAgentRetain &&
          this.form.legalagentretain
        ) {
          if (sumtmp >= 100) {
            sum.ivaret = parseFloat(sum.subTotal * 0.01).toFixed(2);
          } else {
            sum.ivaret = 0;
          }

          sum.ivaperce = 0;
        } else {
          sum.ivaret = 0;
        }

        let fovial = 0;
        let cotrans = 0;
        for (let m = 0; m < this.productsToShow.length; m++) {
          if (
            this.productsToShow[m].type != "service" &&
            this.productsToShow[m].IsHidrocarburo
          ) {
            fovial += parseFloat(parseFloat(this.productsToShow[m].qty) * 0.2);
            cotrans += parseFloat(parseFloat(this.productsToShow[m].qty) * 0.1);

            if (parseFloat(sum.ivaperce) > 0) {
              sum.ivaperce = parseFloat(
                parseFloat(sum.ivaperce) -
                  parseFloat(this.productsToShow[m].ItemNewNewPrice / 1.13) *
                    0.01
              ).toFixed(2);
            }
          }
        }

        sum.fovial = parseFloat(fovial).toFixed(2);
        sum.cotrans = parseFloat(cotrans).toFixed(2);

        if (this.form.legalreterenta) {
          let reterenta = 0;
          for (let m = 0; m < this.productsToShow.length; m++) {
            if (this.productsToShow[m].type == "service") {
              reterenta += parseFloat(
                parseFloat(this.productsToShow[m].ItemNewNewPrice / 1.13) * 0.1
              )
            }
          }

          sum.reterenta = parseFloat(reterenta).toFixed(2);
        }

        sum.total = parseFloat(
          parseFloat(sum.iva) +
            parseFloat(sum.subTotal) +
            parseFloat(sum.nontaxable) +
            parseFloat(sum.nonsuj) +
            parseFloat(sum.fovial) +
            parseFloat(sum.cotrans) +
            parseFloat(sum.ivaperce) -
            parseFloat(sum.ivaret) - 
            parseFloat(sum.reterenta) 
        ).toFixed(2);
      } else {
        sum.subTotal = parseFloat(sum.subTotal).toFixed(2);
        sum.nontaxable = parseFloat(sum.nontaxable).toFixed(2);
        sum.nonsuj = parseFloat(sum.nonsuj).toFixed(2);
        sum.iva = parseFloat(0.0).toFixed(2);
        sum.ivaret = parseFloat(0.0).toFixed(2);

        if (this.form.invoicetype == "FNS") {
          let reterenta = 0;
          for (let m = 0; m < this.productsToShow.length; m++) {
            if (this.productsToShow[m].type == "service") {
              reterenta += parseFloat(
                parseFloat(this.productsToShow[m].ItemNewNewPrice) * 0.1
              );
            }
          }
          //sum.reterenta = parseFloat((parseFloat(sum.subTotal) * 0.1)).toFixed(2);
          sum.reterenta = parseFloat(reterenta).toFixed(2);
          sum.total = parseFloat(
            parseFloat(sum.subTotal) +
              parseFloat(sum.nontaxable) +
              parseFloat(sum.nonsuj) -
              parseFloat(sum.reterenta)
          ).toFixed(2);
        } else if (this.form.invoicetype == "FEX") {
          sum.total = parseFloat(
            parseFloat(sum.subTotal) +
              parseFloat(sum.nontaxable) +
              parseFloat(sum.nonsuj) +
              parseFloat(parseFloat(this.form.flete).toFixed(2)) +
              parseFloat(parseFloat(this.form.seguro).toFixed(2))
          ).toFixed(2);
        } else {
          sum.total = parseFloat(
            parseFloat(sum.subTotal) +
              parseFloat(sum.nontaxable) +
              parseFloat(sum.nonsuj)
          ).toFixed(2);
        }

        /*sum.subTotal = parseFloat(sum.subTotal).toFixed(2);
        sum.nontaxable = parseFloat(sum.nontaxable).toFixed(2);
        sum.iva = parseFloat(sum.subTotal * 0.13).toFixed(2);
        sum.ivaret = parseFloat(sum.subTotal * 0.1).toFixed(2);
        sum.total = parseFloat(
          parseFloat(sum.iva) +
            parseFloat(sum.subTotal) +
            parseFloat(sum.nontaxable)
        ).toFixed(2);*/
      }

      this.updatePaymentsData(sum.total);

      return sum;
    },
    ...mapState("posData", {
      customer: (state) => state.customer,
      header: (state) => state.header,
      body: (state) => state.body,
      invalid: (state) => state.invalid,
      ndornc: (state) => state.ndornc,
      quote: (state) => state.quote,
      contingency: (state) => state.contingency,
    }),
    validateform() {
      if (this.form.invoicetype == "CCF") {
        let counter = 0;
        /*if (this.form.legalidinvoice == "" || this.form.legalidinvoice == null) {
          counter++;
        }*/
        if (this.form.legaladdress == "" || this.form.legaladdress == null) {
          counter++;
        }
        if (this.form.legalname == "" || this.form.legalname == null) {
          counter++;
        }
        if (this.form.legalnit == "" || this.form.legalnit == null) {
          counter++;
        }
        if (this.form.legalnrc == "" || this.form.legalnrc == null) {
          counter++;
        }
        /* if(this.form.legalsector == "" || this.form.legalsector == null) {
          counter++;
        } */
        if (
          this.form.legaltaxpayersize == "" ||
          this.form.legaltaxpayersize == null
        ) {
          counter++;
        }
        /*if (this.form.dui == "" || this.form.dui == null) {
          counter++;
        }*/
        if (this.form.codactividad == "" || this.form.codactividad == null) {
          counter++;
        }
        if (this.form.isa_business) {
          if (
            this.form.businesscontactemail == "" ||
            this.form.businesscontactemail == null
          ) {
            counter++;
          }
        } else {
          if (this.form.email == "" || this.form.email == null) {
            counter++;
          }
        }

        if (this.form.isa_business) {
          if (
            this.form.businesscontactphone == "" ||
            this.form.businesscontactphone == null
          ) {
            counter++;
          }
        } else {
          if (this.form.phonenumber == "" || this.form.phonenumber == null) {
            counter++;
          }
        }

        if (counter > 0) return true;
        else return false;
      } else if (this.form.invoicetype == "FCF") {
        let counter = 0;
        if (
          this.sumObject.total > 25000.0 &&
          (this.form.dui == "" || this.form.dui == null)
        ) {
          counter++;
        }
        if (this.form.legalname == "" || this.form.legalname == null) {
          counter++;
        }
        if (counter > 0) return true;
        else return false;
      }

      return false;
    },
    ...mapGetters(["getCatalogMH", "activeWindowUuid"]),
    activities() {
      return this.getCatalogMH("ActividadEconomica");
    },
    paymentTypes() {
      return this.getCatalogMH("FormaPago");
    },
  },
  async mounted() {
    try {
      this.verifyCarsem();
      /*GET BUSINESS ID AND BRANCH FOR INVOICES*/
      this.branch = JSON.parse(localStorage.getItem("user")).branch;
      this.business = JSON.parse(localStorage.getItem("user")).businessid;
      this.search.branch = this.branch;
      this.search.business = this.business;
      this.getInfoBranch();
      this.form.invoicetype = "FCF";
      this.form.idbusiness = this.business;
      this.form.idbranch = this.branch;
      /*************************************/

      this.allowEditName = this.$store.getters["rolesUser/getCustomRole"](225);

      this.getAllCostumers();
      this.getAllSellers();

      /*identified user role*/
      this.role = localStorage.getItem("system_role");
      /******************** */

      this.filterProducts.business = this.business;
      this.filterProducts.branch = this.branch;

      this.filterServices.business = this.business;
      this.filterServices.branch = this.branch;

      this.filterProducts2 = {
        filter: "pricelist",
        business: this.business,
        branch: this.branch,
      };
      await this.getAllPriceList();
      this.getWarehouse();

      if (this.customer) {
        this.preloadCustomer();
      }

      this.getInvoiceId().subscribe(() => {
        this.$API.products.priceList().then((response) => {
          response.map((item, index) => {
            Vue.set(this.priceList, index + 1, item);
          });
          this.$API.warehouse.getWarehouses(this.search).then((response) => {
            response.map((item, index) => {
              Vue.set(this.warehouse, index + 1, item);
            });
            this.$API.providers.getProviders().then((response) => {
              response.map((item, index) => {
                Vue.set(this.provider, index + 1, item);
              });
            });
          });
        });
      });
      await this.getProducts();
      await this.getServices();

      await this.getZoneConfiguration();

      /*SEGMENT EDIT INVOICE*/
      if (this.header && this.body) {
        this.loadInvoiceHeader();
        this.loadInvoiceItems();
      } else if (this.quote) {
        this.loadQuoteBill();
      }
      /**** */

      this.reactiveMainProductsFilter();

      if (VUE_APP_ENV === "1") {
        document.addEventListener("keydown", (e) => {
          if (this.keyboardConfig[e.code]) {
            this.keyboardConfig[e.code]();
          }
        });
      }

      let userseller = JSON.parse(localStorage.getItem('user')).idseller
      if (userseller != null && userseller != "") {
        if(!this.isCarsem) {
          this.form.ID_Seller = userseller;
        }
      }
      
    } catch (error) {
      console.log(error);
    }
  },
  destroyed() {
    document.removeEventListener("keydown", (e) => {
      console.log(e);
    });
  },
  watch: {
    'alertFactura.show'(value){
      if(!value){
        this.closeAlert()
      }
    },
    warehouseSelected(newValue) {
      if (newValue != null && newValue != "") {
        this.filterProducts.warehouse = newValue;
        this.getProducts();
      }
    },
    showProductsAndServices(val) {
      if (!val) {
        this.getProductsOnFilter();
      }
    },
    headerFirstRowDefaultElements(val) {
      this.headerFirstRowElementWidth = this.headerFirstRowMdsManagement[val];
    },
    headerSecondRowDefaultElements(val) {
      this.headerSecondRowElementWidth =
        this.headerSecondRowColsManagement[val];
    },
    "form.legalidinvoice"(val, oldVal) {
      if (val != "No hay correlativo") {
        this.headerSecondRowDefaultElements++;
      } else if (oldVal === "No hay correlativo") {
        this.headerSecondRowDefaultElements--;
      }
    },
    "form.invoicestatusdescription"(val, oldVal) {
      if (val === "Crédito") {
        this.headerSecondRowDefaultElements++;
      } else if (oldVal === "Crédito") {
        this.headerSecondRowDefaultElements--;
      }
    },
    "form.invoicepaymenttype"(val, oldVal) {
      if (
        val != "Billetes y monedas" &&
        (oldVal === undefined || oldVal === "Billetes y monedas")
      ) {
        this.headerFirstRowDefaultElements++;
      } else if (
        val === "Billetes y monedas" &&
        oldVal != undefined &&
        oldVal != "Billetes y monedas"
      ) {
        this.headerFirstRowDefaultElements--;
      }
    },
    filterProducts: {
      deep: true,
      handler: "getProducts",
    },
    "form.invoicetype"() {
      if (this.productsToShow.length > 0 && this.form.invoicetype == "FEX") {
        for (let i = 0; i < this.productsToShow.length; i++) {
          this.productsToShow[i].nonsuj = 0;
          this.productsToShow[i].nonsuj2 = 0;
          this.productsToShow[i].nontaxable = 0;
          this.productsToShow[i].nontaxable2 = 0;
        }
      }
    },
    "discountObject.ItemPriceDiscountPercent"() {
      if (this.discountObject) {
        if (!this.itemPriceDiscountAmountFocus) {
          this.discountObject.ItemPriceDiscountAmount = (
            this.discountObject.ItemOriginalPrice *
            (this.discountObject.ItemPriceDiscountPercent / 100)
          ).toFixed(8);
        }
        this.discountObject.discountFixed =
          this.discountObject.ItemPriceDiscountAmount;

        if (this.discountObject.ItemPriceDiscountAmount > 0) {
          if (
            this.form.invoicetype != "CCF" &&
            this.form.invoicetype != "DNC" &&
            this.form.invoicetype != "DND"
          ) {
            this.discountObject.TmpItemNewPrice = parseFloat(
              parseFloat(
                (this.discountObject.ItemOriginalPrice -
                  this.discountObject.ItemPriceDiscountAmount) /
                  1.13
              ).toFixed(8)
            );
            this.discountObject.ItemNewPrice = parseFloat(
              parseFloat(
                this.discountObject.ItemOriginalPrice -
                  this.discountObject.ItemPriceDiscountAmount
              ).toFixed(8)
            );
          } else {
            this.discountObject.TmpItemNewPrice = parseFloat(
              parseFloat(
                (this.discountObject.ItemOriginalPrice -
                  this.discountObject.ItemPriceDiscountAmount) /
                  1.13
              ).toFixed(8)
            );
            this.discountObject.ItemNewPrice = parseFloat(
              parseFloat(
                this.discountObject.ItemOriginalPrice -
                  this.discountObject.ItemPriceDiscountAmount
              ).toFixed(8)
            );
          }
        }

        this.calculateCellValue(this.discountObject);
      }
    },
    "discountObject.ItemPriceDiscountAmount"() {
      if (this.discountObject) {
        if (!this.itemPriceDiscountPercentFocus) {
          this.discountObject.ItemPriceDiscountPercent = (
            (this.discountObject.ItemPriceDiscountAmount /
              this.discountObject.ItemOriginalPrice) *
            100
          ).toFixed(8);
        }
        this.discountObject.discountFixed =
          this.discountObject.ItemPriceDiscountAmount;

        if (this.discountObject.ItemPriceDiscountAmount > 0) {
          if (
            this.form.invoicetype != "CCF" &&
            this.form.invoicetype != "DNC" &&
            this.form.invoicetype != "DND"
          ) {
            this.discountObject.TmpItemNewPrice = parseFloat(
              parseFloat(
                (this.discountObject.ItemOriginalPrice -
                  this.discountObject.ItemPriceDiscountAmount) /
                  1.13
              ).toFixed(8)
            );
            this.discountObject.ItemNewPrice = parseFloat(
              parseFloat(
                this.discountObject.ItemOriginalPrice -
                  this.discountObject.ItemPriceDiscountAmount
              ).toFixed(8)
            );
          } else {
            this.discountObject.TmpItemNewPrice = parseFloat(
              parseFloat(
                (this.discountObject.ItemOriginalPrice -
                  this.discountObject.ItemPriceDiscountAmount) /
                  1.13
              ).toFixed(8)
            );
            this.discountObject.ItemNewPrice = parseFloat(
              parseFloat(
                this.discountObject.ItemOriginalPrice -
                  this.discountObject.ItemPriceDiscountAmount
              ).toFixed(8)
            );
          }
        }

        this.calculateCellValue(this.discountObject);
      }
    },
    changueWarehouse() {
      if (this.changueWarehouse === true) {
        this.getWarehouse();
      }
    },
    changuePriceList() {
      if (this.changuePriceList === true) {
        this.getAllPriceList();
      }
    },
    "globalDiscount.TotalPriceDiscountPercent"() {
      if (this.globalDiscount) {
        this.globalDiscount.TotalNewPrice = (
          (this.sumObject.total / 1.13) *
          (this.globalDiscount.TotalPriceDiscountPercent / 100)
        ).toFixed(8);
      }
    },
    productsToShow: {
      handler: function (oldValue, newValue) {
        if (newValue.nontaxable2 || oldValue.nontaxable2) {
          this.sumObject;
        }

        if (newValue.nonsuj2 || oldValue.nonsuj2) {
          this.sumObject;
        }
      },
      deep: true,
    },
  },
  methods: {
    searchSeveralCustomer() {
      const foundCustomer = this.customersList.find(
        (current) => current.IsSeveralCustomer === 1
      );
      if (foundCustomer) {
        this.customerSelected(
          this.customersList.find((current) => current.IsSeveralCustomer === 1)
        );

        this.form.invoicestatusdescription = "Cancelada"

      }
      else {
        this.showAlert("warning", "Información", "No hay clientes varios por defecto")
      }
    },
    async loadPriceListData(ID_Item) {
      this.loading = true;
      try {
        this.prices = await this.$API.priceList.getInfoPriceByProduct({
          ID_Item,
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    selectedPrice(item) {
      this.discountObject.PriceListName = item.PriceListName
      this.discountObject.ItemOriginalPrice = item.ItemOriginalPrice.toFixed(8)
      this.discountObject.ItemNewPrice = item.ItemNewPrice.toFixed(8)
      this.discountObject.ItemPriceDiscountAmount = item.ItemPriceDiscountAmount.toFixed(8)
    },
    verifyCarsem() {
      const keyMastersArray = JSON.parse(getCookie("ID_KeyMaster"));
      const specialKeyMasters =
        keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER1) ||
        keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER2) ||
        keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER3);
      console.log("", specialKeyMasters);
      if (specialKeyMasters) {
        this.isCarsem = true;
        this.key++;
      } else {
        this.isCarsem = false;
      }
    },
    handleSpace(product) {
      this.triggerAddItemEvent(product, 1);
      this.$nextTick(() => {
        this.$refs.plusIcon.focus(); // Reenfocar después de la acción
      });
    },
    handleSpaceService(product) {
      this.triggerAddItemEvent(product, 2);
      this.$nextTick(() => {
        this.$refs.plusIconService.focus(); // Reenfocar después de la acción
      });
    },

    handleKeyDown(event) {
      if (event.key === "ArrowDown") {
        this.selectFirstRowInGrid();
        event.preventDefault(); // Evita más acciones del evento
      }
    },
    selectFirstRowInGrid() {
      this.$nextTick(() => {
        if (this.$refs.dxDataGridInstance) {
          // Asumiendo que 'instance' es el objeto de grid expuesto por DevExtreme
          const gridInstance = this.$refs.dxDataGridInstance.instance;

          // Activar la primera fila o celda
          if (gridInstance) {
            gridInstance.focus(gridInstance.getCellElement(0, 0)); // Enfoca la primera celda
          }
        }
      });
    },
    handleEnterKey() {
      if (this.showProductsAndServices) {
        this.$nextTick(() => {
          this.$refs.generalFilter.focusInput(); // Llama al método de GeneralFilter
        });
      }
    },

    handleTypePaymentChange(data) {
      const selectedTypePayment = data.data.typePayment;
      const descriptionPayment = this.paymentTypes.find(
        (row) => row.Codigo == selectedTypePayment
      ).Valores;
      data.data.descriptionPayment = descriptionPayment;
    },
    async onInputDiscountObjs(label) {
      this.discountObject[label] &&
        (this.discountObject[label] = await onlyDigitsAndAPoint(
          this,
          this.discountObject[label]
        ));
    },
    onItemPriceDiscountPercentFocusBlur() {
      if (this.discountObject.ItemPriceDiscountPercent.length === 0) {
        this.discountObject.ItemPriceDiscountPercent = "0.00";
      } else if (this.discountObject.ItemPriceDiscountPercent === "0.00") {
        this.discountObject.ItemPriceDiscountPercent = "";
      }
      this.itemPriceDiscountPercentFocus = !this.itemPriceDiscountPercentFocus;
    },
    onItemPriceDiscountAmountFocusBlur() {
      if (this.discountObject.ItemPriceDiscountAmount.length === 0) {
        this.discountObject.ItemPriceDiscountAmount = "0.00";
      } else if (this.discountObject.ItemPriceDiscountAmount === "0.00") {
        this.discountObject.ItemPriceDiscountAmount = "";
      }
      this.itemPriceDiscountAmountFocus = !this.itemPriceDiscountAmountFocus;
    },
    handleRowChangedPayments() {
      let sum = 0;
      for (let i = 0; i < this.paymentsData.length; i++) {
        sum += parseFloat(
          parseFloat(this.paymentsData[i].totalPayment).toFixed(2)
        );
      }
      this.sumPayments = parseFloat(sum.toFixed(2));
    },
    deletePayment(data) {
      this.paymentsData = this.paymentsData.filter(
        (element) => element.typePayment !== data.typePayment
      );
    },
    updatePaymentsData(total) {
      if (this.header && this.header?.payments?.length > 0) {
        this.paymentsData = [];
        let sum = 0;
        for (let payment of this.header.payments) {
          this.paymentsData.push({
            typePayment: payment.TypePayment,
            descriptionPayment: payment.DescriptionTypePayment,
            totalPayment: payment.TotalPayment,
            reference: payment.Reference,
          });
          sum = sum + parseFloat(payment.TotalPayment);
        }
        this.sumPayments = parseFloat(sum.toFixed(2));
        this.header.payments = [];
      } else {
        if (this.paymentsData.length == 1) {
          this.paymentsData[0].totalPayment = parseFloat(total);
        }
      }

      //this.sumPayments = parseFloat(total)
    },
    addPaymentType() {
      for (let i = 0; i < this.paymentTypes.length; i++) {
        if (
          !this.paymentsData.find(
            (element) => element.typePayment === this.paymentTypes[i].Codigo
          )
        ) {
          let newPayment = {
            typePayment: this.paymentTypes[i].Codigo,
            descriptionPayment: this.paymentTypes[i].Valores,
            totalPayment: 0.0,
            reference: null,
          };
          this.paymentsData.push(newPayment);
          return;
        }
      }
    },
    numberWithCommas(x) {
      return numberWithCommas(x);
    },
    addElementFromSelector({ data, type }) {
      if (type === 1) {
        this.showProductFromSelector(data);
      } else if (type === 2) {
        this.showServiceFromSelector(data);
      }
    },
    openClasificationSelector() {
      if (this.isCustomerSelected) {
        this.showClasificationSelector = true;
      } else {
        this.showAlert(
          "danger",
          "Advertencia",
          "Debe seleccionar un cliente para agregar productos"
        );
      }
    },
    mostrarOcultarVendedor() {
      if (this.sellersList.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    // onCloseClasificationSelector( e ) {
    //   console.log( e );
    // },
    updateServProList(e) {
      this.ProductServList = e;
    },
    sellerSelectedFromDialog(data) {
      (this.searchSellerDialog = false), (this.form.ID_Seller = data.ID_Seller);
    },
    selectSellerHandler(e) {
      this.form.ID_Seller = e;
      // console.log(this.form.ID_Seller);
    },
    async getAllSellers() {
      try {
        const result = await this.$API.sellers.getAllSellers();
        // for( const index in result ) {
        //   const { FirstName, LastName } = result[ index ];
        //   result[ index ].itemText = `${ FirstName } ${ LastName }`;
        // }
        // // console.log('sellers ', result[0].FullName);
        this.sellersList = result;
        //alert(this.sellersList.length)
        /* if(this.sellersList.length > 0){
          this.togglehideSeller = true
        }*/
      } catch (error) {
        console.log("error en los sellers ", error);
      }
    },
    getBarCode(id) {
      this.loading = true;
      this.$API.inventoryitemsbarcodes
        .getBarCodeById(id, this.PriceListSelected, this.warehouseSelected)
        .then((data) => {
          this.barCodeToFind = data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
          if (
            this.barCodeToFind &&
            this.barCodeToFind.ID_Item &&
            typeof this.barCodeToFind.ID_Item === "string"
          ) {
            if (this.barCodeToFind.ID_Item.startsWith("PRO")) {
              this.showProductBarCode(this.barCodeToFind);
            } else if (this.barCodeToFind.ID_Item.startsWith("SERV")) {
              this.showServiceBarCode(this.barCodeToFind);
            } else {
              this.showAlert(
                "danger",
                "Advertencia",
                "Código de barra / alterno o no válido."
              );
            }
          } else {
            this.showAlert(
              "danger",
              "Advertencia",
              "Código de barra / alterno no válido."
            );
          }
          this.selectedBarCode = "";
        });
    },
    updateCustomersLIst(payload) {
      this.customersList = this.assignCustomersList(payload);
    },
    updateSellersList(payload) {
      this.sellersList = [...payload];
    },
    clearFilter() {
      this.productsFiltered = false;
    },
    filterHandler(response) {
      this.productsFiltered = true;
      this.productsToShowFiltered = response;
    },
    filterPromise(filterObj) {
      const filterArray = ["ID_Item", "ItemName", "qty", "ItemNewPrice"];
      return new Promise((resolve, reject) => {
        try {
          resolve(
            multipleCriteriaFilter(filterArray, filterObj, this.productsToShow)
          );
        } catch (error) {
          reject(error);
        }
      });
    },
    productsFilterHandler(response) {
      this.productsToBill = response;
      this.productToBillPrev = response;
      // console.log('response aca', response);
      this.loadpriceProductsOnFilter();
    },
    servicesFilterHandler(response) {
      this.servicesToBill = response;
    },
    headerFirstRowValidation() {
      // const defaultElements = 3;
      if (this.legalidInvoiceCondition()) {
        this.headerFirstRowDefaultElements++;
      } else {
        if (
          this.form.invoicepaymenttype != "Billetes y monedas" &&
          this.form.invoicepaymenttype != undefined
        ) {
          this.headerFirstRowDefaultElements++;
        }
      }
    },
    legalidInvoiceCondition() {
      return this.form.legalidinvoice != "No hay correlativo";
    },
    printPosFaComponent(options, DOM = document.body) {
      // console.log(options);
      const printPosFaInstance = new printPosFaConstructor({
        propsData: {
          dataProps: options,
        },
      });
      printPosFaInstance.vm = printPosFaInstance.$mount();
      const dom = DOM;
      dom.appendChild(printPosFaInstance.vm.$el);
      return printPosFaInstance.vm;
    },
    async getInfoBranch() {
      this.$API.branches.findSpecificBranch(this.search).then((res) => {
        const infobusiness = res[0];
        this.datatoprint.branch.AuthFeMode = infobusiness.AuthFeMode;
        this.datatoprint.branch.BusinessBillingNRC =
          infobusiness.BusinessBillingNRC;
        this.datatoprint.branch.BusinessBillingNIT =
          infobusiness.BusinessBillingNIT;
        this.datatoprint.branch.BusinessBillingName =
          infobusiness.BusinessBillingName;
        this.taxPayerSize = infobusiness.BusinessTaxPayerSize;
        this.BusinessLegalAgentRetain = infobusiness.LegalAgentRetain;
      });
    },
    async getDuiMask() {
      this.form.dui = await duiMask(this, this.form.dui);
    },
    async getNITMask(e) {
      if (e.which === 8) return false;
      this.form.legalnit = await nitMaskHandlingHomologation(
        this,
        this.form.legalnit
      );
    },
    async getNRCMask() {
      this.form.legalnrc = await nrcMask(this, this.form.legalnrc);
    },
    async copyText() {
      try {
        await navigator.clipboard.writeText(this.currenterrordata);
      } catch (error) {
        console.log(error);
      }
    },
    async handleStockAndQty(params) {
      try {
        const {
          value,
          data: { ItemCurrentQuantity, type },
        } = params;

        if (type != "service" && this.form.invoicetype != "DNC") {
          if (!this.EnableNegativeItem) {
            if (value > ItemCurrentQuantity) {
              const indexOf =  this.productsToShow.findIndex( current => current.ID_Item === params.data.ID_Item );
              this.productsToShow[indexOf].ErrorRow = true;
              return false;
            }
          }
        }

        const result = await this.$API.priceList.getTieredDiscountByProduct({
          ID_Item: params.data.ID_Item,
          PriceListName: params.data.PriceListName || this.PriceListSelected,
          value: value,
        });

        const indexOf =  this.productsToShow.findIndex( current => current.ID_Item === params.data.ID_Item );
        
        if (result.length > 0) {
          if( indexOf != -1 ) {
            this.productsToShow[indexOf].ItemOriginalPrice = result[ 0 ].ItemOriginalPrice;
            this.productsToShow[indexOf].ItemNewPrice = result[ 0 ].ItemOriginalPrice;
            this.productsToShow[indexOf].TmpItemNewPrice = result[ 0 ].ItemOriginalPrice;
            this.productsToShow[indexOf].ItemPriceDiscountAmount =
              result[0].ItemPriceDiscountAmount;
            this.productsToShow[indexOf].ItemPriceDiscountPercent =
              result[0].ItemPriceDiscountPercent;
            this.discountObject = this.productsToShow[indexOf];
            this.calculateItemNewPrice3(this.productsToShow[indexOf]);
          }
        }
        
        this.productsToShow[indexOf].ErrorRow = false;

        return true;
      } catch (error) {
        console.log(error);
        this.showAlert(
          "danger",
          "Error",
          `Ocurrio el siguiente error al intentar manejar el stock del producto ${error.message}`
        );
      }
    },
    async itemsQtyOnlyDigits(params) {
      try {
        const retorno = this.itemQtyPattern.test(params.value);
        // console.log(retorno);
        return retorno;
      } catch (error) {
        this.showAlert(
          "danger",
          "Error",
          `Ocurrio el siguiente error al intentar validar la cantidad del producto ${error.message}`
        );
      }
    },
    async getAllCostumers() {
      try {
        const params = {
          business: this.business,
          branch: this.branch,
        };

        const customers =
          await this.$API.customers.getCustomersPOSCreateInvoice(params);
        this.customersList = this.assignCustomersList(customers);
      } catch (error) {
        this.showAlert(
          "error",
          "Error!",
          `Ha ocurrido el siguiente error al intentar obtener todos los clientes de este negocio ${error.message}`
        );
      }
    },
    assignCustomersList(customers) {
      return customers.map((current) => {
        if (current.Is_a_business) {
          current.itemText = current.BusinessName;
        } else {
          current.itemText = current.LegalName;
        }
        return current;
      });
    },
    changueValueactivity() {
      this.form.descactividad = this.activities.find(
        (act) => act.Codigo == this.form.codactividad
      ).Valores;
    },
    addselector(e = null) {
      if (!this.isCustomerSelected) {
        this.productcode = null;
        this.showAlert(
          "danger",
          "Advertencia",
          "Debe seleccionar un cliente para agregar productos"
        );
        return;
      }

      if (e) {
        const item = this.ProductServList.find((el) => el.val == e);
        if (item) {
          //this.productcode = item;
          if (item.val.startsWith("SERV")) {
            //   this.productcode.Name = item.prod.NameService
            this.showServiceByfilter(item);
            return;
          }
          if (item.val.startsWith("PRO")) {
            // this.productcode.Name = item.prod.ItemName
            this.showProductByfilter(item);
            return;
          }
        }
      }

      if (this.productcode == null) return;
      if (this.productcode == "") return;
      if (this.productcode.type == "product" && e == null) {
        this.showProduct(this.productcode);
      } else if (this.productcode.type == "service" && e == null) {
        this.showService(this.productcode);
      }
    },
    addBarCode() {
      if (this.selectedBarCode === ".C0ntraseniaP@ssw0rdInn0v@Intern@-23") {
        this.showTimesExecute = true;
      } else {
        if (!this.isCustomerSelected) {
          this.selectedBarCode = null;
          const delay = (ms) =>
            new Promise((resolve) => setTimeout(resolve, ms));
          delay(500).then(() => {
            this.showAlert(
              "danger",
              "Advertencia",
              "Debe seleccionar un cliente para agregar productos"
            );
          });
          return;
        }

        if (this.selectedBarCode == null) return;
        if (this.selectedBarCode == "") return;

        this.getBarCode(this.selectedBarCode);
      }
    },
    async getPriceListSelected() {
      this.loading = true;
      try {
        let params = {
          business:
            JSON.parse(localStorage.getItem("user")).businessid != undefined
              ? JSON.parse(localStorage.getItem("user")).businessid
              : 0,
          branch:
            JSON.parse(localStorage.getItem("user")).branch != undefined
              ? JSON.parse(localStorage.getItem("user")).branch
              : 0,
          PriceListName: this.PriceListSelected,
        };
        const response = await this.$API.priceList.itemsFromList(params);

        this.pricelistDetail = response.map((i) => {
          return {
            ID_Item: i.ID_Item,
            ItemName: i.ItemName,
            ItemOriginalPrice: i.ItemOriginalPrice,
            ItemPriceDiscountPercent: i.ItemPriceDiscountPercent,
            ItemPriceDiscountAmount: i.ItemPriceDiscountAmount,
            ItemNewPrice: i.ItemNewPrice,
            TmpItemNewPrice: parseFloat(
              parseFloat(i.ItemNewPrice / 1.13).toFixed(8)
            ),
            TmpCounter: 0,
            ItemCurrency: i.ItemCurrency,
            ID_Branch: i.ID_Branch,
            IsGlobalBranch: i.IsGlobalBranch,
            ID_Business: i.ID_Business,
            InitialRange: i.InitialRange,
            FinalRange: i.FinalRange,
          };
        });

        this.filterServices.PriceListSelected = this.PriceListSelected;

        const responseServices =
          await this.$API.services.getAllServicesWithPriceList(
            this.filterServices
          );
        this.servicesToBill = responseServices;
        this.servicesToBillSearchTab = responseServices;

        this.reactiveMainProductsFilter();

        if (this.DynamicPriceLists) {
          await this.dynamicPricelistBehavior(response, responseServices);
        }
      } catch (error) {
        console.log(error);
        this.showAlert(
          "warning",
          "Advertencia",
          `Ocurrió lo siguiente al cambiar de lista de precios`
        );
      } finally {
        this.loading = false;
      }
    },
    async dynamicPricelistBehavior(response, responseServices) {
      const finderConfig = {
        product: (ID_Item) => {
          return response.find((current) => {
            const { ID_Item: ID_ItemCurrent } = current;
            return ID_ItemCurrent === ID_Item;
          });
        },
        service: (ID_Item) => {
          return responseServices.find((current) => {
            const { CodeService: ID_ItemCurrent } = current;
            return ID_ItemCurrent === ID_Item;
          });
        },
      };

      for (const index in this.productsToShow) {
        const { ID_Item, type } = this.productsToShow[index];

        const currentResponseObj = finderConfig[type](ID_Item);

        if (currentResponseObj) {
          console.log( 'currentResponseObj ', currentResponseObj );
          const {
            ItemOriginalPrice,
            ItemNewPrice,
            ItemPriceDiscountAmount,
            ItemPriceDiscountPercent,
            PriceListName,
          } = currentResponseObj;
          
          this.productsToShow[index].PriceListName = PriceListName;
          this.productsToShow[index].ItemOriginalPrice = ItemOriginalPrice;
          this.productsToShow[index].ItemPriceDiscountAmount =
            ItemPriceDiscountAmount;
          this.productsToShow[index].ItemPriceDiscountPercent =
            ItemPriceDiscountPercent;

          if (ItemPriceDiscountAmount > 0) {
            if (
              this.form.invoicetype != "CCF" &&
              this.form.invoicetype != "DNC" &&
              this.form.invoicetype != "DND"
            ) {
              this.productsToShow[index].TmpItemNewPrice = parseFloat(
                parseFloat(
                  (ItemOriginalPrice - ItemPriceDiscountAmount) / 1.13
                ).toFixed(8)
              );
              this.productsToShow[index].ItemNewPrice = parseFloat(
                parseFloat(ItemOriginalPrice - ItemPriceDiscountAmount).toFixed(
                  4
                )
              );
            } else {
              this.productsToShow[index].TmpItemNewPrice = parseFloat(
                parseFloat(
                  (ItemOriginalPrice - ItemPriceDiscountAmount) / 1.13
                ).toFixed(8)
              );
              this.productsToShow[index].ItemNewPrice = parseFloat(
                parseFloat(ItemOriginalPrice - ItemPriceDiscountAmount).toFixed(
                  4
                )
              );
            }
          } else {
            this.productsToShow[index].ItemNewPrice = ItemNewPrice;
            this.productsToShow[index].TmpItemNewPrice = parseFloat(
              parseFloat(ItemOriginalPrice / 1.13).toFixed(8)
            );
          }

          this.productsToShow[index].TmpCounter = 0;
        } else {
          // console.log( 'this.priceListData ', this.priceListData.find( current => current.Active === 1 ), this.PriceListSelected )
          let params = {
            business:
              JSON.parse(localStorage.getItem("user")).businessid != undefined
                ? JSON.parse(localStorage.getItem("user")).businessid
                : 0,
            branch:
              JSON.parse(localStorage.getItem("user")).branch != undefined
                ? JSON.parse(localStorage.getItem("user")).branch
                : 0,
            PriceListName: this.priceListData.find( current => current.Active === 1 ).id,
          };

          const newResponseProducts = await this.$API.priceList.itemsFromList(params);

          const newResponseServices = await this.$API.services.getAllServicesWithPriceList( { ...this.filterServices, PriceListSelected: this.priceListData.find( current => current.Active === 1 ).id } );

          await this.dynamicPricelistBehavior( newResponseProducts, newResponseServices );
        }
      }
    },
    getAllPriceList() {
      this.$API.priceList.priceListNoData().then((response) => {
        this.priceListData = response;
        let found = this.priceListData.find(
          (pl) =>
            pl.Active == true &&
            pl.ID_Branch == JSON.parse(localStorage.getItem("user")).branch
        );
        if (found) {
          this.PriceListSelected = found.id;
          this.defaultPriceList = this.PriceListSelected;
        } else {
          this.PriceListSelected = this.priceListData.find(
            (pl) => pl.id == "Precios por defecto"
          ).id;
          this.defaultPriceList = this.PriceListSelected;
        }

        this.$store.commit("setChanguePriceList", false);
      });
    },
    getWarehouse() {
      let idbusiness =
        JSON.parse(localStorage.getItem("user")).businessid || "";
      let idbranch = JSON.parse(localStorage.getItem("user")).branch || "";
      this.filterWarehouses.branch = idbranch;
      this.filterWarehouses.business = idbusiness;
      this.$API.warehouse
        .getWarehouses(this.filterWarehouses)
        .then((response) => {
          this.warehouses = response;
          // console.log( 'warehouses ', this.warehouses );
          const found = this.warehouses.find(
            (wh) => wh.Active == true && wh.ID_Branch == idbranch
          );
          if (found) {
            this.warehouseSelected = found.ID_Warehouse;
          } else {
            this.warehouseSelected = this.warehouses[0].ID_Warehouse;
          }
          this.$store.commit("setChangueWarehouse", false);
        })
        .catch((error) => console.log(error));
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deleteoneproduct") {
        this.removeRow(this.confirmDelete);
      }
      if (this.alert.options == "showErrorDetails") {
        this.showDetailsMessages = true;
      }
    },
    async preloadCustomer() {
      await Object.keys(this.customer).map((el) => {
        Vue.set(
          this.form,
          el.replace("_", "").toLowerCase(),
          this.customer[el]
        );
      });

      this.isCustomerSelected = true;
    },

    closeAlert() {
      this.alert.show = false;

      if (this.alert.options == "deleteoneproduct") {
        this.confirmDelete = null;
      }

      if (this.alert.options == "update") {
        this.clearForm();
        this.$store.commit("posData/changeView", "tab-3");
        this.$store.commit("posData/setReloaders", "reloadBill");
        this.$store.commit("posData/setReloaders", "reloadHistory");
        this.$store.commit("posData/setReloaders", "reloadTemplateBill");
        this.$store.commit("posData/setReloaders", "reloadQuotesBill");
      }

      if (this.alert.options == "updateTemplate") {
        this.clearForm();
        this.$store.commit("posData/changeView", "tab-4");
        this.$store.commit("posData/setReloaders", "reloadBill");
        this.$store.commit("posData/setReloaders", "reloadHistory");
        this.$store.commit("posData/setReloaders", "reloadTemplateBill");
        this.$store.commit("posData/setReloaders", "reloadQuotesBill");
      }

      if (this.alert.options == "updateQuote") {
        this.clearForm();
        this.$store.commit("posData/changeView", "tab-5");
        this.$store.commit("posData/setReloaders", "reloadBill");
        this.$store.commit("posData/setReloaders", "reloadHistory");
        this.$store.commit("posData/setReloaders", "reloadTemplateBill");
        this.$store.commit("posData/setReloaders", "reloadQuotesBill");
      }

      if (this.alert.options == "updateFinish") {
        this.clearForm();
        this.$store.commit("posData/changeView", "tab-2");
        this.$store.commit("posData/setReloaders", "reloadBill");
        this.$store.commit("posData/setReloaders", "reloadHistory");
        this.$store.commit("posData/setReloaders", "reloadTemplateBill");
        this.$store.commit("posData/setReloaders", "reloadQuotesBill");
      }
  
      if (this.alert.options == "updateFinishAndPrintInvoice") {
        if (
          JSON.parse(localStorage.getItem("user")).ticketformatfcf &&
          JSON.parse(localStorage.getItem("user")).TipoPlan != "Emprendedor" &&
          JSON.parse(localStorage.getItem("user")).TipoPlan != "Ecoanual" &&
          (this.form.invoicetype == "FCF" || this.form.invoicetype == "CCF")
        ) {
          this.print3 = {
            show: true,
            data: { ...this.datatoprint },
          };
          this.print = {
            show: true,
            data: { ...this.datatoprint },
          };
          this.clearFormForPrinting();
        } else {
          if (this.form.invoicetype == "TCKS") {
            this.print2 = {
              show: true,
              data: { ...this.datatoprint },
            };
            this.clearFormForPrinting();
          } else {
            this.print = {
              show: true,
              data: { ...this.datatoprint },
            };
            this.clearFormForPrinting();
          }
        }

        // this.clearForm();
        // this.printPosFaComponent({...this.print});
      }

      if (this.alert.options == "showErrorDetails") {
        this.clearForm();
        this.$store.commit("posData/setReloaders", "reloadBill");
        this.$store.commit("posData/setReloaders", "reloadHistory");
        this.$store.commit("posData/setReloaders", "reloadTemplateBill");
        this.$store.commit("posData/setReloaders", "reloadQuotesBill");
      }

      if (this.alert.options == "cancelTimesExecute") {
        this.cancelTimesExecute = true;
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    getInvoiceId() {
      let sptype = "GETCORRELATIVE";

      return new Observable((observer) => {
        this.$API.invoices
          .getInvoiceID({
            sptype: sptype,
            invoicetype: this.form.invoicetype,
            business: this.business,
            branch: this.branch,
          })
          .then((data) => {
            this.form.legalidinvoice = data.id;
            this.form.idinvoice = data.ID_Invoice;
            observer.next(data.id);
            observer.complete();
          })
          .catch((data) => {
            this.form.legalidinvoice = "No hay correlativo";
            this.form.idinvoice = data.ID_Invoice;
          });
      });
    },
    clearForm() {
      this.$store.commit("posData/setHeader", null);
      this.$store.commit("posData/setBody", null);
      this.$store.commit("posData/setCustomer", null);
      this.$store.commit("posData/setInvalid", null);
      this.$store.commit("posData/setNDorNC", null);
      this.$store.commit("posData/setQuote", null);
      this.$store.commit("posData/setContingency", null);
      this.$store.commit("posData/setReloaders", "reloadCreate");
    },
    clearFormForPrinting() {
      this.$store.commit("posData/setHeader", null);
      this.$store.commit("posData/setBody", null);
      this.$store.commit("posData/setCustomer", null);
      this.$store.commit("posData/setInvalid", null);
      this.$store.commit("posData/setNDorNC", null);
      this.$store.commit("posData/setQuote", null);
      this.$store.commit("posData/setContingency", null);

      this.workingIn = "";

      const newForm = {
        invoicedate: this.form.invoicedate,
        invoicetype: "FCF",
        idinvoice: "",
        legalidinvoice: "No hay correlativo",
        legalisnontaxable: 0,
        legalisnonsuj: 0,
        istemplate: 0,
        isquote: 0,
        timesexecute: 0,
        idbusiness: JSON.parse(localStorage.getItem("user")).branch,
        idbranch: JSON.parse(localStorage.getItem("user")).businessid,
        flete: 0,
        seguro: 0,
        invoiceinternalnote: "",
      };

      this.productsToShow = [];

      this.paymentsData = [
        {
          typePayment: "01",
          descriptionPayment: "Billetes y monedas",
          totalPayment: 0.0,
          reference: null,
        },
        {
          typePayment: "02",
          descriptionPayment: "Tarjeta Débito",
          totalPayment: 0.0,
          reference: null,
        },
        {
          typePayment: "03",
          descriptionPayment: "Tarjeta Crédito",
          totalPayment: 0.0,
          reference: null,
        },
        {
          typePayment: "04",
          descriptionPayment: "Cheque",
          totalPayment: 0.0,
          reference: null,
        },
        {
          typePayment: "05",
          descriptionPayment: "Transferencia_ Depósito Bancario",
          totalPayment: 0.0,
          reference: null,
        },
      ];

      this.sumPayments = 0.0;

      this.branch = JSON.parse(localStorage.getItem("user")).branch;
      this.business = JSON.parse(localStorage.getItem("user")).businessid;
      this.search.branch = this.branch;
      this.search.business = this.business;
      this.getInfoBranch();
      newForm.idbusiness = this.business;
      newForm.idbranch = this.branch;
      this.$refs.posForm.$children[1].reset();
      this.$refs.posForm.$children[2].reset();

      this.form = { ...newForm };

      this.getInvoiceId().subscribe();

      this.form.invoicetype = "FCF";

      this.productcode = "";

      delete this.form.invoicepaymenttype;
      delete this.form.invoicestatusdescription;
      this.isCustomerSelected = false;
    },
    async getProducts() {
      let idsArray = null;
      let idsWarehouse = null;
      let idsBatch = null;
      let isBatchNull = false;
      if (this.header && this.body && this.stopIn == false) {
        let i = 0;
        for (let item of this.body) {
          if (i == 0) {
            idsArray = `'${item.ID_Item}'`;
            idsWarehouse = `'${item.Warehouse}'`;

            if (item.ID_ItemBatch == "" || item.ID_ItemBatch == null) {
              isBatchNull = true;
            } else {
              idsBatch = `'${item.ID_ItemBatch}'`;
            }
          } else {
            idsArray = idsArray + `,'${item.ID_Item}'`;
            idsWarehouse = idsWarehouse + `,'${item.Warehouse}'`;

            if (item.ID_ItemBatch == "" || item.ID_ItemBatch == null) {
              isBatchNull = true;
            } else {
              idsBatch = idsBatch + `,'${item.ID_ItemBatch}'`;
            }
          }

          i++;
        }
      }
      this.filterProducts.idsArray = idsArray;
      this.filterProducts.idsWarehouse = idsWarehouse;
      this.filterProducts.idsBatch = idsBatch;
      this.filterProducts.isBatchNull = isBatchNull;
      const response = await this.$API.products.getProductsToBillForInvoice(
        this.filterProducts
      );
      this.productsToBillSearchTab = response;
      if (this.warehouseSelected) {
        const filteredProducts = this.productsToBillSearchTab.filter(
          (element) => element.Warehouse === this.warehouseSelected
        );

        filteredProducts.forEach((element) => {
          this.ProductServList.push({
            ID: `${element.ItemName}${
              element.ItemComercialName ? " | " + element.ItemComercialName : ""
            }`,
            val: element.ID_Item,
            prod: { ...element, type: "product" },
          });
        });
        this.assignedList.prod = true;
      } else {
        this.productsToBillSearchTab.forEach((element) => {
          this.ProductServList.push({
            ID: `${element.ItemName}${
              element.ItemComercialName ? " | " + element.ItemComercialName : ""
            }`,
            val: element.ID_Item,
            prod: { ...element, type: "product" },
          });
        });
        this.assignedList.prod = true;
      }

      this.productsToBillDefault = response;
      this.productsToBill = response;
      this.productsToBillForSelector = response;
      this.productToBillPrev = response;
      //   .then((response) => {
      // return new Observable((observer) => {

      //       // console.log('response aca ', this.productsToBill, this.warehouseSelected)
      //       observer.next(response);
      //       observer.complete();
      //     });
      // });
    },

    getProductsOnFilter() {
      this.$API.products
        .getProductsToBillForInvoice(this.filterProducts)
        .then((response) => {
          this.productsToBillDefault = [...response];
          this.productsToBill = response;
          this.productToBillPrev = response;
          this.loadpriceProductsOnFilter();
        });
    },
    getServicesOnFilter() {
      this.$API.services
        .getAllServicesWithPriceList(this.filterServices)
        .then((response) => {
          this.servicesToBill = response;
        });
    },
    async getServices() {
      try {
        let idsArray = null;
        if (this.header && this.body && this.stopIn == false) {
          let i = 0;
          for (let item of this.body) {
            if (i == 0) {
              idsArray = `'${item.ID_Item}'`;
            } else {
              idsArray = idsArray + `,'${item.ID_Item}'`;
            }

            i++;
          }
        }

        this.filterServices.idsArray = idsArray;

        this.filterServices.PriceListSelected = this.PriceListSelected;
        const response = await this.$API.services.getAllServicesWithPriceList(
          this.filterServices
        );
        this.servicesToBill = response;
        this.servicesToBillForSelector = response;
        this.servicesToBillSearchTab = response;
        if (this.servicesToBillSearchTab.length > 0) {
          this.servicesToBillSearchTab.forEach((element) => {
            this.ProductServList.push({
              ID:
                element.NameService +
                (element.Name ? " | " + element.Name : ""),
              val: element.CodeService,
              prod: { ...element, type: "service" },
            });
          });
          this.assignedList.serv = true;
        }
      } catch (error) {
        this.assignedList.serv = true;
      }
      // .then((response) => {
      // });
    },

    onEditorChange(e) {
      this.form.invoiceinternalnote = e.html;
      if (this.form.invoiceinternalnote != "") {
        this.showBgcolorNotes = "tabsContent";
      } else if (
        this.form.invoiceinternalnote == "" ||
        this.form.invoiceinternalnote == "<p><br></p>"
      ) {
        this.showBgcolorNotes = "tabs";
      } else {
        this.showBgcolorNotes = "tabs";
      }
    },

    async showProduct(data) {
      if (this.isCustomerSelected) {
        if (
          !this.productsToShow.find(
            (i) =>
              i.ID_Item == data.ID_Item &&
              this.allowRepetitionOfArticles == false
          )
        ) {
          const qtyToAdd =
            this.POSProductsAlternativeBehavior && data?.qty && data?.qty > 0
              ? parseFloat(data.qty)
              : 1;
          if (data.ItemCurrentQuantity > 0 || this.EnableNegativeItem == 1) {
            const uuid = generateUUID();
            this.productsToShow.push({
              ...data,
              ItemName: data.ItemComercialName || data.ItemName,
              qty: qtyToAdd,
              type: "product",
              nontaxable:
                this.form.legalisexempt && this.form.invoicetype != "FEX"
                  ? 1
                  : this.form.invoicetype != "FEX"
                  ? data.IsExempt
                  : 0,
              nonsuj:
                data.IsExempt || this.form.invoicetype == "FEX"
                  ? 0
                  : this.form.legalisnontaxable,
              TmpItemNewPrice: parseFloat(
                parseFloat(data.ItemOriginalPrice / 1.13).toFixed(8)
              ),
              TmpCounter: 0,
              uuid: uuid,
            });
            
            if (!this.header && !this.body) {
              const result = await this.$API.priceList.getTieredDiscountByProduct({
                ID_Item: data.ID_Item,
                PriceListName: data.PriceListName || this.PriceListSelected,
                value: qtyToAdd,
              });

              const indexOf =  this.productsToShow.findIndex( current => current.ID_Item === data.ID_Item );
          
              if (result.length > 0) {
                if( indexOf != -1 ) {
                  this.productsToShow[indexOf].ItemOriginalPrice = result[ 0 ].ItemOriginalPrice;
                  this.productsToShow[indexOf].ItemNewPrice = result[ 0 ].ItemOriginalPrice;
                  this.productsToShow[indexOf].TmpItemNewPrice = result[ 0 ].ItemOriginalPrice;
                  this.productsToShow[indexOf].ItemPriceDiscountAmount =
                    result[0].ItemPriceDiscountAmount;
                  this.productsToShow[indexOf].ItemPriceDiscountPercent =
                    result[0].ItemPriceDiscountPercent;
                  this.discountObject = this.productsToShow[indexOf];
                  this.calculateItemNewPrice3(this.productsToShow[indexOf]);
                }
              }
            }

          } else {
            if (this.showProductsAndServices) {
              this.showProductsAndServices = !this.showProductsAndServices;
            }
            if (this.form.invoicetype == "DNC") {
              const uuid = generateUUID();
              this.productsToShow.push({
                ...data,
                ItemName: data.ItemComercialName || data.ItemName,
                qty: qtyToAdd,
                type: "product",
                nontaxable:
                  this.form.legalisexempt && this.form.invoicetype != "FEX"
                    ? 1
                    : this.form.invoicetype != "FEX"
                    ? data.IsExempt
                    : 0,
                nonsuj:
                  data.IsExempt || this.form.invoicetype == "FEX"
                    ? 0
                    : this.form.legalisnontaxable,
                TmpItemNewPrice: parseFloat(
                  parseFloat(data.ItemOriginalPrice / 1.13).toFixed(8)
                ),
                TmpCounter: 0,
                uuid: uuid,
              });
            } else {
              this.showAlert(
                "danger",
                "Advertencia",
                "No hay unidades disponibles de este producto"
              );
            }
          }
        }
      } else {
        this.showProductsAndServices = !this.showProductsAndServices;
        this.showAlert(
          "danger",
          "Advertencia",
          "Debe seleccionar un cliente para agregar productos"
        );
      }
    },
    showProductFromSelector(data) {
      if (this.isCustomerSelected) {
        if (
          !this.productsToShow.find(
            (i) =>
              i.ID_Item == data.ID_Item &&
              this.allowRepetitionOfArticles == false
          )
        ) {
          if (data.ItemCurrentQuantity > 0 || this.EnableNegativeItem == 1) {
            const uuid = generateUUID();
            this.productsToShow.push({
              ...data,
              ItemName: data.ItemComercialName || data.ItemName,
              type: "product",
              nontaxable:
                this.form.legalisexempt && this.form.invoicetype != "FEX"
                  ? 1
                  : this.form.invoicetype != "FEX"
                  ? data.IsExempt
                  : 0,
              nonsuj:
                data.IsExempt || this.form.invoicetype == "FEX"
                  ? 0
                  : this.form.legalisnontaxable,
              TmpItemNewPrice: parseFloat(
                parseFloat(data.ItemOriginalPrice / 1.13).toFixed(8)
              ),
              TmpCounter: 0,
              uuid: uuid,
            });
          } else {
            if (this.showProductsAndServices) {
              this.showProductsAndServices = !this.showProductsAndServices;
            }

            this.showAlert(
              "danger",
              "Advertencia",
              "No hay unidades disponibles de este producto"
            );
          }
        }
      } else {
        this.showProductsAndServices = !this.showProductsAndServices;
        this.showAlert(
          "danger",
          "Advertencia",
          "Debe seleccionar un cliente para agregar productos"
        );
      }
    },

    async showProductBarCode(data) {
      const { PrincipalBarCodeValue } = data;
      if (this.isCustomerSelected) {
        if (
          !this.productsToShow.find((i) => {
            return (
              i.ID_Item == data.ID_Item &&
              this.allowRepetitionOfArticles == false
            );
          })
        ) {
          if (data.ItemCurrentQuantity > 0 || this.EnableNegativeItem == 1) {
            const uuid = generateUUID();
            this.productsToShow.push({
              ...data,
              BarCode:
                PrincipalBarCodeValue || PrincipalBarCodeValue?.length > 0
                  ? PrincipalBarCodeValue
                  : null,
              ItemName: data.ItemComercialName || data.ItemName,
              qty: 1,
              type: "product",
              nontaxable:
                this.form.legalisexempt && this.form.invoicetype != "FEX"
                  ? 1
                  : this.form.invoicetype != "FEX"
                  ? data.IsExempt
                  : 0,
              nonsuj:
                data.IsExempt || this.form.invoicetype == "FEX"
                  ? 0
                  : this.form.legalisnontaxable,
              TmpItemNewPrice: parseFloat(
                parseFloat(data.ItemOriginalPrice / 1.13).toFixed(8)
              ),
              TmpCounter: 0,
              uuid: uuid,
            });
          } else {
            if (this.showProductsAndServices) {
              this.showProductsAndServices = !this.showProductsAndServices;
            }

            this.showAlert(
              "danger",
              "Advertencia",
              "No hay unidades disponibles de este producto"
            );
          }
        } else {
          for (const index in this.productsToShow) {
            const { ID_Item } = this.productsToShow[index];

            if (ID_Item == data.ID_Item) {
              this.productsToShow[index].qty++;

              const result = await this.$API.priceList.getTieredDiscountByProduct({
                ID_Item: data.ID_Item,
                PriceListName: data.PriceListName || this.PriceListSelected,
                value: this.productsToShow[index].qty,
              });

              const indexOf =  this.productsToShow.findIndex( current => current.ID_Item === data.ID_Item );
            
              if (result.length > 0) {
                if( indexOf != -1 ) {
                  this.productsToShow[indexOf].ItemOriginalPrice = result[ 0 ].ItemOriginalPrice;
                  this.productsToShow[indexOf].ItemNewPrice = result[ 0 ].ItemOriginalPrice;
                  this.productsToShow[indexOf].TmpItemNewPrice = result[ 0 ].ItemOriginalPrice;
                  this.productsToShow[indexOf].ItemPriceDiscountAmount =
                    result[0].ItemPriceDiscountAmount;
                  this.productsToShow[indexOf].ItemPriceDiscountPercent =
                    result[0].ItemPriceDiscountPercent;
                  this.discountObject = this.productsToShow[indexOf];
                  this.calculateItemNewPrice3(this.productsToShow[indexOf]);
                }
              }

              break;
            }
          }
        }
      } else {
        this.showProductsAndServices = !this.showProductsAndServices;
        this.showAlert(
          "danger",
          "Advertencia",
          "Debe seleccionar un cliente para agregar productos"
        );
      }
    },
    quantityInput(data, type) {
      this.currentItemToAdd = { ...data };
      this.currentItemType = type;
      this.showQuantityDialog = true;
    },
    triggerAddItemEvent(data, type) {
      if (this.POSProductsAlternativeBehavior) {
        this.quantityInput(data, type);
      } else {
        if (type == 2) {
          this.showService(data);
        } else {
          this.showProduct(data);
        }
      }
    },
    submitQuantityInput(data) {
      if (this.currentItemType === 1) {
        this.showProduct(data);
      } else if (this.currentItemType === 2) {
        this.showService(data);
      }
      this.clearQuantityInputValues();
      this.showProductsAndServices = false;
    },
    clearQuantityInputValues() {
      this.currentItemToAdd = null;
      this.currentItemType = 0;
      this.showQuantityDialog = true;
    },
    showService(data) {
      if (this.isCustomerSelected) {
        if (!this.productsToShow.find((i) => i.ID_Item == data.CodeService)) {
          const qtyToAdd =
            this.POSProductsAlternativeBehavior && data?.qty && data?.qty > 0
              ? parseFloat(data.qty)
              : 1;
          // console.log('tipo del precio ', typeof data.Price);
          this.productsToShow.push({
            ID_Branch: data.ID_Branch,
            ID_Business: data.ID_Business,
            ID_Item: data.CodeService,
            ItemName: data.Name || data.NameService,
            ItemName2: data.NameService || data.Name,
            discountFixed: 0,
            ItemNewPrice: parseFloat(data.ItemNewPrice),
            TmpItemNewPrice: parseFloat(
              parseFloat(data.ItemOriginalPrice / 1.13).toFixed(8)
            ), //parseFloat(parseFloat(data.Price / 1.13).toFixed(8)),
            TmpCounter: 0,
            ItemOriginalPrice: parseFloat(data.ItemOriginalPrice),
            qty: qtyToAdd,
            type: "service",
            ItemType: "SERVICIO",
            serviceType: data.TypeService || "",
            idServiceType: data.ID_TypeService || "",
            ItemCurrency: "$",
            ItemMeasureUnit: "",
            nonsuj:
              this.form.invoicetype != "FEX" ? this.form.legalisnontaxable : 0,
            PriceListName: data.PriceListName,
            ItemPriceDiscountAmount: data.ItemPriceDiscountAmount,
            ItemPriceDiscountPercent: data.ItemPriceDiscountPercent,
          });
        }
      } else if (this.newCostumer == true) {
        // console.log("entro")
      } else {
        this.showProductsAndServices = !this.showProductsAndServices;
        this.showAlert(
          "warning",
          "Advertencia",
          "Por favor seleccione un cliente para facturar el servicio"
        );
      }
    },
    showServiceFromSelector(data) {
      if (this.isCustomerSelected) {
        if (!this.productsToShow.find((i) => i.ID_Item == data.CodeService)) {
          // console.log('tipo del precio ', typeof data.Price);
          this.productsToShow.push({
            ID_Branch: data.ID_Branch,
            ID_Business: data.ID_Business,
            ID_Item: data.CodeService,
            ItemName: data.Name || data.NameService,
            ItemName2: data.NameService || data.Name,
            discountFixed: 0,
            ItemNewPrice: parseFloat(data.ItemNewPrice),
            TmpItemNewPrice: parseFloat(
              parseFloat(data.ItemOriginalPrice / 1.13).toFixed(8)
            ), //parseFloat(parseFloat(data.Price / 1.13).toFixed(8)),
            TmpCounter: 0,
            ItemOriginalPrice: parseFloat(data.Price),
            qty: data.qty,
            type: "service",
            ItemType: "SERVICIO",
            serviceType: data.TypeService || "",
            idServiceType: data.ID_TypeService || "",
            ItemCurrency: "$",
            ItemMeasureUnit: "",
            nonsuj:
              this.form.invoicetype != "FEX" ? this.form.legalisnontaxable : 0,
            PriceListName: data.PriceListName,
            ItemPriceDiscountAmount: data.ItemPriceDiscountAmount,
            ItemPriceDiscountPercent: data.ItemPriceDiscountPercent,
          });
        }
      } else if (this.newCostumer == true) {
        // console.log("entro")
      } else {
        this.showProductsAndServices = !this.showProductsAndServices;
        this.showAlert(
          "warning",
          "Advertencia",
          "Por favor seleccione un cliente para facturar el servicio"
        );
      }
    },
    showProductByfilter(val) {
      const data = val.prod;
      if (this.isCustomerSelected) {
        if (
          !this.productsToShow.find(
            (i) =>
              i.ID_Item == data.ID_Item &&
              this.allowRepetitionOfArticles == false
          )
        ) {
          if (data.ItemCurrentQuantity > 0 || this.EnableNegativeItem == 1) {
            const uuid = generateUUID();
            this.productsToShow.push({
              ...data,
              ItemName: data.ItemComercialName || data.ItemName,
              qty: 1,
              type: "product",
              nontaxable:
                this.form.legalisexempt && this.form.invoicetype != "FEX"
                  ? 1
                  : this.form.invoicetype != "FEX"
                  ? data.IsExempt
                  : 0,
              nonsuj:
                data.IsExempt || this.form.invoicetype == "FEX"
                  ? 0
                  : this.form.legalisnontaxable,
              TmpItemNewPrice: parseFloat(
                parseFloat(data.ItemOriginalPrice / 1.13).toFixed(8)
              ),
              TmpCounter: 0,
              uuid: uuid,
            });
          } else {
            if (this.showProductsAndServices) {
              this.showProductsAndServices = !this.showProductsAndServices;
            }

            this.showAlert(
              "danger",
              "Advertencia",
              "No hay unidades disponibles de este producto"
            );
          }
        }
      } else {
        this.showProductsAndServices = !this.showProductsAndServices;
        this.showAlert(
          "danger",
          "Advertencia",
          "Debe seleccionar un cliente para agregar productos"
        );
      }
    },
    showServiceByfilter(val) {
      console.log("val ", val);
      const data = val.prod;
      if (this.isCustomerSelected) {
        if (!this.productsToShow.find((i) => i.ID_Item == data.CodeService)) {
          // console.log('tipo del precio ', typeof data.Price);
          this.productsToShow.push({
            ID_Branch: data.ID_Branch,
            ID_Business: data.ID_Business,
            ID_Item: data.CodeService,
            ItemName: data.Name || data.NameService,
            ItemName2: data.NameService || data.Name,
            discountFixed: 0,
            ItemNewPrice: parseFloat(data.ItemNewPrice),
            TmpItemNewPrice: parseFloat(
              parseFloat(data.ItemOriginalPrice / 1.13).toFixed(8)
            ), //parseFloat(parseFloat(data.Price / 1.13).toFixed(8)),
            TmpCounter: 0,
            ItemOriginalPrice: parseFloat(data.ItemOriginalPrice),
            qty: 1,
            type: "service",
            ItemType: "SERVICIO",
            serviceType: data.TypeService || "",
            idServiceType: data.ID_TypeService || "",
            ItemCurrency: "$",
            ItemMeasureUnit: "",
            nonsuj:
              this.form.invoicetype != "FEX" ? this.form.legalisnontaxable : 0,
            PriceListName: data.PriceListName,
            ItemPriceDiscountAmount: data.ItemPriceDiscountAmount,
            ItemPriceDiscountPercent: data.ItemPriceDiscountPercent,
          });
        }
        this.productcode = null;
      } else if (this.newCostumer == true) {
        this.productcode = null;
      } else {
        this.showProductsAndServices = !this.showProductsAndServices;
        this.showAlert(
          "warning",
          "Advertencia",
          "Por favor seleccione un cliente para facturar el servicio"
        );
      }
    },
    showServiceBarCode(data) {
      const { PrincipalBarCodeValue } = data;
      if (this.isCustomerSelected) {
        if (!this.productsToShow.find((i) => i.ID_Item == data.CodeService)) {
          this.productsToShow.push({
            ID_Branch: data.ID_Branch,
            ID_Business: data.ID_Business,
            ID_Item: data.CodeService,
            ItemName: data.Name || data.NameService,
            ItemName2: data.NameService || data.Name,
            discountFixed: 0,
            ItemNewPrice: parseFloat(data.ItemNewPrice),
            TmpItemNewPrice: parseFloat(
              parseFloat(data.ItemOriginalPrice / 1.13).toFixed(8)
            ),
            TmpCounter: 0,
            ItemOriginalPrice: parseFloat(data.ItemOriginalPrice),
            qty: 1,
            type: "service",
            ItemType: "SERVICIO",
            serviceType: data.TypeService || "",
            idServiceType: data.ID_TypeService || "",
            ItemCurrency: "$",
            ItemMeasureUnit: "",
            nonsuj:
              this.form.invoicetype != "FEX" ? this.form.legalisnontaxable : 0,
            PriceListName: data.PriceListName,
            ItemPriceDiscountAmount: data.ItemPriceDiscountAmount,
            ItemPriceDiscountPercent: data.ItemPriceDiscountPercent,
            BarCode:
              PrincipalBarCodeValue || PrincipalBarCodeValue?.length > 0
                ? PrincipalBarCodeValue
                : null,
          });
        } else {
          for (const index in this.productsToShow) {
            const { ID_Item } = this.productsToShow[index];

            if (ID_Item == data.CodeService) {
              this.productsToShow[index].qty++;
              break;
            }
          }
        }
      } else if (this.newCostumer == true) {
        // console.log("entro")
      } else {
        this.showProductsAndServices = !this.showProductsAndServices;
        this.showAlert(
          "warning",
          "Advertencia",
          "Por favor seleccione un cliente para facturar el servicio"
        );
      }
    },
    async addNewCustomer() {
      // console.log(this.form)
      if (this.form.legalnit) {
        if (
          !validateNITHomologation(
            "form",
            this,
            "legalnit",
            "Número de NIT del Cliente"
          )
        ) {
          return;
        }
      }

      const newForm = {
        legalname: this.form.legalname,
        legaladdress: this.form.legaladdress,
        legalnrc: this.form.legalnrc
          ? this.form.legalnrc.replaceAll("-", "")
          : "",
        legalnit: this.form.legalnit
          ? this.form.legalnit.replaceAll("-", "")
          : "",
        dui: this.form.dui ? this.form.dui.replaceAll("-", "") : "",
        legalentity: this.form.legalentity,
        information: this.form.information,
        legalisnontaxable: this.form.legalisnontaxable,
        // legalsector: this.form.legalsector,
        legaltaxpayersize: this.form.legaltaxpayersize,
        status: 1,
        idbusiness: JSON.parse(localStorage.getItem("user")).businessid || "",
        idbranch: JSON.parse(localStorage.getItem("user")).branch || "",
        codactividad: this.form.codactividad,
        descactividad: this.form.descactividad,
        phonenumber: this.form.phonenumber,
        email: this.form.email,
        //islegal: true
      };

      try {
        const response = await this.$API.customers.addCustomer(newForm);
        await this.getAllCostumers();
        this.newCostumer = false;
        this.isCustomerSelected = true;
        this.customerSelectOnchange(response.data.customer);
        this.showAlert(
          "success",
          "Cliente agregado",
          "El cliente se ha agregado correctamente"
        );
      } catch (error) {
        console.error("Error:", error);
        this.showAlert(
          "error",
          "Error al agregar cliente",
          "Hubo un problema al agregar el cliente"
        );
      }

      /*this.$API.customers.addCustomer(newForm).then(async (response) => {
        await this.getAllCostumers()
        //console.log("response new client", response.data)
        Object.keys(response.data).map((el) => {
          Vue.set(this.form, el.replace("_", "").toLowerCase(), response.data[el]);
        });
        this.newCostumer = false;
        this.isCustomerSelected = true;
        this.customerSelected(this.customerList.find(current => current.ID_Customer === response.data.customer))
        this.showAlert(
          "success",
          "Cliente agregado",
          "El cliente se ha agregado correctamente"
        );
      })*/
    },
    removeRow(data) {
      this.productsToShow.splice(data.rowIndex, 1);
      this.discountObject.splice(data.rowIndex, 1);
    },
    calculateCellValue(data) {
      data.ItemNewNewPrice = parseFloat(
        data.qty * data.ItemOriginalPrice -
          data.qty * data.ItemPriceDiscountAmount
      ).toFixed(8);
      return parseFloat(
        data.qty * data.ItemOriginalPrice -
          data.qty * data.ItemPriceDiscountAmount
      ).toFixed(8);
    },
    calculateCellValueSinIva(data) {
      let preciosiniva = parseFloat((data.ItemOriginalPrice / 1.13).toFixed(2));
      return parseFloat(
        data.qty * preciosiniva - data.qty * data.ItemPriceDiscountAmount
      ).toFixed(2);
    },
    calculateItemNewPrice(data) {
      data.TmpCounter++;
      data.ItemOriginalPrice = data.ItemNewPrice;
      data.TmpItemNewPrice = parseFloat((data.ItemNewPrice / 1.13).toFixed(8));
      if (data.TmpCounter > 0) {
        data.ItemPriceDiscountAmount = 0;
        data.ItemPriceDiscountPercent = 0;
        this.discountObject = data;
      }
    },
    calculateItemNewPrice2(data) {
      data.TmpCounter++;
      data.ItemNewPrice = data.TmpItemNewPrice;
      data.ItemOriginalPrice = data.TmpItemNewPrice;
      if (data.TmpCounter > 0) {
        data.ItemPriceDiscountAmount = 0;
        data.ItemPriceDiscountPercent = 0;
        this.discountObject = data;
      }
    },
    calculateItemNewPrice3(data) {
      data.TmpItemNewPrice = parseFloat((data.ItemNewPrice / 1.13).toFixed(8));
    },
    customerSelectOnchange(event) {
      try {
        const foundCustomer = this.customersList.find(
          (current) => current.ID_Customer === event
        );
        if (foundCustomer) {
          this.customerSelected(
            this.customersList.find((current) => current.ID_Customer === event)
          );
        }
      } catch (error) {
        console.log(error);
        this.showAlert(
          "error",
          "Error",
          `Ocurrió el siguiente error al intentar seleccionar un usuario ${error.message}`
        );
      }
    },
    async customerSelected(data) {
      this.isCustomerSelected = true;
      this.searchCustomerDialog = false;
      this.newCostumer = false;
      if (
        !this.customersList.find(
          (current) => current.ID_Customer === data.ID_Customer
        )
      ) {
        if (data.Is_a_business) {
          data.itemText = data.BusinessName;
        } else {
          data.itemText = data.LegalName;
        }
        this.customersList.push(data);
      }
      Object.keys(data).map((el) => {
        Vue.set(this.form, el.replace("_", "").toLowerCase(), data[el]);
      });
      this.form.idbranch = this.branch;

      this.form.dui && (this.form.dui = applyDUIMaskOutOfInput(this.form.dui));
      this.form.legalnrc &&
        (this.form.legalnrc = applyDUIMaskOutOfInput(this.form.legalnrc));
      this.form.legalnit &&
        (this.form.legalnit = applyNitMaskHandlingHomologationOutOfInput(
          this.form.legalnit
        ));
      
      if (!this.header && !this.body) {  
        if (data.StatusInvoice != null && data.StatusInvoice != "") {
          this.form.invoicestatusdescription = data.StatusInvoice
        }

        if (data.TypeInvoice != null && data.TypeInvoice != "") {
          this.form.invoicetype = data.TypeInvoice
        }

        if (data.PaymentType != null && data.PaymentType != "") {
          if (this.pospayments == 0) {
            this.form.invoicepaymenttype = data.PaymentType
          }
        }

        if (data.ID_Seller != null && data.ID_Seller != "") {
          this.form.ID_Seller = data.ID_Seller
        }
      }

      if (data.PriceListName != null) {
        this.PriceListSelected = data.PriceListName;
        this.reactiveMainProductsFilter();
      } else {
        //this.PriceListSelected = this.PriceListSelected
        this.PriceListSelected = this.defaultPriceList;
        this.reactiveMainProductsFilter();
      }

      this.customerCreditInformation =
        await this.$API.customers.getAcountReceivableByCustomer({
          ID_Customer: data.ID_Customer,
        });

      // console.log(this.customerCreditInformation)

      if (this.form.limitcredit > 0) {
        if (this.customerCreditInformation.TotalDebt >= this.form.limitcredit) {
          this.creditBillingBlock = true;
          this.showAlert(
            "dangerHtml",
            "Advertencia",
            `El cliente seleccionado ah llegado al límite de crédito permitido, por lo tanto no es posible facturarle.<br><br>
          Saldo pendiente del cliente $${parseFloat(
            this.customerCreditInformation.TotalDebt
          ).toFixed(2)}`
          );
        } else if (this.customerCreditInformation.TotalDebt > 0) {
          this.creditBillingBlock = false;
          let actualCredit =
            this.form.limitcredit - this.customerCreditInformation.TotalDebt;

          this.showAlert(
            "successHtml",
            "<span class='primary--text'>Advertencia</span>",
            `<span class="primary--text"> El cliente seleccionado tiene facturas con </span> crédito pendiente <span class="primary--text">a cancelar por un monto total de</span>
          $ ${parseFloat(this.customerCreditInformation.TotalDebt).toFixed(
            2
          )}<br><br>
          <span class="primary--text">Límite inicial de crédito del cliente</span> $ ${parseFloat(
            this.form.limitcredit
          ).toFixed(2)}<br><br>
          <span class="primary--text">Límite actual de crédito del cliente</span> $ ${parseFloat(
            actualCredit
          ).toFixed(2)}`
          );
        } else {
          this.creditBillingBlock = false;
        }
      } else {
        this.creditBillingBlock = false;
      }

      if (
        this.form.creditstatus == "BLOQUEADO" ||
        this.form.creditstatus == "SUSPENDIDO" ||
        this.form.creditstatus == "INACTIVO"
      ) {
        this.form.invoicestatusdescription = "Cancelada";
      }

      if (this.form.creditperiod != 0) {
        this.creditDays.push(this.form.creditperiod + " días");
        this.form.invoicecreditday = this.form.creditperiod + " días";
      }

      // console.log(this.form)
    },
    addNewClient() {
      this.$API.customers
        .addCustomer({ ...this.form, islegal: true })
        .then((response) => {
          this.showAlert(
            "success",
            "Cliente agregado",
            "El cliente se ha agregado correctamente"
          );
          this.form = {};
          this.customerSelected(response.data);
        });
    },
    cancelDiscount() {
      let index = this.productsToShow.findIndex(
        (el) =>
          el.ID_Item == this.discountObject.ID_Item &&
          el.PriceListName == this.discountObject.PriceListName
      );
      Vue.set(this.productsToShow, index, { ...this.objectToApplyDiscount });
      this.showDiscount = false;
      this.discountObject = null;
    },
    checkValidDui() {
      if (!this.form.dui || this.form.dui.trim() === "") {
        return false;
      } else {
        return true;
      }
    },
    checkBusinessFields() {
      if (
        !this.form.legalnrc ||
        this.form.legalnrc === "" ||
        !this.form.legalnit ||
        this.form.legalnit === "" ||
        !this.form.codactividad ||
        this.form.codactividad === ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    async sendBill(status = 0) {
      if (status === 0 || status === 1) {
        this.$refs.posForm.validate();
      }

      //VALIDACIONES CUANDO ES TEMPLATE
      if (this.form.istemplate == 1) {
        this.showDialogTemplate = false;
        if (this.form.templatename == "" || this.form.templatename == null) {
          this.showAlert(
            "warning",
            "Advertencia",
            "El nombre de la plantilla de facturación es requerido"
          );
          this.form.templatename = "";
          this.form.istemplate = 0;
          return;
        }
      }

      //VALIDACIONES GENERALES
      if (this.sumObject.total <= 0 || !this.sumObject.total) {
        this.showAlert(
          "warning",
          "Advertencia",
          "No se puede facturar si el monto total de la factura es igual a $0"
        );
        return;
      }
      if (!this.form.isa_business) {
        if (
          this.sumObject.total > 25000.0 &&
          this.form.invoicetype === "FCF" &&
          !this.checkValidDui()
        ) {
          // if(1095.01 > 1095.00 && this.form.invoicetype === 'FCF' && !this.checkValidDui()) {
          this.showAlert(
            "warning",
            "Advertencia",
            "No se puede facturar con un cliente si este no tiene DUI, el monto de la factura es mayor a 3 salarios mínimos y el tipo de factura es CONSUMIDOR FINAL."
          );
          return;
        }
      } else {
        if (
          this.sumObject.total > 25000.0 &&
          this.form.invoicetype === "FCF" &&
          !this.checkBusinessFields()
        ) {
          // console.log(this.checkBusinessFields());
          this.showAlert(
            "warning",
            "Advertencia",
            "No se puede facturar con un cliente si este es un negocio cuando el monto de la factura es mayor a 3 salarios mínimos, el tipo de factura es CONSUMIDOR FINAL y no se ha ingresado su información legal completamente (NRC, NIT y Actividad económica)."
          );
          return;
        }
      }
      let errors = 0;

      if (!this.pospayments) {
        this.form.invoicepaymenttype || this.form.isquote == 1
          ? ""
          : (errors += 1);
      }

      this.form.invoicestatusdescription || this.form.isquote == 1
        ? ""
        : (errors += 1);

      if (this.POSRequiredIdSeller) {
        this.form.ID_Seller || this.form.isquote == 1 ? "" : (errors += 1);
      }

      if (this.form.invoicestatusdescription == "Crédito") {
        if (
          this.form.invoicecreditday == "" ||
          this.form.invoicecreditday == null
        )
          errors += 1;
      } else {
        this.form.invoicecreditday = null;
      }

      //VALIDACIONES CUANDO SE VA A FACTURAR
      var moneyReturn = 0; //Para devolución de dinero
      var cash = 0; //Cuanto pagaron en efectivo
      var sumOtherPay = 0; //Cuanto pagaron con otro método de pago

      if (status == 1) {
        
        if (this.EnableNegativeItem == false) {
          if (this.productsToShow.some(item => item?.ErrorRow === true)) {
            this.showAlert("warning", "Advertencia", "La factura contiene productos con cantidades invalidas o que exceden el stock de existencias.")
            return;
          }
        }

        //validaciones del módulo multipagos
        if (
          this.pospayments &&
          this.form.invoicetype != "DNC" &&
          this.form.invoicetype != "DND"
        ) {
          if (
            this.paymentsData.find((element) => element.typePayment == "01")
          ) {
            cash = parseFloat(
              this.paymentsData.find((element) => element.typePayment == "01")
                .totalPayment
            );
          }

          sumOtherPay = this.paymentsData.reduce((sum, element) => {
            if (element.typePayment != "01") {
              return sum + parseFloat(element.totalPayment);
            } else {
              return sum;
            }
          }, 0);

          if (sumOtherPay > this.sumObject.total && cash >= 0) {
            this.showAlert(
              "dangerHtml",
              "Advertencia",
              `No es posible dar cambio en la operación actual pagos en Billetes y Monedas
            insuficientes.<br/><br/>
            Total a pagar en efectivo insuficiente: $${cash.toFixed(2)} <br><br>
            Total a pagar (otro tipo de pago): $${sumOtherPay.toFixed(
              2
            )} <br><br>
            Total DTE: $${this.sumObject.total}`
            );
            return;
          }

          if (this.sumPayments < this.sumObject.total) {
            this.showAlert(
              "dangerHtml",
              "Advertencia",
              `El total a pagar difiere con el total del DTE.<br><br>
            Total a pagar en efectivo: $${cash.toFixed(2)} <br><br>
            Total a pagar (otro tipo de pago): $${sumOtherPay.toFixed(
              2
            )} <br><br>
            Total DTE: $${this.sumObject.total}`
            );
            return;
          }

          if (this.sumPayments > this.sumObject.total) {
            moneyReturn = parseFloat(
              (this.sumPayments - this.sumObject.total).toFixed(2)
            );
          }
        }
        /*if(this.sumPayments < this.sumObject.total) {
          this.showAlert("dangerHtml", "Advertencia", `El total a pagar difiere con el total a cancelar.<br><br>
          Total a pagar: ${this.sumPayments} <br><br>
          Total a cancelar: ${this.sumObject.total}`)
          return
        }
        else {
          if(this.sumPayments > this.sumObject.total) {
            moneyReturn = parseFloat((this.sumPayments - this.sumObject.total).toFixed(2))
          }
        }*/

        if (this.form.limitcredit > 0) {
          let maxCredit =
            this.form.limitcredit - this.customerCreditInformation.TotalDebt;
          if (
            this.sumObject.total > maxCredit &&
            this.form.invoicestatusdescription == "Crédito"
          ) {
            this.showAlert(
              "dangerHtml",
              "Advertencia",
              `El límite de crédito para este cliente excede el permitido.<br><br>
            Límite inicial de crédito del cliente $${parseFloat(
              this.form.limitcredit
            ).toFixed(2)}<br><br>
            Saldo pendiente del cliente $${parseFloat(
              this.customerCreditInformation.TotalDebt
            ).toFixed(2)}<br><br>
            Límite actual de crédito del cliente $${maxCredit}`
            );
            return;
          }
        }

        //this.form.legalidinvoice == 'No hay correlativo' ? (errors += 1) : "";
        if (this.form.invoicestatusdescription == "Pendiente de pago")
          errors += 1;

        // console.log("AVER", this.form)
        if (this.form.invoicetype == "CCF" && this.form.descactividad == "") {
          this.showAlert(
            "warning",
            "Advertencia",
            "Para facturas de crédito fiscal, es requerida la descripción de actividad del cliente"
          );
          return;
        }

        if (this.form.invoicetype == "CCF" && this.form.codactividad == "") {
          this.showAlert(
            "warning",
            "Advertencia",
            "Para facturas de crédito fiscal, es requerido el código de actividad del cliente"
          );
          return;
        }

        if (this.form.invoicetype == "CCF" && this.form.legalnit == "") {
          this.showAlert(
            "warning",
            "Advertencia",
            "Para facturas de crédito fiscal, es requerido el nit del cliente"
          );
          return;
        }

        if (this.form.invoicetype == "CCF" && this.form.legalnrc == "") {
          this.showAlert(
            "warning",
            "Advertencia",
            "Para facturas de crédito fiscal, es requerido el nrc del cliente"
          );
          return;
        }

        if (this.form.invoicetype == "CCF" && this.form.department == "") {
          this.showAlert(
            "warning",
            "Advertencia",
            "Para facturas de crédito fiscal, es requerido el departamento del cliente"
          );
          return;
        }

        if (this.form.invoicetype == "CCF" && this.form.municipality == "") {
          this.showAlert(
            "warning",
            "Advertencia",
            "Para facturas de crédito fiscal, es requerido el municipio del cliente"
          );
          return;
        }

        if (this.form.invoicetype == "CCF" && this.form.legaladdress == "") {
          this.showAlert(
            "warning",
            "Advertencia",
            "Para facturas de crédito fiscal, es requerida la dirección del cliente"
          );
          return;
        }

        if (this.form.invoicetype == "CDD" && this.form.codasociado == "") {
          this.showAlert(
            "warning",
            "Advertencia",
            "Para comprobantes de donación, es requerido el codigo de documento asociado"
          );
        }

        if (this.form.invoicetype == "CDD" && this.form.docasociado == "") {
          this.showAlert(
            "warning",
            "Advertencia",
            "Para comprobantes de donación, es requerido el documento asociado"
          );
        }

        if (this.form.invoicetype == "CDD" && this.form.detalleasociado == "") {
          this.showAlert(
            "warning",
            "Advertencia",
            "Para comprobantes de donación, es requerido el detalle del documento asociado"
          );
        }
      }
      if (errors > 0) {
        if (
          this.form.invoicestatusdescription == "Pendiente de pago" &&
          status == 1
        ) {
          this.showAlert(
            "warning",
            "Advertencia",
            "No se puede facturar si la factura se encuentra pendiente de pago."
          );
        } else {
          this.showAlert(
            "warning",
            "Advertencia",
            "Debe llenar todos los campos obligatorios"
          );
        }

        return;
      }
      if (
        this.form.legalidinvoice != "No hay correlativo" ||
        JSON.parse(localStorage.getItem("FaMh")).AuthFeActive == 1
      ) {
        if (this.isCustomerSelected) {
          //legalidinvoice
          let obj = {}; //{ ...this.sumObject };
          let form = { ...this.form };
          for (var i in form) {
            let key = i.replace("legal", "invoice");
            obj[key] = this.form[i];
          }

          obj.invoicestatus = status;
          let items = [];
          let serviceToGenerate = [];

          /*descuentos*/
          obj.invoicedescunosuj = 0;
          obj.invoicedescuexenta = 0;
          obj.invoicedescugravada = 0;
          obj.invoiceporcentajedescuento = 0;
          obj.invoicetotaldescu = 0;

          /*subtotales*/
          obj.invoicesubtotalventas = 0;
          obj.invoicesubtotal = 0;

          /*totales*/
          obj.invoicemontototaloperacion = 0;
          obj.invoicetotalexenta = 0;
          obj.invoicetotalnosuj = 0;
          obj.invoicetotalgravada = 0;
          obj.invoicetotalpagar = 0;
          obj.invoicetotaliva = 0;
          obj.invoiceivarete1 = 0;
          obj.invoiceivaperce1 = 0;
          obj.invoicetotalletras = "";
          obj.invoicedui = obj.dui;
          obj.invoicereterenta = 0;
          obj.fovial = 0;
          obj.cotrans = 0;

          let restIvaPerce = 0;

          this.productsToShow.map((el) => {
            if (el.type == "service") {
              serviceToGenerate.push(el);
            }

            if (el.discountFixed == undefined) {
              el.discountFixed = 0;
            } else {
              el.discountFixed = parseFloat(el.discountFixed);
            }

            if (el.ItemPriceDiscountPercent == undefined)
              el.ItemPriceDiscountPercent = 0;

            //let descu = parseFloat(parseFloat((el.ItemOriginalPrice/1.13)*(el.ItemPriceDiscountPercent / 100)).toFixed(2))

            if (
              isNaN(el.Suggested_price) ||
              el.Suggested_price == undefined ||
              el.Suggested_price == null
            ) {
              el.Suggested_price = el.ItemOriginalPrice;
            }

            let total_price;
            let totaliva;
            let totalunitcost;
            let totalgravada;
            let totalnosuj;
            let totalexenta;
            let totaldescu;

            if (
              this.form.invoicetype == "CCF" ||
              this.form.invoicetype == "DND" ||
              this.form.invoicetype == "DNC"
            ) {
              total_price = parseFloat(
                parseFloat(
                  ((el.ItemNewPrice / 1.13).toFixed(8) * el.qty).toFixed(8)
                ).toFixed(8)
              );

              totaliva =
                el.nonsuj || el.nontaxable
                  ? 0
                  : parseFloat(
                      parseFloat(el.ItemNewNewPrice - total_price).toFixed(8)
                    );
              totalunitcost = 
                el.nonsuj || el.nontaxable
                ? parseFloat(parseFloat(el.ItemOriginalPrice).toFixed(8))
                : parseFloat(parseFloat(el.ItemOriginalPrice / 1.13).toFixed(8));
              //let itemPrice = parseFloat(el.ItemOriginalPrice.toFixed(2))
              //let itemPriceWithoutIva = parseFloat(parseFloat(itemPrice / 1.13).toFixed(2))
              //let itemIva = itemPrice - itemPriceWithoutIva
              //total_price = parseFloat(parseFloat(itemPriceWithoutIva * el.qty).toFixed(2))
              //totaliva = (el.nonsuj || el.nontaxable) ? 0 : parseFloat(parseFloat(itemIva * el.qty).toFixed(2))
              //totalunitcost = itemPriceWithoutIva
              totalgravada =
                el.nonsuj || el.nontaxable
                  ? 0
                  : parseFloat(parseFloat(total_price).toFixed(8));
              totalnosuj = el.nonsuj
                ? parseFloat(parseFloat(el.ItemNewNewPrice).toFixed(8))
                : 0;
              totalexenta = el.nontaxable
                ? parseFloat(parseFloat(el.ItemNewNewPrice).toFixed(8))
                : 0;
              totaldescu = parseFloat(
                parseFloat(
                  (el.ItemPriceDiscountAmount / 1.13) * el.qty
                ).toFixed(8)
              );

              if (el.nontaxable2) el.nontaxable = el.nontaxable2;

              if (el.nonsuj2) el.nonsuj = el.nonsuj2;

              /*Calculos*/
              if (el.nonsuj) {
                //obj.invoicedescunosuj += el.discountFixed
                //obj.invoicedescunosuj += descu
              }

              if (el.nontaxable) {
                //obj.invoicedescuexenta += el.discountFixed
                //obj.invoicedescuexenta += descu
              }

              if (!el.nontaxable && !el.nonsuj) {
                //obj.invoicedescugravada += el.discountFixed
                //obj.invoicedescugravada += descu
                //obj.invoicesubtotalventas += total_price
              }

              obj.invoicesubtotalventas +=
                totalgravada + totalnosuj + totalexenta;

              obj.invoicetotaldescu += totaldescu; //el.discountFixed //descu
              //obj.invoiceporcentajedescuento += parseFloat(el.ItemPriceDiscountPercent)
              obj.invoicesubtotal += totalgravada + totalnosuj + totalexenta; //total_price
              obj.invoicetotalnosuj += totalnosuj;
              obj.invoicetotalexenta += totalexenta;
              obj.invoicetotalgravada += totalgravada;
              obj.invoicemontototaloperacion +=
                (totalgravada != 0 ? parseFloat(el.ItemNewNewPrice) : 0) +
                totalnosuj +
                totalexenta; //parseFloat(el.ItemNewNewPrice)
              obj.invoicetotalpagar +=
                (totalgravada != 0 ? parseFloat(el.ItemNewNewPrice) : 0) +
                totalnosuj +
                totalexenta; //parseFloat(el.ItemNewNewPrice)
              obj.invoicetotaliva += totaliva;

              if (this.form.legalreterenta) {
                if (el.type == "service") {
                  let reterenta = parseFloat(
                    parseFloat(parseFloat(total_price) * 0.1).toFixed(2)
                  );
                  obj.invoicereterenta += reterenta;
                  obj.invoicetotalpagar = obj.invoicetotalpagar - reterenta;
                } else {
                  let reterenta = 0;
                  obj.invoicereterenta += reterenta;
                }
              }

              if (
                this.form.invoicetype == "CCF" &&
                el.type == "product" &&
                el.IsHidrocarburo
              ) {
                let fovial = parseFloat(
                  parseFloat(parseFloat(el.qty) * 0.2).toFixed(2)
                );
                let cotrans = parseFloat(
                  parseFloat(parseFloat(el.qty) * 0.1).toFixed(2)
                );

                obj.fovial += fovial;
                obj.cotrans += cotrans;
                obj.invoicemontototaloperacion =
                  obj.invoicemontototaloperacion + fovial + cotrans;
                obj.invoicetotalpagar =
                  obj.invoicetotalpagar + fovial + cotrans;
                restIvaPerce = restIvaPerce + totalgravada * 0.01;
              }
            } else {
              total_price = parseFloat(
                parseFloat(el.ItemNewNewPrice).toFixed(8)
              );
              totaliva =
                el.nonsuj || el.nontaxable
                  ? 0
                  : parseFloat(
                      parseFloat(total_price - total_price / 1.13).toFixed(8)
                    );

              totalgravada =
                el.nonsuj || el.nontaxable
                  ? 0
                  : parseFloat(parseFloat(total_price).toFixed(8));
              totalnosuj = el.nonsuj
                ? parseFloat(parseFloat(total_price).toFixed(8))
                : 0;
              totalexenta = el.nontaxable
                ? parseFloat(parseFloat(total_price).toFixed(8))
                : 0;
              totaldescu = parseFloat(
                parseFloat(el.ItemPriceDiscountAmount * el.qty).toFixed(8)
              );

              if (el.nonsuj) {
                totalunitcost = parseFloat(
                  parseFloat(el.ItemOriginalPrice).toFixed(8)
                );
              } else if (el.nontaxable) {
                totalunitcost = parseFloat(
                  parseFloat(el.ItemOriginalPrice).toFixed(8)
                );
              } else {
                totalunitcost = parseFloat(
                  parseFloat(el.ItemOriginalPrice).toFixed(8)
                );
              }

              if (el.nontaxable2) el.nontaxable = el.nontaxable2;

              if (el.nonsuj2) el.nonsuj = el.nonsuj2;

              /*Calculos*/
              if (el.nonsuj) {
                //obj.invoicedescunosuj += el.discountFixed
                //obj.invoicedescunosuj += descu
              }

              if (el.nontaxable) {
                //obj.invoicedescuexenta += el.discountFixed
                //obj.invoicedescuexenta += descu
              }

              if (!el.nontaxable && !el.nonsuj) {
                //obj.invoicedescugravada += el.discountFixed
                //obj.invoicedescugravada += descu
                //obj.invoicesubtotalventas += total_price
              }

              obj.invoicesubtotalventas +=
                totalgravada + totalnosuj + totalexenta;

              obj.invoicetotaldescu += totaldescu; //descu
              //obj.invoiceporcentajedescuento += parseFloat(el.ItemPriceDiscountPercent)
              obj.invoicesubtotal += totalgravada + totalnosuj + totalexenta; //total_price
              obj.invoicetotalnosuj += totalnosuj;
              obj.invoicetotalexenta += totalexenta;
              obj.invoicetotalgravada += totalgravada;
              obj.invoicemontototaloperacion +=
                totalgravada + totalnosuj + totalexenta; //total_price
              obj.invoicetotalpagar += totalgravada + totalnosuj + totalexenta; //total_price
              obj.invoicetotaliva += totaliva;

              if (this.form.invoicetype == "FNS") {
                if (el.type == "service") {
                  let reterenta = parseFloat(
                    parseFloat(parseFloat(total_price) * 0.1).toFixed(2)
                  );
                  obj.invoicereterenta += reterenta;
                  obj.invoicetotalpagar = obj.invoicetotalpagar - reterenta;
                } else {
                  let reterenta = 0;
                  obj.invoicereterenta += reterenta;
                }
              }

              if (this.form.legalreterenta && this.form.invoicetype == "FCF") {
                if (el.type == "service") {
                  let reterenta = parseFloat(
                    parseFloat(parseFloat(total_price / 1.13) * 0.1).toFixed(2)
                  );
                  obj.invoicereterenta += reterenta;
                  obj.invoicetotalpagar = obj.invoicetotalpagar - reterenta;
                } else {
                  let reterenta = 0;
                  obj.invoicereterenta += reterenta;
                }
              }

              if (
                this.form.invoicetype == "FCF" &&
                el.type == "product" &&
                el.IsHidrocarburo
              ) {
                let fovial = parseFloat(
                  parseFloat(parseFloat(el.qty) * 0.2).toFixed(2)
                );
                let cotrans = parseFloat(
                  parseFloat(parseFloat(el.qty) * 0.1).toFixed(2)
                );

                obj.fovial += fovial;
                obj.cotrans += cotrans;
                obj.invoicemontototaloperacion =
                  obj.invoicemontototaloperacion + fovial + cotrans;
                obj.invoicetotalpagar =
                  obj.invoicetotalpagar + fovial + cotrans;
              }
            }

            console.log("item to push ", {
              idbranch: this.branch,
              idbusiness: el.ID_Business,
              iditem: el.ID_Item,
              itemtype: el.type,
              itemquantity: el.qty,
              itemmeasureunit: el.ItemMeasureUnit,
              itemdescription: el.ItemName,
              itemunitcost: totalunitcost, //Precio unitario original sin aplicar el descuento y con el iva
              itemdiscount: totaldescu || 0, //el.discountFixed || 0, //descu || 0,
              itemdiscountpercent: parseFloat(el.ItemPriceDiscountPercent) || 0,
              itemnosuj: totalnosuj, //No debe de incluir el iva ni el descuento
              itemexenta: totalexenta, //No debe de incluir el iva ni el descuento
              itemgravada: totalgravada, //No debe de incluir el iva ni el descuento
              itempsv: parseFloat(el.Suggested_price),
              itemnogravada: 0,
              itemiva: totaliva,
              itemcurrency: el.ItemCurrency || "$",
              warehouse: el.Warehouse,
              itembatch: el.ID_ItemBatch || "",
              ishidrocarburo: el.IsHidrocarburo || 0,
              principalbarcode: el.BarCode || "",
            });

            items.push({
              idbranch: this.branch,
              idbusiness: el.ID_Business,
              iditem: el.ID_Item,
              itemtype: el.type,
              itemquantity: el.qty,
              itemmeasureunit: el.ItemMeasureUnit,
              itemdescription: el.ItemName,
              itemunitcost: totalunitcost, //Precio unitario original sin aplicar el descuento y con el iva
              itemdiscount: totaldescu || 0, //el.discountFixed || 0, //descu || 0,
              itemdiscountpercent: parseFloat(el.ItemPriceDiscountPercent) || 0,
              itemnosuj: totalnosuj, //No debe de incluir el iva ni el descuento
              itemexenta: totalexenta, //No debe de incluir el iva ni el descuento
              itemgravada: totalgravada, //No debe de incluir el iva ni el descuento
              itempsv: parseFloat(el.Suggested_price),
              itemnogravada: 0,
              itemiva: totaliva,
              itemcurrency: el.ItemCurrency || "$",
              warehouse: el.Warehouse,
              itembatch: el.ID_ItemBatch || "",
              ishidrocarburo: el.IsHidrocarburo || 0,
              principalbarcode: el.BarCode || "",
            });
          });

          obj.invoicedescunosuj = parseFloat(
            parseFloat(obj.invoicedescunosuj).toFixed(2)
          );
          obj.invoicedescuexenta = parseFloat(
            parseFloat(obj.invoicedescuexenta).toFixed(2)
          );
          obj.invoicedescugravada = parseFloat(
            parseFloat(obj.invoicedescugravada).toFixed(2)
          );
          obj.invoicetotaldescu = parseFloat(
            parseFloat(obj.invoicetotaldescu).toFixed(2)
          );
          obj.invoicesubtotalventas = parseFloat(
            parseFloat(obj.invoicesubtotalventas).toFixed(2)
          );
          obj.invoicesubtotal = parseFloat(
            parseFloat(obj.invoicesubtotal).toFixed(2)
          );
          obj.invoicemontototaloperacion = parseFloat(
            parseFloat(obj.invoicemontototaloperacion).toFixed(2)
          );
          obj.invoicetotalexenta = parseFloat(
            parseFloat(obj.invoicetotalexenta).toFixed(2)
          );
          obj.invoicetotalnosuj = parseFloat(
            parseFloat(obj.invoicetotalnosuj).toFixed(2)
          );
          obj.invoicetotalgravada = parseFloat(
            parseFloat(obj.invoicetotalgravada).toFixed(2)
          );
          obj.invoicetotalpagar = parseFloat(
            parseFloat(obj.invoicetotalpagar).toFixed(2)
          );
          obj.invoicetotaliva = parseFloat(
            parseFloat(obj.invoicetotaliva).toFixed(2)
          );
          obj.invoicereterenta = parseFloat(
            parseFloat(obj.invoicereterenta).toFixed(2)
          );
          obj.fovial = parseFloat(parseFloat(obj.fovial).toFixed(2));
          obj.cotrans = parseFloat(parseFloat(obj.cotrans).toFixed(2));

          if (
            this.form.invoicetype == "CCF" ||
            this.form.invoicetype == "DND" ||
            this.form.invoicetype == "DNC"
          ) {
            const originalSubtotal100Percent =
              obj.invoicetotaldescu + obj.invoicesubtotal;
            obj.invoiceporcentajedescuento = parseFloat(
              parseFloat(
                (obj.invoicetotaldescu * 100) / originalSubtotal100Percent
              ).toFixed(2)
            );
          }

          if (
            this.form.invoicetype == "FCF" ||
            this.form.invoicetype == "FNS" ||
            this.form.invoicetype == "CCF" ||
            this.form.invoicetype == "DND" ||
            this.form.invoicetype == "DNC"
          )
            if (
              !this.BusinessLegalAgentRetain
            ) {
              if (this.form.legalagentretain) {
                if (!this.form.isa_publicentity) {
                  if (this.form.invoicetype == "FCF") {
                    let sumtmp =
                      parseFloat(obj.invoicetotalexenta) +
                      parseFloat(obj.invoicetotalnosuj) +
                      parseFloat(obj.invoicetotalgravada - obj.invoicetotaliva);
                    if (sumtmp >= 100) {
                      obj.invoiceivarete1 = parseFloat(
                        parseFloat(
                          (obj.invoicetotalgravada - obj.invoicetotaliva) * 0.01
                        ).toFixed(2)
                      );
                      obj.invoicetotalpagar = parseFloat(
                        parseFloat(
                          obj.invoicetotalpagar - obj.invoiceivarete1
                        ).toFixed(2)
                      );
                    }
                  } else {
                    let sumtmp =
                      parseFloat(obj.invoicetotalexenta) +
                      parseFloat(obj.invoicetotalnosuj) +
                      parseFloat(obj.invoicetotalgravada);
                    if (sumtmp >= 100) {
                      obj.invoiceivarete1 = parseFloat(
                        parseFloat(obj.invoicetotalgravada * 0.01).toFixed(2)
                      );
                      obj.invoicetotalpagar = parseFloat(
                        parseFloat(
                          obj.invoicetotalpagar - obj.invoiceivarete1
                        ).toFixed(2)
                      );
                    }
                  }
                }
              }
            }

          if (this.form.isa_publicentity && this.form.invoicetype == "FCF") {
            let sumtmp =
              parseFloat(obj.invoicetotalexenta) +
              parseFloat(obj.invoicetotalnosuj) +
              parseFloat(obj.invoicetotalgravada);
            if (sumtmp >= 113) {
              obj.invoiceivarete1 = parseFloat(
                parseFloat(
                  (obj.invoicetotalgravada - obj.invoicetotaliva) * 0.01
                ).toFixed(2)
              );
              obj.invoicetotalpagar = parseFloat(
                parseFloat(obj.invoicetotalpagar - obj.invoiceivarete1).toFixed(
                  2
                )
              );
            } else if (this.form.removerestrictionpublicentity) {
              obj.invoiceivarete1 = parseFloat(
                parseFloat(
                  (obj.invoicetotalgravada - obj.invoicetotaliva) * 0.01
                ).toFixed(2)
              );
              obj.invoicetotalpagar = parseFloat(
                parseFloat(obj.invoicetotalpagar - obj.invoiceivarete1).toFixed(
                  2
                )
              );
            }
          }

          if (
            this.form.invoicetype == "CCF" ||
            this.form.invoicetype == "DND" ||
            this.form.invoicetype == "DNC"
          ) {
            if (this.BusinessLegalAgentRetain) {
              if (
                !this.form.legalagentretain
              ) {
                let sumtmp =
                  parseFloat(obj.invoicetotalexenta) +
                  parseFloat(obj.invoicetotalnosuj) +
                  parseFloat(obj.invoicetotalgravada);
                if (sumtmp >= 100) {
                  obj.invoiceivaperce1 = parseFloat(
                    parseFloat(
                      obj.invoicetotalgravada * 0.01 - restIvaPerce
                    ).toFixed(2)
                  );
                  //obj.invoicemontototaloperacion = parseFloat(parseFloat(obj.invoicemontototaloperacion - obj.invoiceivarete1).toFixed(2))
                  obj.invoicetotalpagar = parseFloat(
                    parseFloat(
                      obj.invoicetotalpagar + obj.invoiceivaperce1
                    ).toFixed(2)
                  );
                }
              }
            }
          }

          if (this.form.invoicetype == "FEX") {
            obj.invoicemontototaloperacion = parseFloat(
              parseFloat(
                obj.invoicemontototaloperacion +
                  parseFloat(this.form.flete) +
                  parseFloat(this.form.seguro)
              ).toFixed(2)
            );
            obj.invoicetotalpagar = parseFloat(
              parseFloat(
                obj.invoicetotalpagar +
                  parseFloat(this.form.flete) +
                  parseFloat(this.form.seguro)
              ).toFixed(2)
            );
          }

          /*if(this.form.invoicetype == "FNS"){
            obj.invoicereterenta = parseFloat(parseFloat( parseFloat(obj.invoicetotalpagar) * 0.1 ).toFixed(2))
            obj.invoicetotalpagar = parseFloat(parseFloat( parseFloat(obj.invoicetotalpagar) - parseFloat(obj.invoicereterenta) ).toFixed(2))
          }
          else{
            obj.invoicereterenta = parseFloat( 0.00 )
          }*/

          /*if(this.taxPayerSize == 'PEQUEÑO'){
            if(this.form.legaltaxpayersize == "MEDIANO"){
              obj.invoiceivarete1 = parseFloat(parseFloat(obj.invoicetotalgravada * 0.01).toFixed(2))
              //obj.invoicemontototaloperacion = parseFloat(parseFloat(obj.invoicemontototaloperacion - obj.invoiceivarete1).toFixed(2))
              obj.invoicetotalpagar = parseFloat(parseFloat(obj.invoicetotalpagar - obj.invoiceivarete1).toFixed(2))
            }
          }*/

          obj.invoicetotalletras = numberToText(obj.invoicetotalpagar);

          //Se agrega el punto de venta para pasarlo al ministerio de hacienda
          obj.codpointsale = localStorage.getItem("codPointSale");

          obj.pospayments = this.pospayments;

          if (this.pospayments) {
            if (moneyReturn > 0) {
              this.paymentsData.find((element) => {
                if (element.typePayment == "01") {
                  element.totalPayment = parseFloat(
                    (parseFloat(element.totalPayment) - moneyReturn).toFixed(2)
                  );
                }
              });
            }
            obj.invoicepaymenttype = null;

            this.paymentsData = this.paymentsData.filter(
              (element) => element.totalPayment != 0
            );

            obj.payments = this.paymentsData;
          } else {
            if (
              this.form.invoicepaymenttype != "" &&
              this.form.invoicepaymenttype != null
            ) {
              this.paymentsData[0].typePayment = this.paymentTypes.find(
                (element) => element.Valores === this.form.invoicepaymenttype
              ).Codigo;
              this.paymentsData[0].descriptionPayment =
                this.form.invoicepaymenttype;
              if (
                this.form.invoicepaymenttype != "Billetes y monedas" &&
                form.invoicepaymenttype != undefined
              ) {
                this.paymentsData[0].reference = this.form.invoicereference;
              }
              this.paymentsData[0].totalPayment = obj.invoicetotalpagar;

              obj.invoicepaymenttype = this.paymentsData[0].descriptionPayment;

              this.paymentsData = this.paymentsData.filter(
                (element) => element.totalPayment != 0
              );
            } else {
              this.paymentsData = [];
            }

            obj.payments = this.paymentsData;
          }

          obj.MoneyReturn = moneyReturn;
          obj.Flete = this.form.flete;
          obj.Seguro = this.form.seguro;

          /*if (status == 1) {
            this.$API.invoices.addServiceToClient({
              ID_Customer: obj.idcustomer,
              dateinvoice: this.form.invoicedate,
              services: serviceToGenerate,
              businesstype: JSON.parse(localStorage.getItem("user")).businesstype,
            });
          }*/

          let invalidData = this.invalid != null ? this.invalid : null;
          let ndorncData = this.ndornc != null ? this.ndornc : null;
          let contingencyData =
            this.contingency != null ? this.contingency : null;
          this.loading = true;

          if (this.form.timesexecute > 1 && status == 1) {
            this.loading = true;
            let quantityProcessed = 0;
            let quantityRejected = 0;
            for (let i = 0; i < this.form.timesexecute; i++) {
              if (this.cancelTimesExecute == true) {
                this.alert.show = false;
                this.showAlert(
                  "progress",
                  "Ejecutando facturas",
                  "Cancelando proceso..."
                );
                break;
              }

              let response = await this.$API.invoices.addInvoice({
                header: obj,
                items: items,
                user: JSON.parse(localStorage.getItem("user")).id,
                invalid: invalidData,
                ndornc: ndorncData,
                contingency: contingencyData,
              });

              if (response.estado == "PROCESADO") {
                if (
                  JSON.parse(localStorage.getItem("user")).businesstype ==
                    "bloodtestlaboratory" ||
                  JSON.parse(localStorage.getItem("user")).businesstype ==
                    "medicalclinic"
                ) {
                  await this.$API.invoices.addServiceToClient({
                    ID_Customer: obj.idcustomer,
                    dateinvoice: this.form.invoicedate,
                    services: serviceToGenerate,
                    businesstype: JSON.parse(localStorage.getItem("user"))
                      .businesstype,
                  });
                }
                quantityProcessed++;
              } else {
                quantityRejected++;
              }

              this.alert.show = false;
              this.showAlert(
                "progress",
                "Ejecutando facturas",
                "Procesadas: " +
                  quantityProcessed +
                  "/" +
                  this.form.timesexecute +
                  " | Rechazadas: " +
                  quantityRejected +
                  "/" +
                  this.form.timesexecute,
                "cancelTimesExecute"
              );

              if (this.cancelTimesExecute == true) {
                this.alert.show = false;
                this.showAlert(
                  "progress",
                  "Ejecutando facturas",
                  "Cancelando proceso..."
                );
                break;
              }
            }

            this.alert.show = false;

            this.loading = false;
            this.showAlert(
              "success",
              "Resultado de la facturación",
              "Procesadas: " +
                quantityProcessed +
                "/" +
                this.form.timesexecute +
                " | Rechazadas: " +
                quantityRejected +
                "/" +
                this.form.timesexecute,
              "updateFinish"
            );
          } else {
            this.$API.invoices
              .addInvoice({
                header: obj,
                items: items,
                user: JSON.parse(localStorage.getItem("user")).id,
                invalid: invalidData,
                ndornc: ndorncData,
                contingency: contingencyData,
              })
              .then(async (response) => {
                //Si se retorna sendBill=2 entonces el mensaje es de facturación con correlativos

                //Si se retorna sendBill=1 entonces el mensaje es de facturación electrónica
                this.datatoprint.dteJson = response.billingDteJsonHeader;
                if (response.sendBill == 1) {
                  if (response.estado == "PROCESADO") {
                    if (
                      JSON.parse(localStorage.getItem("user")).businesstype ==
                        "bloodtestlaboratory" ||
                      JSON.parse(localStorage.getItem("user")).businesstype ==
                        "medicalclinic"
                    ) {
                      this.$API.invoices.addServiceToClient({
                        ID_Customer: obj.idcustomer,
                        dateinvoice: this.form.invoicedate,
                        services: serviceToGenerate,
                        businesstype: JSON.parse(localStorage.getItem("user"))
                          .businesstype,
                      });
                    }
                    this.alertFactura.color = "comment";
                    if (response?.estadoInvalidacion === "PROCESADO") {
                      this.alertFactura.message =
                        "Se ha procesado la nueva factura con los datos editados y se ha invalidado la factura anteriormente seleccionada.";
                    } else if (response?.estadoInvalidacion === "RECHAZADO") {
                      this.alertFactura.message = `Se ha realizado y procesado la factura. Pero la invalidacion no se ha ejecutado. Por favor, invalide la factura manualmente`;
                    } else {
                      this.alertFactura.message = "Se ha realizado y procesado la factura."
                      this.alertFactura.total = this.sumObject.total
                      this.alertFactura.pagoCon = this.sumPayments
                      this.alertFactura.cambio = moneyReturn;
                    }
                    this.alertFactura.estado = response.estado;
                    this.alertFactura.description = response.descripcionMsg;
                    this.alertFactura.observaciones = response.observaciones

                    this.datatoprint.header =
                      await this.$API.invoices.getInvoice(
                        response.billingDteJsonHeader.ID_Invoice
                      );
                    this.datatoprint.body =
                      await this.$API.invoices.getAllInvoiceItems(
                        response.billingDteJsonHeader.ID_Invoice
                      );

                    if (
                      this.form.invoicetype == "DNC" ||
                      this.form.invoicetype == "DND"
                    ) {
                      this.datatoprint.docRelated =
                        await this.$API.invoices.getDocRelatedByInvoice(
                          response.billingDteJsonHeader.ID_Invoice
                        );
                    }

                    if (
                      this.form.invoicetype == "DNC" ||
                      this.form.invoicetype == "DND"
                    ) {
                      this.datatoprint.docRelated =
                        await this.$API.invoices.getDocRelatedByInvoice(
                          response.billingDteJsonHeader.ID_Invoice
                        );
                    }

                    this.loading = false;
                    this.alertFactura.title = "DTE GENERADA"
                    this.alertFactura.colorTitle = "primary--text font-weight-bold"
                    this.alertFactura.show = true
                    this.alertFactura.colorBtn = "primary";
                    this.alert.options = "updateFinishAndPrintInvoice"
                  } else if (response.estado == "CONTINGENCIA") {
                    this.alertFactura.color = "warning";
                    this.alertFactura.colorTitle = "black--text font-weight-bold"
                    this.alertFactura.colorBtn = "comment"
                    this.alertFactura.message = "El DTE se ha guardado en estado de contingencia."
                    this.alertFactura.total = this.sumObject.total
                    this.alertFactura.pagoCon = this.sumPayments
                    this.alertFactura.cambio = this.moneyReturn;
                    this.alertFactura.estado = response.estado;
                    this.alertFactura.description = `${response.status}, ${response.error}`;
                    this.datatoprint.header =
                      await this.$API.invoices.getInvoice(
                        response.billingDteJsonHeader.ID_Invoice
                      );
                    this.datatoprint.body =
                      await this.$API.invoices.getAllInvoiceItems(
                        response.billingDteJsonHeader.ID_Invoice
                      );

                    this.loading = false;
                    this.alertFactura.title = "DTE EN CONTINGENCIA",
                    this.alertFactura.show = true;
                    this.alert.options = "updateFinishAndPrintInvoice"
                  } else {
                    this.alertFactura.title = "DTE RECHAZADO"
                    this.alertFactura.colorTitle = "black--text font-weight-bold"
                    this.alertFactura.color = "error"
                    this.alertFactura.colorBtn = "comment"
                    this.alertFactura.message =
                      "Se ha rechazado el DTE, tomar en cuenta las observaciones del Ministerio de Hacienda y realizarla de nuevo.";
                    this.alertFactura.estado = response.estado;
                    this.alertFactura.description = response.descripcionMsg;
                    this.alertFactura.observaciones = response.observaciones;
                    this.loading = false;
                    this.alertFactura.show = true;
                  }
                } else if (response.sendBill == 0) {
                  if (obj?.invoicetype == "TCKS") {
                    this.datatoprint.header =
                      await this.$API.invoices.getInvoice(response.ID_Invoice);
                    this.datatoprint.body =
                      await this.$API.invoices.getAllInvoiceItems(
                        response.ID_Invoice
                      );

                    this.loading = false;
                    this.showAlert(
                      "success",
                      "Ticket generado",
                      response.message,
                      "updateFinishAndPrintInvoice"
                    );
                  } else if (obj?.istemplate == 1) {
                    this.loading = false;
                    this.showAlert(
                      "success",
                      "Plantilla de factura generada",
                      response.message,
                      "updateTemplate"
                    );
                    this.$store.dispatch("reloadData", "invoices");
                  } else if (obj?.isquote == 1) {
                    this.loading = false;
                    this.showAlert(
                      "success",
                      "Cotización generada",
                      response.message,
                      "updateQuote"
                    );
                    this.$store.dispatch("reloadData", "invoices");
                  } else {
                    this.loading = false;
                    this.showAlert(
                      "success",
                      "Factura generada",
                      response.message,
                      "update"
                    );
                    this.$store.dispatch("reloadData", "invoices");
                  }
                }
              })
              .catch((err) => {
                this.loading = false;
                let message =
                  "Ocurrio un error al realizar la factura. ¡Por favor intentarlo nuevamente o contáctarse con el administrador del sistema!";
                // message += "<br/><br/>"+JSON.stringify(err)
                this.currenterrordata = JSON.stringify(err);
                this.showAlert("danger", "Advertencia", message);
              });
          }
        } else {
          this.showAlert(
            "danger",
            "Advertencia",
            "Por favor seleccione un cliente para facturar."
          );
        }
      } else {
        if (
          this.form.legalidinvoice != "No hay correlativo" &&
          JSON.parse(localStorage.getItem("FaMh")).AuthFeActive == 0
        ) {
          this.showAlert(
            "danger",
            "Advertencia",
            "No se puede generar facturas sin correlativo."
          );
        } else if (JSON.parse(localStorage.getItem("FaMh")).AuthFeActive == 1) {
          this.showAlert(
            "danger",
            "Advertencia",
            "No se puede generar facturas si no se ha configurado las credenciales de facturación electrónica en la configuración del negocio o sucursal."
          );
        } else {
          this.showAlert(
            "danger",
            "Advertencia",
            "Ha ocurrido un problema, no se pudo generar la factura. Por favor contacte al administrador del sistema."
          );
        }
      }
    },
    loadpriceProducts() {
      /*if(!this.PriceListSelected || !this.warehouseSelected)
      {
        this.showAlert(
          "warning",
          "Advertencia",
          "Debe seleccionar una lista de precios y una bodega."
        );
        return;
      }*/
      if (this.isCustomerSelected) {
        // this.productsToBill = [ ...this.productToBillPrev.filter( el => {
        //   return el.Warehouse == this.warehouseSelected && el.PriceListName === this.PriceListSelected
        // } )];

        // for( const element of this.productToBillPrev ) {
        //   const { ID_Item, Warehouse } = element;

        //   const foundProduct = this.productsToBill.find( current => current.ID_Item === ID_Item && current.Warehouse === Warehouse );

        //   if(!foundProduct) {
        //     // console.log( 'foundProduct ', ID_Item, Warehouse );
        //     this.productsToBill.push( element );
        //   }
        // }

        // console.log( 'filtrando ', this.productToBillPrev.filter( el => el.ID_Item === 'PRO2023120001' && el.ActivePriceList === 1 ) );
        // this.productsToBill = this.productsToBill.filter((el) => {
        //   return el.Warehouse == this.warehouseSelected
        // })
        //ItemNewPrice
        //pricelistDetail
        this.productsToBill.forEach((element) => {
          this.pricelistDetail.forEach((item) => {
            if (element.ID_Item == item.ID_Item && item.InitialRange == null) {
              element.ItemNewPrice = item.ItemNewPrice;
              element.TmpItemNewPrice = parseFloat(
                parseFloat(item.ItemOriginalPrice / 1.13).toFixed(8)
              );
              element.TmpCounter = 0;
            }
          });
        });
        this.showProductsAndServices = !this.showProductsAndServices;
      } else {
        this.showAlert(
          "danger",
          "Advertencia",
          "Debe seleccionar un cliente para agregar productos"
        );
      }
    },
    reactiveMainProductsFilter() {
      // console.log('this.PriceListSelected ', this.PriceListSelected)
      // console.log('this.productsToBillPrev ', this.productToBillPrev);
      try {
        this.productsToBill = [
          ...this.productToBillPrev.filter((el) => {
            /* MODIFICACIONES INTRODUCIDAS PARA MOSTRAR LOS PRODUCTOS DE DISTINTOS LOTES POR EMANUEL */
            if (this.PriceListSelected != null && this.PriceListSelected != 0) {
              return (
                el.Warehouse == this.warehouseSelected &&
                el.PriceListName == this.PriceListSelected
              );
            } else if (
              this.warehouseSelected != null &&
              this.warehouseSelected != 0
            ) {
              console.log("2");
              return el.Warehouse == this.warehouseSelected;
            } else {
              console.log("3");
              return el;
            }
            /* FIN MODIFICACIONES INTRODUCIDAS PARA MOSTRAR LOS PRODUCTOS DE DISTINTOS LOTES POR EMANUEL */
          }),
        ];

        this.search.warehouse = this.warehouseSelected;
        this.search.pricelist = this.PriceListSelected;

        this.productsToBillForSelector = [
          ...this.productToBillPrev.filter((el) => {
            return (
              el.Warehouse == this.warehouseSelected &&
              el.PriceListName === this.PriceListSelected
            );
          }),
        ];

        this.servicesToBillForSelector = [...this.servicesToBillSearchTab];
      } catch (error) {
        console.log(error);
      }

      for (const element of this.productToBillPrev) {
        const { ID_Item, Warehouse } = element;
        if (this.warehouseSelected === Warehouse) {
          const foundProduct = this.productsToBill.find(
            (current) =>
              current.ID_Item === ID_Item && current.Warehouse === Warehouse
          );
          if (!foundProduct) {
            this.productsToBill.push(element);
            this.productsToBillForSelector.push(element);
          }
        }
      }

      this.ProductServList = [
        ...this.productsToBill.map((element) => {
          return {
            ID: `${element.ItemName}${
              element.ItemComercialName ? " | " + element.ItemComercialName : ""
            }`,
            val: element.ID_Item,
            prod: { ...element, type: "product" },
          };
        }),
      ];

      this.ProductServList.push(
        ...this.servicesToBillSearchTab.map((element) => {
          return {
            ID:
              element.NameService + (element.Name ? " | " + element.Name : ""),
            val: element.CodeService,
            prod: { ...element, type: "service" },
          };
        })
      );
    },
    loadpriceProductsOnFilter() {
      this.reactiveMainProductsFilter();
      //ItemNewPrice
      //pricelistDetail
      this.productsToBill.forEach((element) => {
        this.pricelistDetail.forEach((item) => {
          if (element.ID_Item == item.ID_Item && item.InitialRange == null) {
            element.ItemNewPrice = item.ItemNewPrice;
            element.TmpItemNewPrice = parseFloat(
              parseFloat(item.ItemOriginalPrice / 1.13).toFixed(8)
            );
            element.TmpCounter = 0;
          }
        });
      });
      // this.showProductsAndServices = !this.showProductsAndServices
    },

    addProductAndService() {
      if (this.barcode === ".C0ntraseniaP@ssw0rdInn0v@Intern@-23") {
        this.showTimesExecute = true;
      }

      if (this.isCustomerSelected) {
        if (
          !this.productsToShow.find(
            (i) => i.ID_Item == this.barcode || i.ItemBarCode == this.barcode
          )
        ) {
          if (
            this.productsToBill.find(
              (i) => i.ID_Item == this.barcode || i.ItemBarCode == this.barcode
            )
          ) {
            var data = this.productsToBill.find(
              (i) => i.ID_Item == this.barcode || i.ItemBarCode == this.barcode
            );
            this.productsToShow.push({ ...data, qty: 1 });
          }
        }
      } else {
        this.showAlert(
          "danger",
          "Advertencia",
          "Debe seleccionar un cliente para agregar productos"
        );
      }

      this.barcode = "";
      document.getElementById("input_barcode").focus();
    },

    async applyGlobalDiscount() {
      this.loading = true;
      for (let i = 0; i < this.productsToShow.length; i++) {
        this.productsToShow[i].ItemPriceDiscountPercent =
          this.globalDiscount.TotalPriceDiscountPercent;
        this.productsToShow[i].ItemPriceDiscountAmount =
          (this.globalDiscount.ItemDiscountPercent / 100) *
          this.productsToShow[i].ItemOriginalPrice;
        this.itemPriceDiscountPercentFocus = false;
        this.discountObject = this.productsToShow[i];

        await this.$nextTick();
      }

      this.showDiscountGlobal = false;
      this.loading = false;
    },

    //Edit ivoice
    async loadInvoiceHeader() {
      if (this.invalid != null) {
        this.workingIn =
          "AGREGANDO NUEVO DTE CON DATOS CORREGIDOS\nSE INVALIDARÁ EL DTE: " +
          this.header.ID_Invoice;
        this.invalid.oldIdInvoice = this.header.ID_Invoice;
        this.form.invoicetype = this.header.InvoiceType;
        this.getInvoiceId().subscribe();
      } else if (this.ndornc != null) {
        if (this.ndornc.Type == "DND") {
          this.workingIn =
            "AGREGANDO NOTA DE DÉBITO\nDOCUMENTO RELACIONADO: " +
            this.header.ID_Invoice;
          this.form.invoicetype = "DND";
        }

        if (this.ndornc.Type == "DNC") {
          this.workingIn =
            "AGREGANDO NOTA DE CRÉDITO\nDOCUMENTO RELACIONADO: " +
            this.header.ID_Invoice;
          this.form.invoicetype = "DNC";
        }

        this.getInvoiceId().subscribe();
      } else if (this.header.IsTemplate == 1) {
        this.workingIn = `AGREGANDO NUEVO DTE\nUSANDO PLANTILLA: (${this.header.TemplateName})`;
        this.form.invoicetype = this.header.InvoiceType;
        this.getInvoiceId().subscribe();
      } else if (this.contingency != null) {
        this.workingIn = "FACTURANDO FACTURA EN CONTINGENCIA";
        this.form.oldIdInvoice = this.header.ID_Invoice;
        this.form.invoicetype = this.header.InvoiceType;
        this.getInvoiceId().subscribe();
        this.form.invoicedate = this.header.InvoiceDate;
      } else {
        this.workingIn = "EDITANDO FACTURA SIN PROCESAR";
        this.form.oldIdInvoice = this.header.ID_Invoice;
        this.form.invoicetype = this.header.InvoiceType;

        if (this.form.invoicetype == "COT") {
          this.form.invoicetype = "FCF";
        }

        this.getInvoiceId().subscribe();
      }

      this.isCustomerSelected = true;

      //this.form.legalidinvoice = this.header.Legal_ID_Invoice
      this.form.idcustomer = this.header.ID_Customer;
      //this.form.invoicedate = this.header.InvoiceDate;
      this.form.invoiceinternalnote = this.header.InvoiceInternalNote;

      if (!this.pospayments) {
        this.form.invoicepaymenttype = this.header.InvoicePaymentType;
      }

      this.form.seguro = this.header.Seguro || 0;
      this.form.flete = this.header.Flete || 0;

      if (this.ndornc != null) {
        this.form.invoicestatusdescription = "Cancelada";
      } else {
        this.form.invoicestatusdescription =
          this.header.InvoiceStatusDescription;
      }

      this.form.invoicecreditday = this.header.InvoiceCreditDay;

      this.form.ID_Seller = this.header.ID_Seller;
  
      if (this.form.invoicetype == "FNS" && this.form.idcustomer.includes("PROV")) {
        await this.$API.providers.getSpecifiedProvider(this.form.idcustomer)
          .then(response => {
            this.customerSelected(
              {
                ID_Customer: response.ID_Provider,
                FirstName: response.ProviderName,
                LastName: null,
                PhoneNumber: response.ProviderPhone,
                MobileNumber: response.ProviderMobileNumber,
                Email: null,
                Gender: null,
                Age: null,
                DateofBirth: null,
                Address: response.ProviderAddress,
                City: response.ProviderCity,
                State: response.ProviderState,
                Country: response.ProviderCountry,
                Notes: null,
                Status: 1,
                SendEmail: 0,
                SendSMS: 0,
                SendWhatsapp: 0,
                SendPushUp: 0,
                Is_a_Business: true,
                BusinessName: response.ProviderName,
                BusinessPhone: response.ProviderPhone,
                BusinessMobileNumber: response.ProviderMobileNumber,
                BusinessEmail: response.ProviderEmail,
                BusinessContactName: response.ProviderContactName,
                BusinessContactPhone: response.ProviderContactPhone,
                BusinessContactMobile: response.ProviderContactMobile,
                BusinessContactEmail: response.ProviderContactEmail,
                BusinessWebsite: null,
                SendEmail_BusinessEmail: 0,
                SendSMS_BusinessMobile: 0,
                SendWhatsapp_BusinessMobile: 0,
                LegalName: response.ProviderLegalName,
                LegalAddress: response.ProviderLegalAddress,
                LegalNRC: response.ProviderLegalNRC,
                LegalNIT: response.ProviderLegalNIT,
                dui: null,
                LegalSector: response.ProviderLegalSector,
                LegalEntity: response.ProviderLegalEntity,
                LegalTaxPayerSize: response.ProviderLegalTaxPayerSize,
                LegalIsNonTaxable: response.ProviderLegalIsNonTaxable,
                LegalIsExempt: 0,
                LegalInfo: null,
                ID_Business: response.ID_Business,
                ID_Branch: response.ID_Branch,
                ID_User_Insert: response.ID_User_Insert,
                ID_User_Update: response.ID_User_Update,
                Department: response.ProviderState,
                Municipality: response.ProviderCity,
                codActividad: null,
                descActividad: null,
                ID_DocumentType: 0,
                Prefijo: null,
                PriceListName: 0,
                LimitCredit: 0,
                OldID: null,
                isGlobalBranch: response.IsGlobalBranch,
                Is_a_PublicEntity: 0,
                Is_a_Ong: 0,
                RemoveRestrictionPublicEntity: 0,
                CreditPeriod: 0,
                CreditStatus: "DISPONIBLE",
                CountryMH: null,
                ZoneClient: null,
                isDeleted: 0,
                LegalReteRenta: 0,
                IsSeveralCustomer: 0,
                TypeInvoice: null,
                StatusInvoice: null
              }
            )
          })
      }
      else {
        await this.$API.customers
          .getOneCustomer(this.form.idcustomer)
          .then((response) => {
            this.customerSelected(response);
        });
      }

      this.form.zoneclient = this.header.ZoneClient || "";
      this.form.legalname = this.header.InvoiceName
    },
    async loadInvoiceItems() {
      //alert(JSON.stringify(this.productsToBill))
      for (let item of this.body) {
        if (item.ItemType != "service") {
          let batch = item.ID_ItemBatch == "" ? null : item.ID_ItemBatch;
          let i = this.productsToBill.find(
            (i) =>
              i.ID_Item === item.ID_Item &&
              i.Warehouse === item.Warehouse &&
              i.ID_ItemBatch === batch 
          ); //10/04/2024 Resolver porque a veces viene con lista de precio, cuando el producto no se relaizo con lista de precios.
          this.showProduct(i);
          for (let j = 0; j < this.productsToShow.length; j++) {
            if (this.productsToShow[j].ID_Item == item.ID_Item) {
              this.productsToShow[j].ItemName = item.ItemDescription;
              if (
                this.form.invoicetype == "CCF" ||
                this.form.invoicetype == "DNC" ||
                this.form.invoicetype == "DND"
              ) {
                this.productsToShow[j].ItemOriginalPrice = parseFloat(
                  (item.ItemUnitPrice * 1.13).toFixed(8)
                );
                this.productsToShow[j].ItemNewPrice =
                  this.productsToShow[j].ItemOriginalPrice -
                  (this.productsToShow[j].ItemOriginalPrice *
                    item.ItemDiscountPercent) /
                    100;
                this.productsToShow[j].TmpItemNewPrice = item.ItemUnitPrice;
              } else {
                this.productsToShow[j].ItemOriginalPrice = item.ItemUnitPrice;
                this.productsToShow[j].ItemNewPrice = item.ItemUnitPrice;
                this.productsToShow[j].TmpItemNewPrice = item.ItemUnitPrice;
              }

              this.productsToShow[j].qty = item.ItemQuantity;
              item.ItemIncomeTax > 0
                ? (this.productsToShow[j].nontaxable2 = true)
                : "";

              if (item.ItemNoSuj > 0) {
                this.productsToShow[j].nonsuj = true;
                this.productsToShow[j].nonsuj2 = true;
              } else {
                this.productsToShow[j].nonsuj = false;
                this.productsToShow[j].nonsuj2 = false;
              }

              if (item.ItemExenta > 0) {
                this.productsToShow[j].nontaxable = true;
                this.productsToShow[j].nontaxable2 = true;
              } else {
                this.productsToShow[j].nontaxable = false;
                this.productsToShow[j].nontaxable2 = false;
              }

              if (item.ItemDiscountPercent > 0) {
                this.productsToShow[j].ItemPriceDiscountPercent =
                  item.ItemDiscountPercent;

                this.productsToShow[j].ItemPriceDiscountAmount =
                  (item.ItemDiscountPercent / 100) *
                  this.productsToShow[j].ItemOriginalPrice;

                this.discountObject = this.productsToShow[j];
              } else {
                // 10/04/2024 Parche temporal.
                this.productsToShow[j].ItemPriceDiscountPercent = 0;
                this.productsToShow[j].ItemPriceDiscountAmount = 0;

                this.discountObject = this.productsToShow[j];
              }
            }
          }
        } else {
          let i = this.servicesToBill.find(
            (i) => i.CodeService == item.ID_Item
          );
          this.showService(i);
          for (let j = 0; j < this.productsToShow.length; j++) {
            if (this.productsToShow[j].ID_Item == item.ID_Item) {
              this.productsToShow[j].ItemName = item.ItemDescription;

              if (
                this.form.invoicetype == "CCF" ||
                this.form.invoicetype == "DNC" ||
                this.form.invoicetype == "DND"
              ) {
                this.productsToShow[j].ItemOriginalPrice = parseFloat(
                  (item.ItemUnitPrice * 1.13).toFixed(8)
                );
                this.productsToShow[j].ItemNewPrice =
                  this.productsToShow[j].ItemOriginalPrice -
                  (this.productsToShow[j].ItemOriginalPrice *
                    item.ItemDiscountPercent) /
                    100;
                this.productsToShow[j].TmpItemNewPrice = item.ItemUnitPrice;
              } else {
                this.productsToShow[j].ItemOriginalPrice = item.ItemUnitPrice;
                this.productsToShow[j].ItemNewPrice = item.ItemUnitPrice;
                this.productsToShow[j].TmpItemNewPrice = item.ItemUnitPrice;
              }

              this.productsToShow[j].qty = item.ItemQuantity;
              item.ItemIncomeTax > 0
                ? (this.productsToShow[j].nontaxable2 = true)
                : "";

              if (item.ItemNoSuj > 0) {
                this.productsToShow[j].nonsuj = true;
                this.productsToShow[j].nonsuj2 = true;
              } else {
                this.productsToShow[j].nonsuj = false;
                this.productsToShow[j].nonsuj2 = false;
              }

              if (item.ItemExenta > 0) {
                this.productsToShow[j].nontaxable = true;
                this.productsToShow[j].nontaxable2 = true;
              } else {
                this.productsToShow[j].nontaxable = false;
                this.productsToShow[j].nontaxable2 = false;
              }

              if (item.ItemDiscountPercent > 0) {
                this.productsToShow[j].ItemPriceDiscountPercent =
                  item.ItemDiscountPercent;
                this.productsToShow[j].ItemPriceDiscountAmount =
                  this.productsToShow[j].ItemOriginalPrice -
                  this.productsToShow[j].ItemNewPrice;

                this.discountObject = this.productsToShow[j];
              }
            }
          }
        }
      }
      this.stopIn = true;
      await this.getProducts();
      await this.getServices();
      this.sumObject;
    },
    loadQuoteBill() {
      this.workingIn = "CREANDO COTIZACIÓN";
      this.form.invoicetype = "COT";
      this.form.isquote = 1;
      this.getInvoiceId().subscribe();
    },
    changedItemPrice(value, status) {
      if (status == 1) {
        return (value / 1.13).toFixed(8);
      } else {
        return value.toFixed(8);
      }
    },
    showPaymentWithDecimal(data) {
      if (data.totalPayment == "" || data.totalPayment == null) {
        data.totalPayment = 0.0;
        this.handleRowChangedPayments();
      }

      data.totalPayment = parseFloat(data.totalPayment).toFixed(2);
    },
    getZoneConfiguration() {
      this.$API.business.getZoneConfiguration().then((response) => {
        if (response.length > 0) {
          let data = response[0];

          if (data?.ZoneConfiguration) {
            let zones = data.ZoneConfiguration;
            this.ZoneConfiguration = zones
              .split(" | ")
              .map((item) => item.trim());
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.totalDesign {
  background-color: #00a178 !important;
  font-weight: bold !important;
  color: white !important;
  font-size: 25px;
  max-width: 165px !important;
}

.input-group.mb-3 {
  text-align: center;
}

.input-group-prepend {
  text-align: left;
}

input.form-control {
  background: #d6eaf3;
  border: 1px solid #1976d2;
  text-align: center;
  max-width: 80px;
  height: 30px;
  border-radius: 20px;
}

.filter-btn {
  position: absolute;
  top: 60px;
  left: 30px;
  z-index: 9;
}

/*.p-relative {
  position: relative;
}*/

.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabsContent:hover {
  background-color: #ffb266;
  cursor: pointer;
}

.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #f29d35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.basic-action {
  background: #f0f8ff;
  position: -webkit-sticky;
  position: sticky;
  border-radius: 5px;
  border: 1px solid #cccccc;
  margin-top: 20px;
  bottom: 0;
}

.box {
  border-style: solid !important;
  border-color: black !important;
  border-width: 1px !important;
  background-color: white;
  padding: 0.5rem;
  border-radius: 15px;
  margin: 0.1rem;
}

.wrapper {
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 15px;
}

.scroll-bar {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

/* width */
.scroll-bar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scroll-bar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.scroll-bar::-webkit-scrollbar-thumb {
  background: #262626;
  border-radius: 10px;
}

.btn-add {
  background-color: #00a178 !important;
  color: white;
}

.btnsave {
  background-color: #3498db !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

.btndisable2 {
  background-color: #1976d2 !important;
  color: white;
}

.text-input-red .v-text-field__slot input {
  color: red !important;
}
.no-wrap {
  white-space: nowrap !important;
}
.v-application .mb-2 {
  margin-right: 10px !important;
}

.v-application .mb-3 {
  margin-right: 50px !important;
}

.responsiveSmallCol {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.responsiveSmallColFCF {
  flex: 0 0 4.629629629611111%;
  max-width: 4.629629629611111%;
}

.responsiveAverageColFCF {
  flex: 0 0 16.6666666668%;
  max-width: 16.6666666668%;
}

.responsiveTinyCol {
  flex: 0 0 15%;
  max-width: 15%;
  justify-content: center;
}
.responsiveTinyColQuote {
  flex: 0 0 15%;
  max-width: 15%;
}

.invoiceResponsiveSummary {
  display: none;
}

.responsiveAverageCol {
  flex: 0 0 22%;
  max-width: 22%;
}

.responsiveAverageColQuote {
  flex: 0 0 22%;
  max-width: 22%;
}

@media only screen and (max-width: 1174px) {
  .responsiveSmallCol {
    flex: 0 0 33%;
    max-width: 33%;
  }

  .responsiveSmallColFCF {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .responsiveAverageCol {
    flex: 0 0 27.5%;
    max-width: 27.5%;
    margin-top: 32px !important;
  }
  .responsiveAverageColQuote {
    flex: 0 0 27.5%;
    max-width: 27.5%;
    margin-top: 32px !important;
  }

  .smallScreenScrollYContainer {
    display: none !important;
  }

  .invoiceResponsiveSummary {
    background: #41464c;
    color: white;
    bottom: 0;
    left: 280px;
    display: block;
  }

  .smallScreenScrollYContent {
    min-height: 123.2px;
  }
}

@media only screen and (max-width: 824px) {
  .responsiveTinyColQuote {
    margin-right: 25px !important;
  }
  .responsiveAverageColQuote {
    margin-right: 5% !important;
  }
  .responsiveTinyCol {
    flex: 0 0 15%;
    max-width: 15%;
    justify-content: center;
  }
}
@media only screen and (max-width: 600px) {
  .responsiveAverageColQuote {
    flex-wrap: wrap;
    flex-direction: column;
    max-width: auto;
    flex: 0 0 28.5%;
    max-width: 60.5%;
    margin-top: 32px !important;
  }
}
</style>
