import axios from "./axios";

export default {
    findAllAccountingExpensesEntry() {
        const options = {
            method: 'GET',
            url: '/accountingexpensesentry',
            headers: { 'content-type': 'application/json' },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    verifyDuplicatedEntries(value) {
        const options = {
            method: 'GET',
            url: '/accountingexpensesentry/verifyDuplicatedEntries',
            headers: { 'content-type': 'application/json' },
            params: value
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    filteredAccountingExpensesEntry(params) {
        const options = {
            method: 'GET',
            url: '/accountingexpensesentry/list/filtered',
            headers: { 'content-type': 'application/json' },
            params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    filteredAccountingExpensesEntryByDate(params) {
        const options = {
            method: 'GET',
            url: '/accountingexpensesentry/list/filteredByDate',
            headers: { 'content-type': 'application/json' },
            params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    createAccountingExpensesEntry(data) {
        const options = {
            method: 'POST',
            url: '/accountingexpensesentry',
            headers: { 'content-type': 'multipart/form-data' },
            data: data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    
    ImportAccountingExpenses(data) {
        const options = {
            method: 'POST',
            url: '/accountingexpensesentry/importExpenses',
            headers: { 'content-type': 'multipart/form-data' },
            data: data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    deleteAccountingExpenses(id) {
        const options = {
            method: 'DELETE',
            url: '/accountingexpensesentry/deleteExpenses',
            headers: { 'content-type': 'multipart/form-data' },
            params: id,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};
